import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions.js";
import { fetchData } from "./redux/data/dataActions.js";
import * as s from "./styles/globalStyles.js";
import styled from "styled-components";
import Web3 from "web3";
import 'rsuite/styles/index.less';
import "rsuite/dist/rsuite.min.css";
import { Notification, toaster } from 'rsuite';
import { Loader } from 'rsuite';

import firebase, {isValidEthAddress, getBalance } from './firebaseConfig.js';
import CopiarCodigo from './CopiarCodigo.js'; 

import { ethers } from 'ethers';
import { createScene } from './SceneManager.js';
import { createScene2 } from './SceneManager2.js';
import { createScene3 } from './SceneManager3.js';
import { createScenelogo } from './SceneLogo.js';

  
const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;



export const CTNButton = styled.button`
padding: 1pxx;
border-radius: ;
outline: none;
background-color: transparent;
padding: 0px;
color: white;
text-shadow: 6px 6px 6px rgba(0, 0, 0, 1);
width: 460px;
height: 56px;
cursor: pointer;

display: flex;
align-items: center;
justify-content: center;


  @keyframes letter-by-letter {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;
export const CTNButton2 = styled.button`
line-height: 1.6;
outline: none;
background-color: transparent;
color: white;
text-shadow: 6px 6px 6px rgba(0, 0, 0, 1);
display: flex;
align-items: center;
justify-content: center;


  @keyframes letter-by-letter {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes color-change {
  0% {
    color: white;
  }
  10% {
    color: rgb(255, 0, 0); /* Rojo */
  }
  20% {
    color: rgb(0, 255, 0); /* Verde */
  }
  30% {
    color: rgb(0, 0, 255); /* Azul */
  }
  40% {
    color: rgb(255, 255, 0); /* Amarillo */
  }
  50% {
    color: rgb(255, 0, 255); /* Magenta */
  }
  60% {
    color: rgb(0, 255, 255); /* Cian */
  }
  70% {
    color: rgb(128, 128, 128); /* Gris */
  }
  80% {
    color: rgb(255, 128, 0); /* Naranja */
  }
  90% {
    color: rgb(128, 0, 255); /* Morado */
  }
  100% {
    color: white;
  }
}
`;
export const CTNButton4 = styled.button`
line-height: 1.6;
outline: none;
background-color: transparent;
color: white;

text-shadow: 6px 6px 6px rgba(0, 0, 0, 1);
align-items: center;
justify-content: center;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: ;
  border: none;
  background-color: Transparent;
  padding: 10px;
  outline: none;

  color: white;
  width: 30px;
  height: 29px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
   background-color:  ;
  }
`;
export const ResponsiveWrapper = styled.div`    
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10%;
  width: 1000px;
  outline: none;
`;
export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  outline: none;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  
  background-color : #252525;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;
export const StyledImg = styled.img` 
  width: 450px;
  border-radius: 0px;
  outline: none;


@keyframes Hidden {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Visible {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

  &.slide-in {
    animation: slideRight 2.1s linear infinite;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(0);
    }
    90% {
      transform: translateX(170px);
    }
  }


`;




function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [tokens, settokens] = useState(1);
  const [brd, setbrd] = useState("2px solid #FFFFFF");
  const [bxsh, setbxsh] = useState("0px 0px 3px 0px #FFFFFF");
  const [DOT, setDOT] = useState("red");
  const [type, setType] = React.useState('info');
  const [placement, setPlacement] = React.useState('topStart');
  const [text, setText] = useState('');
  const [isConnected, setIsConnected] = useState(false); 

  

  const fortime1 = "The Private Sale will begin in:";
  const fortime2 = "It's time, the Private Sale has begun.";
  const contract= "0x3B2cA2f2C1bBCb523E06820180c1345377AC4eAE";

  const discordlink = "https://discord.com/invite/conquestofparadise";
  const opensealink = "https://opensea.io/collection/conquest-of-paradise/overview";
  const etherlink = "https://etherscan.io/address/0x3b2ca2f2c1bbcb523e06820180c1345377ac4eae";
  const twitterlink = "https://x.com/ConqOfParadise";
  const tiktoklink = "https://www.tiktok.com/@conqofparadise";
  const instagramlink = "https://www.instagram.com/conqofparadise/";




  const openairdrop1= "20/01/25";  const closeairdrop1= "28/01/25";  const airdrop1= "30/01/25";
  const openairdrop2= "31/01/25";  const closeairdrop2= "05/02/25"; const airdrop2= "07/02/25";
  const openairdrop3= "08/02/25";  const closeairdrop3= "13/02/25"; const airdrop3= "15/02/25";

  const openairdropeth= "16/02/25"; const closeairdropeth= "22/02/25"; const airdropeth= "25/02/25";

  const start= "18/11/24";

  const closeregister= "19/12/24"; const inforegister= "20/12/24";

  const privsale= "22/12/24";   const privsaleprice= "0.04 ETH";
  const presale= "25/12/24";   const presaleprice= "0.02 ETH";
  const publicsale= "28/12/24"; const endpublicsale= "20/01/25"; const publicsaleprice= "0.07 ETH";








const targetDate = new Date('2024-12-21T20:31:00Z');
const [timeRemaining, setTimeRemaining] = useState(targetDate.getTime() - new Date().getTime());
const intervalRef = useRef(null);
  
    useEffect(() => {
     intervalRef.current = setInterval(() => {
        const newTimeRemaining = targetDate.getTime() - new Date().getTime();
  
        if (newTimeRemaining <= 0) {
          clearInterval(intervalRef.current);
          setTimeRemaining(0); 
          return;
        }
        setTimeRemaining(newTimeRemaining);
      }, 1000);
      return () => clearInterval(intervalRef.current);
    }, []);
  
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000); 

  
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  
  const [UserTwitter, setUserTwitter] = useState('');
  const [UserDisc, setUserDisc] = useState(''); 
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {if (errorMessage) { setTimeout(() => setErrorMessage(''), 2500); }}, [errorMessage]);



  const [errorMessage1, setErrorMessage1] = useState('');

  const [ errorMessage2, setErrorMessage2] = useState('');
  useEffect(() => {if (errorMessage2) { setTimeout(() => setErrorMessage2(''), 2500); }}, [errorMessage2]);


const handleSend = async () => {
  const trimmedUserDisc= UserDisc.trim();
  const trimmedAddress = text.trim();
  const trimmedUserTwitter = UserTwitter.trim();

if (!trimmedAddress && !trimmedUserTwitter && !trimmedUserDisc) {
  setErrorMessage('OOPS! YOU SEEM TO BE MISSING SOME INFORMATION. PLEASE FILL IN ALL FIELDS IN ORDER TO PARTICIPATE.');
  audio3.play();
return;}

if (!trimmedUserDisc || !trimmedUserTwitter) {
  setErrorMessage('OOPS! PLEASE FILL IN YOUR DISCORD AND TWITTER USERNAMES TO PARTICIPATE.');
  audio3.play();
  return;
}
 
  const db = firebase.firestore();
  const docRef = db.collection('PRE-SALE').doc(trimmedUserDisc +  trimmedUserTwitter);

  let isValid = true;

  setErrorMessage('');



  if (!trimmedAddress) {
    setErrorMessage('CHECK YOUR ETH ADDRESS, IT SEEMS TO BE INVALID! REMEMBER THAT IT MUST START WITH 0X.');
    isValid = false;
    audio3.play();
    return;
  }

  if (!isValidEthAddress(trimmedAddress)) {
    setErrorMessage('THAT ETH ADDRESS DOES NOT APPEAR TO BE SPELLED CORRECTLY, CHECK THOSE NUMBERS AND LETTERS!');
    isValid = false;
    audio3.play();
    return;
  }
  if (!trimmedUserTwitter) {
    setErrorMessage('OOPS! YOU FORGOT YOUR TWITTER USERNAME, JOIN THE COMMUNITY AND STAY UP TO DATE!');
    audio3.play();
    return;
  }
  if (!trimmedUserDisc) {
    setErrorMessage('OOPS! YOU FORGOT YOUR DISCORD USERNAME, JOIN THE COMMUNITY AND STAY UP TO DATE! ');
    audio3.play();
    return;
  }

  if (!isChecked2) {
    setErrorMessage('BEFORE CONTINUING, TAKE A LOOK AT THE TERMS AND CONDITIONS. WE KNOW THEY ARE A BIT BORING, BUT THEY ARE IMPORTANT.');
    audio3.play();
    return;
  }

  const balance = await getBalance(trimmedAddress);

  if (balance.eq(ethers.utils.parseEther("0"))) {
    setErrorMessage('IT LOOKS LIKE YOUR ETH PORTFOLIO IS A BIT EMPTY. MAKE SURE YOU HAVE FUNDS TO CONTINUE.');
    isValid = false;
    audio3.play();
    return;
  }

  if (isValid) {
    docRef
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          setErrorMessage1(`YOU ARE ALREADY PARTICIPATING IN THE PRE-SALE! DON'T WORRY, SAVE THE DATE ${inforegister} TO FIND OUT IF YOU ARE ONE OF THE LUCKY ONES, CROSS YOUR FINGERS AND GOOD LUCK!`);
          audio3.play();
        } else {
          docRef.set({
            text: trimmedAddress,
            UserTwitter: trimmedUserTwitter,
            UserDisc: trimmedUserDisc,
            fecha: firebase.firestore.Timestamp.fromDate(new Date()),
          });
          setText('');
          setUserTwitter('');
          setUserDisc('');
          setIsChecked2(false);
          setErrorMessage1(`CONGRATULATIONS! YOU ARE ALREADY PARTICIPATING IN THE PRESALE. NOW YOU JUST HAVE TO WAIT FOR THE BIG DAY ${inforegister} TO FIND OUT IF YOU HAVE WON A PLACE. CROSS YOUR FINGERS AND GOOD LUCK!`);
          audio.play();
        }
      })
  }
};

const handleSend2 = async () => {
  const trimmedUserDisc= UserDisc.trim();
  const trimmedAddress = text.trim();
  const trimmedUserTwitter = UserTwitter.trim();

if (!trimmedAddress && !trimmedUserTwitter && !trimmedUserDisc) {
setErrorMessage('OOPS! YOU SEEM TO BE MISSING SOME INFORMATION. PLEASE FILL IN ALL FIELDS IN ORDER TO PARTICIPATE.');
audio3.play();
return;}

if (!trimmedUserDisc || !trimmedUserTwitter) {
  setErrorMessage('OOPS! MISSING DISCORD & TWITTER: PLEASE FILL IN YOUR DISCORD AND TWITTER USERNAMES TO PARTICIPATE.');
  audio3.play();
  return;
}
 
  const db = firebase.firestore();
  const docRef = db.collection('PRIV-SALE').doc(trimmedUserDisc + trimmedUserTwitter);

  let isValid = true;

  setErrorMessage('');


  if (!trimmedAddress) {
    setErrorMessage('CHECK YOUR ETH ADDRESS, IT SEEMS TO BE INVALID! REMEMBER THAT IT MUST START WITH 0X.');
    isValid = false;
    audio3.play();
    return;
  }

  if (!isValidEthAddress(trimmedAddress)) {
    setErrorMessage('THAT ETH ADDRESS DOES NOT APPEAR TO BE SPELLED CORRECTLY, CHECK THOSE NUMBERS AND LETTERS!');
    isValid = false;
    audio3.play();
    return;
  }
  if (!trimmedUserTwitter) {
    setErrorMessage('OOPS! YOU FORGOT YOUR TWITTER USERNAME, JOIN THE COMMUNITY AND STAY UP TO DATE!');
    audio3.play();
    return;
  }
  if (!trimmedUserDisc) {
    setErrorMessage('OOPS! YOU FORGOT YOUR DISCORD USERNAME, JOIN THE COMMUNITY AND STAY UP TO DATE! ');
    audio3.play();
    return;
  }

  if (!isChecked2) {
    setErrorMessage('BEFORE CONTINUING, TAKE A LOOK AT THE TERMS AND CONDITIONS. WE KNOW THEY ARE A BIT BORING, BUT THEY ARE IMPORTANT.');
    audio3.play();
    return;
  }

  const balance = await getBalance(trimmedAddress);

  if (balance.eq(ethers.utils.parseEther("0"))) {
    setErrorMessage('IT LOOKS LIKE YOUR ETH PORTFOLIO IS A BIT EMPTY. MAKE SURE YOU HAVE FUNDS TO CONTINUE.');
    isValid = false;
    audio3.play();
    return;
  }

  if (isValid) {
    docRef
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          setErrorMessage1(`YOU ARE ALREADY PARTICIPATING IN THE PRIV-SALE! DON'T WORRY, SAVE THE DATE ${inforegister} TO FIND OUT IF YOU ARE ONE OF THE LUCKY ONES, CROSS YOUR FINGERS AND GOOD LUCK!`);
          audio3.play();
        } else {
          docRef.set({
            text: trimmedAddress,
            UserTwitter: trimmedUserTwitter,
            UserDisc: trimmedUserDisc,
            fecha: firebase.firestore.Timestamp.fromDate(new Date()),
          });
          setText('');
          setUserTwitter('');
          setUserDisc('');
          setIsChecked2(false);
          setErrorMessage1(`CONGRATULATIONS! YOU ARE ALREADY PARTICIPATING IN THE PRIV-SALE. NOW YOU JUST HAVE TO WAIT FOR THE BIG DAY ${inforegister} TO FIND OUT IF YOU HAVE WON A PLACE. CROSS YOUR FINGERS AND GOOD LUCK!`);
          audio.play();
        }
      })
  }
};




const imageContainerRef = useRef(null);

useEffect(() => {
  const imageContainer = imageContainerRef.current;

  if (imageContainer) {
    const handleContextMenu = (e) => {
      if (e.target.nodeName === 'IMG') {
        e.preventDefault();
      }
    };
    imageContainer.addEventListener('contextmenu', handleContextMenu);
  }
}, [imageContainerRef]);



  const errmessage = (
    <Notification
    style={{fontSize: 15, backgroundColor: "transparent", boxShadow: "none", width: "420px", }}
  >
<CTNButton2
style={{ position: "absolute", top: 69, left: 97, width: "345px",}}>
SORRY, PLEASE TRY AGAIN.
</CTNButton2>
  <StyledImg
      src="/config/images/Err.png" alt="image" draggable="false"
      style={{width: "390px",height: "120px" }} />
 </Notification>
  );

  const txmessage = (
    <Notification
    style={{fontSize: 15, backgroundColor: "transparent", boxShadow: "none", width: "420px", }}
  >
<CTNButton2
style={{ position: "absolute", top: 69, left: 97, width: "345px",}}>
CONGRATS, MINT WAS SUCCESSFULL.
</CTNButton2>
  <StyledImg
      src="/config/images/Err.png" alt="image" draggable="false"
      style={{width: "390px",height: "120px" }} />
 </Notification>
  );

  const mntmessage = (
    <Notification
    style={{fontSize: 15, backgroundColor: "transparent", boxShadow: "none", width: "420px", }}
  >
<CTNButton2
style={{ position: "absolute", top: 69, left: 97, width: "345px",}}>
MINTING IN PROGRESS....
</CTNButton2>
  <StyledImg
      src="/config/images/Err.png" alt="image" draggable="false"
      style={{width: "390px",height: "120px" }} />
 </Notification>
  );




  
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "0x3B2cA2f2C1bBCb523E06820180c1345377AC4eAE",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "Ethereum Mainnet",
      SYMBOL: "ETH",
      ID: 1,
    },
    NFT_NAME: "Conquest of Paradise",
    SYMBOL: "COP",
    MAX_SUPPLY: 21632,
    DISPLAY_COST: 0.1,
    WL_Display: 0,
    GAS_LIMIT: 145000,
    MAX_PER_TX: 99,
    MARKETPLACE: "Opeansea",
    MARKETPLACE_LINK: "https://opensea.io/collection/conquest-of-paradise",
    Discord: "https:/https://discord.gg/conquestofparadise/",  
    Twitter: "https://x.com/ConqOfParadise",
    SHOW_BACKGROUND: false,
  });


  const claimNFTs = () => {
    let cost = CONFIG.DISPLAY_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), 'ether');
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    setbrd("2px solid yellow");
    setbxsh("0px 0px 3px 0px yellow");
    toaster.push(mntmessage, { placement })
    blockchain.smartContract.methods
      .mint(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })

      .once("error", (err) => {
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement })
        setbrd("2px solid red");
        setbxsh("0px 0px 3px 0px red");
      })

      
      .then((receipt) => {
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        toaster.push( txmessage, { placement })
        setbrd("2px solid green");
        setbxsh("0px 0px 3px 0px green");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    if (newtokens > CONFIG.MAX_PER_TX) {
      newtokens = CONFIG.MAX_PER_TX;
    }
    settokens(newtokens);
  };

  
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(blockchain.account.toUpperCase().slice(0, 4) + "..." + blockchain.account.toUpperCase().substring(38, 42));
    }
  };


  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };


const audio = new Audio('/config/sounds/mint.mp3');
const audio2 = new Audio('/config/sounds/click.mp3');
const audio3 = new Audio('/config/sounds/error.mp3');
  
const [audioPlayed, setAudioPlayed] = useState(false);
useEffect(() => {
  
  if (blockchain.errorMsg && !audioPlayed) {
    const audio3 = new Audio("/config/sounds/error.mp3");
    audio3.play();
    setAudioPlayed(true);
    
    
  } else if (data.totalSupply >= CONFIG.MAX_SUPPLY && !audioPlayed) {
    const audio4 = new Audio("/config/sounds/mint.mp3");
    audio.play();
    setAudioPlayed(true);
  }
  
}, [blockchain, data.totalSupply, audioPlayed]);



const [currentQuestion00, setcurrentQuestion00] = useState('¿WHAT IS THIS?')

const [currentQuestion2, setcurrentQuestion2] = useState('¿Server?')
const [currentQuestion3, setcurrentQuestion3] = useState('¿Server?')
const [currentQuestion4, setcurrentQuestion4] = useState('¿PHYGITAL NFT?')
const [currentQuestion5, setcurrentQuestion5] = useState('¿PRIVSALE?')
const [currentQuestion6, setcurrentQuestion6] = useState('¿ROADMAP?')
const [currentQuestion7, setcurrentQuestion7] = useState('')


const [allGifsReloaded, setAllGifsReloaded] = useState(false);
const [reloadTimeoutId, setReloadTimeoutId] = useState(null);
const [loadingImageShown, setLoadingImageShown] = useState(false);

const [currentQuestion, setCurrentQuestion] = useState('¿Exploring?');
const [currentQuestion1, setCurrentQuestion1] = useState('¿General?');
const [showOptions2, setShowOptions2] = useState(false);  






const [selectedOption, setSelectedOption] = useState("Exploring");
const options = ['Exploring', 'Archer', 'Triton', 'Attack'];  

const handleOptionChange = (newOption) => {
   setSelectedOption(newOption);
   
   let defaultOption6;
switch (newOption) {case "Exploring":defaultOption6 = "Ench Diamond Sword";break;
  case "Archer":defaultOption6 = "Ench Bow"; break;
  case "Triton": defaultOption6 = "Trident Ench";break;
  case "Attack":defaultOption6 = "Ench Diamond Sword";break;}
  setSelectedOption6(defaultOption6);
    if (newOption === "Exploring") {
      setCurrentQuestion("¿Exploring?");
      setShowOptions2(true);} 
    else if (newOption === "Archer") {
      setCurrentQuestion("¿Archer?");
      setShowOptions2(true);}
    else if (newOption === "Triton") {
      setCurrentQuestion("¿Triton?");
      setShowOptions2(true);}
    else if (newOption === "Attack") {
      setCurrentQuestion("¿Attack?");
      setShowOptions2(true);}
    else {}};

    

const [selectedOption1, setSelectedOption1] = useState('Steve');
const options1 = ["Steve", "Villager", "Enderman", "Skeleton Wither", "Skeleton", "Iron Golem", "Pigman", "Zombie"]; 

const handleOptionChange1 = (newOption) => {setSelectedOption1(newOption);
  if (newOption === 'Steve' || newOption === 'Villager' || newOption === 'Iron Golem' || newOption === 'Pigman' || newOption === 'Zombie') {
    setCurrentQuestion1("¿General?");;} 
  else if (newOption ==='Skeleton' || newOption === 'Skeleton Wither') {
    setCurrentQuestion1("¿Skeletons?");;}
  else if (newOption === "Enderman") {
    setCurrentQuestion1("¿Enderman?");;}
  else {}
};



const [selectedOption2, setSelectedOption2] = useState("Ench Diamond Helmet");  //Casco
const options2 = ["Arabian Turban", "Arrows", "Balaclava Camouflaged", "Chefs Hat", "Cow Head", "Cowboy Hat", "Creeper Head", "Diamond Helmet", "Dragon Head", "Ench Diamond Helmet", "Ench Iron Helmet", "Ench Netherite Helmet", "Firefighter Helmet", "Glasses", "Gold Helmet", "Headband", "Helmet Medieval", "Helmet of Iron Man", "Helmet of Spartan", "Iron Helmet", "Jack o'Lantern", "Leather Cap", "Mask of Paper House", "Netherite Helmet", "Prisoner Hat",];


  

const [selectedOption3, setSelectedOption3] = useState("Ench Diamond Chestplate"); //pecho
const options3 = ["Camouflage jacket", "Chestplate Medieval", "Chestplate of Iron Man", "Chestplate of Spartan", "Diamond Chestplate", "Ench Diamond Chestplate", "Ench Iron Chestplate", "Ench Netherite Chestplate", "Filipina Chefs", "Firefighter Jacket", "Golden Chestplate", "Hawaiian T-shirt", "Iron Chestplate", "Jacket of Paper House", "Karate Tunic", "Leather Tunic", "Netherite Chestplate", "Prisoner Jacket", "Smoking Jacket", "Sports Jacket",];


const [selectedOption4, setSelectedOption4] = useState("Ench Diamond Leggings"); //pantalones
const options4 = ["Camouflage jeans", "Chef Trousers", "Diamond Leggings", "Ench Diamond Leggings", "Ench Iron Leggings", "Ench Netherite Leggings", "Firefighter Pants", "Gold Leggings", "Hawaiian Shorts", "Iron Leggings", "Karate Trousers", "Leather Pants", "Netherite Leggings", "Pants Medieval", "Pants of Iron Man", "Pants of Paper House", "Pants of Spartan", "Prisoner Sweatpants", "Sports Leggings", "Suit Pants",];


const [selectedOption5, setSelectedOption5] = useState("Ench Diamond Boots"); //Boots
const options5 = ["Barefoot", "Boots of Iron Man", "Boots of Paper House", "Boots of Spartan", "Camouflage Boots", "Chef Sandals", "Diamond Boots", "Ench Diamond Boots", "Ench Iron Boots", "Ench Netherite Boots", "Firefighter Boots", "Gold Boots", "Hawaiian Sandals", "Iron Boots", "Leather Boots", "Netherite Boots", "Prisoner Shoes", "Sabatons Medieval", "Sports Shoes", "Suit shoes",];




const [selectedOption6, setSelectedOption6] = useState(() => {let initialDefaultOption6;
  switch (selectedOption) { case "Exploring":initialDefaultOption6 = "Ench Diamond Sword"; break;
    case "Bow Attack": initialDefaultOption6 = "Ench Bow"; break;
    case "Tritón":initialDefaultOption6 = "Trident Ench";break;
    case "Attack":initialDefaultOption6 = "Ench Diamond Sword";break;}
  return initialDefaultOption6;});
const options6 = { Exploring: ["Bottle o'Enchanting", "Ench bow", "Chest", "Cooked Salmon", "Crafting Table", "Diamond Axe", "Dragon Head", "Elytra", "Ench Diamond Sword", "Fishing Rod", "Golden Apple", "Grass Block", "Iron Pickaxe", "Netherite Sword", "Redstone Dust", "Stone", "TNT", "Torch", "Totem of Undying", "Wooden Pickaxe",],
Archer: ["Bow", "Crossbow", "Ench Bow", "Ench Crossbow"],
Triton: ["Trident Ench", "Trident"],
Attack : [ "Bow", "Diamond Axe", "Diamond Hoe", "Diamond Pickaxe", "Diamond Shovel", "Diamond Sword", "Ench Bow", "Ench Diamond Axe", "Ench Diamond Hoe", "Ench Diamond Pickaxe", "Ench Diamond Shovel", "Ench Diamond Sword", "Ench Iron Axe", "Ench Iron Pickaxe", "Ench Iron Sword", "Ench Netherite Axe", "Ench Netherite Hoe", "Ench Netherite Pickaxe", "Ench Netherite Shovel", "Ench Netherite Sword", "Golden Sword", "Iron Axe", "Iron Pickaxe", "Iron Sword", "Netherite Axe", "Netherite Hoe", "Netherite Pickaxe", "Netherite Shovel", "Netherite Sword", "Stone Pickaxe", "Stone Sword", "Wooden Axe", "Wooden Pickaxe"], 
};



const [selectedOption7, setSelectedOption7] = useState("Ench Shield"); //mano iz
const options7 = {Exploring : ["Block of Diamond", "compass", "Cooked Porkchop", "Diamond Hoe", "Diamond Pickaxe", "Egg", "Ench Iron Sword", "Ench Netherite Axe", "Ench Netherite Sword", "Ench Shield", "Ender Pearl", "Firework Rocket", "Flint and Steel", "Iron Shovel", "Milk Bucket", "Nether Star", "Piston", "Pumpkin Pie", "Shield", "Soul Torch"],
Archer: ["Charging"],
Triton : ["Block of Diamond", "compass", "Cooked Porkchop", "Diamond Hoe", "Diamond Pickaxe", "Egg", "Ench Iron Sword", "Ench Netherite Axe", "Ench Netherite Sword", "Ench Shield", "Ender Pearl", "Firework Rocket", "Flint and Steel", "Iron Shovel", "Milk Bucket", "Nether Star", "Piston", "Pumpkin Pie", "Shield", "Soul Torch"],
Attack : ["Block of Diamond", "compass", "Cooked Porkchop", "Diamond Hoe", "Diamond Pickaxe", "Egg", "Ench Iron Sword", "Ench Netherite Axe", "Ench Netherite Sword", "Ench Shield", "Ender Pearl", "Firework Rocket", "Flint and Steel", "Iron Shovel", "Milk Bucket", "Nether Star", "Piston", "Pumpkin Pie", "Shield", "Soul Torch"],};


const [selectedOption8, setSelectedOption8] = useState("Superflat World"); //fondo
const options8 = [  "Amethyst Geode", "Attack of Dragon", "Attack of Wither", "Attack of Zombie Village", "Badlands", "Beach", "Beacon", "Diamond chest", "Dungeons of the End", "End Crystal", "Exit Portal Off", "Hunger Game", "Initial box", "Mineshaft Abandoned", "Portal of the End", "Portal of the Nether", "Skyblock", "Superflat World", "The Nether", "Zombie Spawner","Amethyst Geode", "Attack of Dragon", "Attack of Wither", "Attack of Zombie Village", "Badlands", "Beach", "Beacon", "Diamond chest", "Dungeons of the End", "End Crystal", "Exit Portal Off", "Hunger Game", "Initial box", "Mineshaft Abandoned", "Portal of the End", "Portal of the Nether", "Skyblock", "Superflat World", "The Nether", "Zombie Spawner"];



const [isButtonDisabled, setIsButtonDisabled] = useState(false);
const [timeoutId, setTimeoutId] = useState(null);


const handleCycleOptions = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex = options.indexOf(selectedOption);
  const nextIndex = (currentIndex + 1) % options.length; 
  handleOptionChange(options[nextIndex]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};


const handleCycleOptions1 = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex = options1.indexOf(selectedOption1);
  const nextIndex = (currentIndex + 1) % options1.length; 
  handleOptionChange1(options1[nextIndex]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};

const handleNextOption2 = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex = options2.indexOf(selectedOption2);
  const nextIndex = (currentIndex + 1) % options2.length; 
  setSelectedOption2(options2[nextIndex]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};
  
const handleNextOption3 = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex = options3.indexOf(selectedOption3);
  const nextIndex = (currentIndex + 1) % options3.length; 
  setSelectedOption3(options3[nextIndex]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};

const handleNextOption4 = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex = options4.indexOf(selectedOption4);
  const nextIndex = (currentIndex + 1) % options4.length; 
  setSelectedOption4(options4[nextIndex]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};

const handleNextOption5 = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex = options5.indexOf(selectedOption5);
  const nextIndex = (currentIndex + 1) % options5.length; 
  setSelectedOption5(options5[nextIndex]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};

const handleOptionChange6 = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex6 = options6[selectedOption].indexOf(selectedOption6);
  const nextIndex6 = (currentIndex6 + 1) % options6[selectedOption].length;
  setSelectedOption6(options6[selectedOption][nextIndex6]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};

const handleOptionChange7 = () => {
  if (isButtonDisabled) {audio3.play();return;}
  const currentIndex7 = options7[selectedOption].indexOf(selectedOption7);
  const nextIndex7 = (currentIndex7 + 1) % options7[selectedOption].length;
  setSelectedOption7(options7[selectedOption][nextIndex7]);
  audio2.play();
  setIsButtonDisabled(true); const newTimeoutId =
  setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
  setTimeoutId(newTimeoutId);};


const handleNextOption8 = () => {
if (isButtonDisabled) {audio3.play();return;}

  const currentIndex = options8.indexOf(selectedOption8);
  const nextIndex = (currentIndex + 1) % options8.length; 
  setSelectedOption8(options8[nextIndex]);
  audio2.play();

setIsButtonDisabled(true); const newTimeoutId =
setTimeout(() => { setIsButtonDisabled(false); setTimeoutId(null);}, 2000); 
setTimeoutId(newTimeoutId); imageGifs(true); 
};

function imageGifs(show) {
  setLoadingImageShown(show);

  if (show) {
    setTimeout(() => {
      setLoadingImageShown(false); 
    }, 600); 
  }
}





useEffect(() => {
  reloadGifs();
}, [selectedOption, selectedOption1, selectedOption2, selectedOption3, 
    selectedOption4, selectedOption5, selectedOption6, selectedOption7]);
    
const reloadGifs = () => {
  clearTimeout(reloadTimeoutId);
  setLoadingImageShown(true); 
  setReloadTimeoutId(setTimeout(() => {
    setAllGifsReloaded(true);
    const imgs = document.querySelectorAll("img[src$='.gif']:not(#backgroundGif)"); 
    for (const img of imgs) { img.src = img.src;}
    setLoadingImageShown(false); 
  }, 600)); 
};

const [visibleCanvas, setVisibleCanvas] = useState('PHYGITAL NFT'); 

const [imageChanged, setImageChanged] = useState(false);

const [activeButton, setActiveButton] = useState(1);



const handlePhygitalNFTClick = () => {
  setVisibleCanvas('PHYGITAL NFT');
  canvasRef2.current.style.display = 'block';
  canvasRef3.current.style.display = 'none';
};

const handleNFCCardClick = () => {
  setVisibleCanvas('NFC CARD');
  canvasRef2.current.style.display = 'none';
  canvasRef3.current.style.display = 'block';
};

const [sceneData, setSceneData] = useState(null);
const [sceneData2, setSceneData2] = useState(null);
const [sceneData3, setSceneData3] = useState(null);

const canvasRef = useRef(null);
const canvasRef2 = useRef(null);
const canvasRef3 = useRef(null);

useEffect(() => {
  if (activeButton === 1) {
    const scene = createScene(canvasRef.current);
    setSceneData(scene);
  }
}, [activeButton, canvasRef]);


useEffect(() => {
  if (sceneData && activeButton !== 1) {

  }
}, [activeButton, sceneData]);


useEffect(() => {
  if  (activeButton === 5 && currentQuestion2 === '¿PHYGITAL NFT?') {const scene = createScene2(canvasRef2.current);
setSceneData2(scene);}
}, [activeButton, currentQuestion2, canvasRef3]);

useEffect(() => {
  if (sceneData2 && activeButton === 5 && currentQuestion2 === '¿PHYGITAL NFT?') {}
}, [activeButton, currentQuestion2, sceneData]);




useEffect(() => {
  if  (activeButton === 5 && currentQuestion2 === '¿PHYGITAL NFT?') {const scene = createScene3(canvasRef3.current);
setSceneData3(scene);}
}, [activeButton, currentQuestion2, canvasRef3]);

useEffect(() => {
  if (sceneData3 && activeButton === 5 && currentQuestion2 === '¿PHYGITAL NFT?') {}
}, [activeButton, currentQuestion2, sceneData]);

const canvasReflogo = useRef(null);
const [sceneDatalogo, setSceneDatalogo] = useState(null);


useEffect(() => {
  const canvasContainer = canvasReflogo.current;
  if (canvasContainer) {
    const { scene, renderer } = createScenelogo(canvasContainer);
    setSceneDatalogo({ scene, renderer });
  }
}, [canvasReflogo]);




const [showMsg, setShowMsg] = useState(false);
const [showMsgRoad, setShowMsgRoad] = useState(false);
const [showMsgWinner, setShowMsgWiiner]  = useState(false);
const [showMsgRoad1, setShowMsgRoad1] = useState(false);
const [showMsgRoad2, setShowMsgRoad2] = useState(false);
const [showMsg1, setShowMsg1] = useState(false);
const [showMsg2, setShowMsg2] = useState(false);
const [messageDismissed, setMessageDismissed] = useState(false);
const [messageDismissed1, setMessageDismissed1] = useState(false);



const [hovered, setHovered] = useState(false);
const [hovered1, setHovered1] = useState(false);
const [hovered2, setHovered2] = useState(false);
const [hovered3, setHovered3] = useState(false);
const [hovered4, setHovered4] = useState(false);
const [hovered5, setHovered5] = useState(false);  
const [hovered6, setHovered6] = useState(false);
const [hovered7, setHovered7] = useState(false);
const [hovered8, setHovered8] = useState(false);
const [hovered9, setHovered9] = useState(false);
const [hovered10, setHovered10] = useState(false);
const [hovered11, setHovered11] = useState(false);
const [hovered12, setHovered12] = useState(false);
const [hovered13, setHovered13] = useState(false);
const [hovered14, setHovered14] = useState(false);
const [hovered15, setHovered15] = useState(false);
const [hovered16, setHovered16] = useState(false);
const [hovered17, setHovered17] = useState(false);
const [hovered18, setHovered18] = useState(false);
const [hovered19, setHovered19] = useState(false);
const [hovered20, setHovered20] = useState(false);
const [hovered21, setHovered21] = useState(false);
const [hovered22, setHovered22] = useState(false);
const [hovered23, setHovered23] = useState(false);
const [hovered24, setHovered24] = useState(false);
const [hovered25, setHovered25] = useState(false);
const [hovered26, setHovered26] = useState(false);
const [hovered27, setHovered27] = useState(false);
const [hovered28, setHovered28] = useState(false);



const handleMouseEnter = () => {   setHovered(true); }; const handleMouseLeave = () => {   setHovered(false); };
const handleMouseEnter1 = () => {   setHovered1(true); }; const handleMouseLeave1 = () => {   setHovered1(false); };
const handleMouseEnter2 = () => {   setHovered2(true); }; const handleMouseLeave2 = () => {   setHovered2(false); };
const handleMouseEnter3 = () => {   setHovered3(true); }; const handleMouseLeave3 = () => {   setHovered3(false); };
const handleMouseEnter4 = () => {   setHovered4(true); }; const handleMouseLeave4 = () => {   setHovered4(false); };
const handleMouseEnter5 = () => {   setHovered5(true); }; const handleMouseLeave5 = () => {   setHovered5(false); };
const handleMouseEnter6 = () => {   setHovered6(true); }; const handleMouseLeave6 = () => {   setHovered6(false); };
const handleMouseEnter7 = () => {   setHovered7(true); }; const handleMouseLeave7 = () => {   setHovered7(false); };
const handleMouseEnter8 = () => {   setHovered8(true); }; const handleMouseLeave8 = () => {   setHovered8(false); };
const handleMouseLeave9 = () => {   setHovered9(false); }; const handleMouseEnter9 = () => {   setHovered9(true); };
const handleMouseLeave10 = () => {   setHovered10(false); }; const handleMouseEnter10 = () => {   setHovered10(true); };
const handleMouseLeave11 = () => {   setHovered11(false); }; const handleMouseEnter11 = () => {   setHovered11(true); };
const handleMouseLeave12 = () => {   setHovered12(false); }; const handleMouseEnter12 = () => {   setHovered12(true); };
const handleMouseLeave13 = () => {   setHovered13(false); }; const handleMouseEnter13 = () => {   setHovered13(true); };
const handleMouseLeave14 = () => {   setHovered14(false); }; const handleMouseEnter14 = () => {   setHovered14(true); };
const handleMouseLeave15 = () => {   setHovered15(false); }; const handleMouseEnter15 = () => {   setHovered15(true); };
const handleMouseLeave16 = () => {   setHovered16(false); }; const handleMouseEnter16 = () => {   setHovered16(true); }; 
const handleMouseLeave17 = () => {   setHovered17(false); }; const handleMouseEnter17 = () => {   setHovered17(true); }; 
const handleMouseLeave18 = () => {   setHovered18(false); }; const handleMouseEnter18 = () => {   setHovered18(true); }; 
const handleMouseLeave19 = () => {   setHovered19(false); }; const handleMouseEnter19 = () => {   setHovered19(true); }; 
const handleMouseLeave20 = () => {   setHovered20(false); }; const handleMouseEnter20 = () => {   setHovered20(true); }; 
const handleMouseLeave21 = () => {   setHovered21(false); }; const handleMouseEnter21 = () => {   setHovered21(true); }; 
const handleMouseLeave22 = () => {   setHovered22(false); }; const handleMouseEnter22 = () => {   setHovered22(true); }; 
const handleMouseLeave23 = () => {   setHovered23(false); }; const handleMouseEnter23 = () => {   setHovered23(true); }; 
const handleMouseLeave24 = () => {   setHovered24(false); }; const handleMouseEnter24 = () => {   setHovered24(true); }; 
const handleMouseLeave25 = () => {   setHovered25(false); }; const handleMouseEnter25 = () => {   setHovered25(true); }; 
const handleMouseLeave26 = () => {   setHovered26(false); }; const handleMouseEnter26 = () => {   setHovered26(true); }; 
const handleMouseLeave27 = () => {   setHovered27(false); }; const handleMouseEnter27 = () => {   setHovered27(true); }; 
const handleMouseLeave28 = () => {   setHovered28(false); }; const handleMouseEnter28 = () => {   setHovered28(true); }; 


const [IsHovered, setIsHovered] = useState(false);
const [IsHovered1, setIsHovered1] = useState(false);
const [IsHovered2, setIsHovered2] = useState(false);
const [IsHovered3, setIsHovered3] = useState(false);
const [IsHovered4, setIsHovered4] = useState(false);
const [IsHovered5, setIsHovered5] = useState(false);  
const [IsHovered6, setIsHovered6] = useState(false);
const [IsHovered7, setIsHovered7] = useState(false);
const [IsHovered8, setIsHovered8] = useState(false);
const [IsHovered9, setIsHovered9] = useState(false);
const [IsHovered10, setIsHovered10] = useState(false);
const [IsHovered11, setIsHovered11] = useState(false);
const [IsHovered12, setIsHovered12] = useState(false);
const [IsHovered13, setIsHovered13] = useState(false);
const [IsHovered14, setIsHovered14] = useState(false);
const [IsHovered15, setIsHovered15] = useState(false);
const [IsHovered16, setIsHovered16] = useState(false);
const [IsHovered17, setIsHovered17] = useState(false);
const [IsHovered18, setIsHovered18] = useState(false);
const [IsHovered19, setIsHovered19] = useState(false);
const [IsHovered20, setIsHovered20] = useState(false);
const [IsHovered21, setIsHovered21] = useState(false);
const [IsHovered22, setIsHovered22] = useState(false);
const [IsHovered23, setIsHovered23] = useState(false);
const [IsHovered24, setIsHovered24] = useState(false);
const [IsHovered25, setIsHovered25] = useState(false);
const [IsHovered26, setIsHovered26] = useState(false);
const [IsHovered27, setIsHovered27] = useState(false);
const [IsHovered28, setIsHovered28] = useState(false);


const handleTouchStart = () => {setIsHovered(true);};  const handleTouchEnd = () => {setIsHovered(false);}; 
const handleTouchStart1 = () => {setIsHovered1(true); }; const handleTouchEnd1 = () => { setIsHovered1(false); };
const handleTouchStart2 = () => {setIsHovered2(true); }; const handleTouchEnd2 = () => {setIsHovered2(false); };
const handleTouchStart3 = () => {setIsHovered3(true); }; const handleTouchEnd3 = () => {setIsHovered3(false); };
const handleTouchStart4 = () => {setIsHovered4(true); }; const handleTouchEnd4 = () => {setIsHovered4(false); };
const handleTouchStart5 = () => {setIsHovered5(true); }; const handleTouchEnd5 = () => {setIsHovered5(false); };
const handleTouchStart6 = () => {setIsHovered6(true); }; const handleTouchEnd6 = () => {setIsHovered6(false); };
const handleTouchStart7 = () => {setIsHovered7(true); }; const handleTouchEnd7 = () => {setIsHovered7(false); };
const handleTouchStart8 = () => {setIsHovered8(true); }; const handleTouchEnd8 = () => {setIsHovered8(false); };
const handleTouchEnd9 = () => {setIsHovered9(false); }; const handleTouchStart9 = () => {setIsHovered9(true); };
const handleTouchEnd10 = () => {setIsHovered10(false); }; const handleTouchStart10 = () => {setIsHovered10(true); };
const handleTouchEnd11 = () => {setIsHovered11(false); }; const handleTouchStart11 = () => {setIsHovered11(true); };
const handleTouchEnd12 = () => {setIsHovered12(false); }; const handleTouchStart12 = () => {setIsHovered12(true); };
const handleTouchEnd13 = () => {setIsHovered13(false); }; const handleTouchStart13 = () => {setIsHovered13(true); };
const handleTouchEnd14 = () => {setIsHovered14(false); }; const handleTouchStart14 = () => {setIsHovered14(true); };
const handleTouchEnd15 = () => {setIsHovered15(false); }; const handleTouchStart15 = () => {setIsHovered15(true); };
const handleTouchEnd16 = () => {setIsHovered16(false); }; const handleTouchStart16 = () => {setIsHovered16(true); }; 
const handleTouchEnd17 = () => {setIsHovered17(false); }; const handleTouchStart17 = () => {setIsHovered17(true); }; 
const handleTouchEnd18 = () => {setIsHovered18(false); }; const handleTouchStart18 = () => {setIsHovered18(true); }; 
const handleTouchEnd19 = () => {setIsHovered19(false); }; const handleTouchStart19 = () => {setIsHovered19(true); }; 
const handleTouchEnd20 = () => {setIsHovered20(false); }; const handleTouchStart20 = () => {setIsHovered20(true); }; 
const handleTouchEnd21 = () => {setIsHovered21(false); }; const handleTouchStart21 = () => {setIsHovered21(true); }; 
const handleTouchEnd22 = () => {setIsHovered22(false); }; const handleTouchStart22 = () => {setIsHovered22(true); }; 
const handleTouchEnd23 = () => {setIsHovered23(false); }; const handleTouchStart23 = () => {setIsHovered23(true); }; 
const handleTouchEnd24 = () => {setIsHovered24(false); }; const handleTouchStart24 = () => {setIsHovered24(true); }; 
const handleTouchEnd25 = () => {setIsHovered25(false); }; const handleTouchStart25 = () => {setIsHovered25(true); }; 
const handleTouchEnd26 = () => {setIsHovered26(false); }; const handleTouchStart26 = () => {setIsHovered26(true); }; 
const handleTouchEnd27 = () => {setIsHovered27(false); }; const handleTouchStart27 = () => {setIsHovered27(true); }; 
const handleTouchEnd28 = () => {setIsHovered28(false); }; const handleTouchStart28 = () => {setIsHovered28(true); }; 

useEffect(() => {const timers = [];
const activateHovered = (hovered, setHovered, delay, falseDelay) => { const timer = setTimeout(() => 
{setHovered(true); const timer2 = setTimeout(() => setHovered(false), 
falseDelay); timers.push(timer2);}, delay); timers.push(timer);};
activateHovered(hovered6, setHovered6,   3600, 1000); 
activateHovered(hovered7, setHovered7,   3800, 1050);
activateHovered(hovered8, setHovered8,   4000, 1100); 
activateHovered(hovered9, setHovered9,   4200, 1150);
activateHovered(hovered10, setHovered10, 4400, 1200);
activateHovered(hovered11, setHovered11, 4600, 1250); 
  return () => timers.forEach(clearTimeout);}, []);

useEffect(() => {const timers = [];
const activateHovered = (IsHovered, setIsHovered, delay, falseDelay) => { const timer = setTimeout(() => 
{setIsHovered(true); const timer2 = setTimeout(() => setIsHovered(false), 
falseDelay); timers.push(timer2);}, delay); timers.push(timer);};
activateHovered(IsHovered6, setIsHovered6,   3600, 1000); 
activateHovered(IsHovered7, setIsHovered7,   3800, 1050);
activateHovered(IsHovered8, setIsHovered8,   4000, 1100); 
activateHovered(IsHovered9, setIsHovered9,   4200, 1150);
activateHovered(IsHovered10, setIsHovered10, 4400, 1200);
activateHovered(IsHovered11, setIsHovered11, 4600, 1250); 
return () => timers.forEach(clearTimeout);}, []);


const frases = [
'CREATING MAGIC!',
'LUCK IS COOKING!',
'THE DICE ARE ROLLING!',
'WE ARE ABOUT TO SURPRISE YOU!',
'FATE IS DECIDED NOW!',
'GET READY FOR THE BIG ANNOUNCEMENT!',
'SOMETHING INCREDIBLE IS ABOUT TO HAPPEN!',
'IT WILL BE WORTH THE WAIT!',
'THE STARS ARE ALIGNING!',
'THE EXCITEMENT IS ABOUT TO EXPLODE!',
'DO NOT FORGET TO FOLLOW THE RULES',
'DO NOT MISS THE NEWS',
'GET READY FOR THE ADVENTURE!',
'FUN IS GUARANTEED!',
'THE COMMUNITY IS WAITING FOR YOU!',
];

const [fraseActual, setFraseActual] = useState(0);

useEffect(() => {
  const intervalo = setInterval(() => {
    setFraseActual(fraseActual => (fraseActual + 1) % frases.length);
  }, 5000);

  return () => clearInterval(intervalo); 
}, []);



const [activeImage, setActiveImage] = useState(-1);

useEffect(() => {
  const interval = setInterval(() => {
    setActiveImage(prev => (prev === 2 ? -1 : prev + 1));
  }, activeImage === -1 ? 1000 : (activeImage + 1) * 400);

  return () => clearInterval(interval);
}, [activeImage]);

function ajustarEscaladoDispositivos() {
  const anchoVentana = window.innerWidth;
  const altoVentana = window.innerHeight;


  const anchoPromedioLaptop = 1366;
  const altoPromedioLaptop = 768;
  const anchoPromedioMac = 1440;
  const altoPromedioMac = 900;
  const anchoPromedioPC = 1920; 
  const altoPromedioPC = 1080;  


  const esMac = navigator.userAgent.includes("Mac"); 
  const esPC = !esMac && anchoVentana >= anchoPromedioPC && altoVentana >= altoPromedioPC;


  const escalaLaptop = 0.7; 
  const escalaMac = 0.7;    
  const escalaPC = 1.2  ;    


  let escalaFinal;
  if (esMac) {
    escalaFinal = escalaMac;
  } else if (esPC) {
    escalaFinal = escalaPC;
  } else {
    escalaFinal = escalaLaptop;
  }
  const escalaLimitada = Math.max(0.5, Math.min(1.2, escalaFinal)); 
  document.body.style.transform = `scaleX(${escalaLimitada}) scaleY(${escalaLimitada})`;
}

function ajustarEscaladoMovil() {
  const anchoVentana = window.innerWidth;
  const escala = Math.max(0.32, Math.min(1, (anchoVentana - 395) / 625)); 
  document.body.style.transform = `scaleX(${escala}) scaleY(${escala})`;
}


const mediaQuery = window.matchMedia("(max-width: 768px)"); 
function handleMediaQueryChange(e) {
  if (e.matches) { 
    window.addEventListener('resize', ajustarEscaladoMovil);
    ajustarEscaladoMovil();
  } else {
    window.addEventListener('resize', ajustarEscaladoDispositivos);
    ajustarEscaladoDispositivos();
  }
}

handleMediaQueryChange(mediaQuery);
mediaQuery.addListener(handleMediaQueryChange);




function cambiarImagenFondo() {
  document.body.style.backgroundImage = "url(/config/images/background.png)";
}
setTimeout(cambiarImagenFondo, 2000);


setTimeout(() => {
  document.body.style.transition = 'opacity 4S ease'; 
  document.body.style.opacity = 1;
}, 5); 



document.addEventListener("dragstart", function(event) {
  if (event.target.tagName === "img") {
    event.preventDefault();
  }
});
  

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  

  const [isImageVisible, setImageVisible] = useState(true);
  
  useEffect(() => { const timeoutId = setTimeout(() => {setImageVisible(false);}, 5500); return () => clearTimeout(timeoutId); }, []);


  

  function isMobile() {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  

  if (isMobile()) {
    return (

    <div ref={imageContainerRef}>


<s.Container flex={1} ai={"center"}
style= {{ padding: 24,position: "absolute", top: 100, left: 0, width: "100%", height: "120%"}}>
    
    <ResponsiveWrapper flex={1} style={{ position: "absolute"}} test > 


<canvas ref={canvasReflogo} alt="Conquest Of Paradise" style={{  position: "absolute", top: -25, left: -100, pointerEvents: 'none' }} />

</ResponsiveWrapper>

<ResponsiveWrapper flex={1} style={{ padding: 220, position: "absolute"}} test > 


<s.Container style={{  position: "absolute", top: -260, left: 75, pointerEvents: 'none' }}>

<StyledImg src={"/config/images/time.png"}
style={{ position: "absolute", top: 1745, left: -50, width: "950px",height: "350px" }} 
alt="image" draggable="false"/>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1997, fontSize: 24}} >
 <p  translate="no" style={{position: "relative", left: 28, display: 'flex', justifyContent: 'center', width: "160px"}}>DAY</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "169px"}}>HOUR</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>MIN</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "168px"}}>SEC</p>  
</CTNButton2>


{timeRemaining <= 0 ? ( 
<>
<CTNButton4 
style={{ position: "absolute", top: 1785, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 28}}>{fortime2}</p>  
</CTNButton4>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1848, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>UP</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>DA</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>TI</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>NG</p>  
</CTNButton2>

</>
) : (
<>
<CTNButton4 
style={{ position: "absolute", top: 1780, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 32}}>{fortime1}</p>  
</CTNButton4>


<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1848, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>{days}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>{hours}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>{minutes}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>{seconds}</p>  
</CTNButton2>
</>
        )}

</s.Container>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",}} test
>
  <s.Container flex={1} jc={"center"} ai={"center" }>

    <StyledImg
      src={"/config/images/More/TestBaseMovil.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 130, left: -100, width: "1200px",height: "1200px" }} />
  </s.Container>

</ResponsiveWrapper>


<s.Container style={{ position: "absolute", top: -11, left: 0 ,}}>


<StyledImg
  src={IsHovered6 ? "/config/images/Testw01.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: -100, width: "199px", height: "131px" }} />

<StyledImg
  src={IsHovered7 ? "/config/images/Testw02.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 100, width: "199px", height: "131px" }} />

<StyledImg
  src={IsHovered8 ? "/config/images/Testw03.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 300, width: "199px", height: "131px" }} />

<StyledImg
  src={IsHovered9 ? "/config/images/Testw04.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 500, width: "199px", height: "131px" }} />


<StyledImg
  src={IsHovered10 ? "/config/images/Testw05.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 700, width: "199px", height: "131px" }} />

  
<StyledImg
  src={IsHovered11 ? "/config/images/Testw06.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 900, width: "199px", height: "131px" }} />

  


  
</s.Container>

<s.Container style={{ position: "absolute", top: 0, left: 0 ,}} >

{activeButton === 1 && ( // boton Minting
  <div id="content1">
{ 
<>
<> 

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute", top: 450 }} test
>

<s.Container style={{ position: "relative", top: 88, left: 52,}} >


<StyledImg
      src={"/config/images/TestSteveWeb1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 159, left: -90,  width: "387px",height: "610px" }} />

<canvas ref={canvasRef} style={{position: "absolute", top: 260, left: -25, transform: 'scale(1.5)'}} />

  
</s.Container>


  <s.Container flex={1} jc={"center"} ai={"center"}>

    <StyledImg
      src={"/config/images/More/Testw1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: -100, width: "199px",height: "192px" }} 
      />

    <StyledImg
      src={"/config/images/More/TestSteveWeb1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 93, left: -30, width: "420px",height: "960px" }} />


    <StyledImg
      src={"/config/images/TestSteveWeb2.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 272, left: 430, width: "620px",height: "280px",  }} />


    <s.TextTitle
      style={{
        position: "absolute", top: 177, left: 550}}>

      {blockchain.account && blockchain.smartContract !== null ? ( 

      Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
        <>

<CTNButton
style={{position: "absolute", top: 210, left: -100, fontSize: 36,  width: "576px",pointerEvents: 'none' }}
>
MINTING HAS ENDED BUT YOU CAN STILL GET YOURS AT OPENSEA

</CTNButton>

  <CTNButton
    style={{position: "absolute", top: 520, left: -77,}}
    onClick={(e) =>{ window.open(opensealink, "_blank"); audio2.play();}}
    onTouchStart={() => handleTouchStart2()}
    onTouchEnd={() => handleTouchEnd2()}
  >
<StyledImg
src={IsHovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
OPENSEA
</CTNButton>
  </CTNButton>

        </>
      ) : 
        <>
          <s.AmountContainer style={{ position: "absolute", top: -0, left: 0, }}>



          <StyledImg
            src={"/config/images/More/button0.png"}
            alt="image"
            draggable="false"
            style={{ position: "absolute", top: 375, left: -130, width: "630px", height: "260px" }} />

            <CTNButton
            style={{ position: "absolute", top: 140, left: -45, fontSize: 42, pointerEvents: 'none' }}
          >
          <p translate="no">{data.totalSupply}&nbsp;/&nbsp;{CONFIG.MAX_SUPPLY}</p>
            </CTNButton>
            
          <s.SpacerSmall />
          <CTNButton
            style={{position: "absolute", top: 212, left: -45, fontSize: 56, pointerEvents: 'none'}}
          >
            PRICE&nbsp;<p translate="no">{(CONFIG.DISPLAY_COST * tokens).toString().substring(0,4)}&nbsp;{CONFIG.NETWORK.SYMBOL}</p>

            </CTNButton>

<CTNButton
  style={{position: "absolute", top: 280, left: -45, fontSize: 32, pointerEvents: 'none' }}>
   {walletAddress}
  </CTNButton>




  {blockchain.account !== "" ? (
  <>
  </>
  ) : null }

           <CTNButton
              style={{ position: "absolute", top: 396, left: 151, width: "77px", height: "93px", fontSize: 42}}
            >
  {tokens}
              </CTNButton>

            <StyledRoundButton   //minimo
            style={{ position: "absolute", top: 395, left: -80}}
            onClick={(e) => {
              e.preventDefault();
              settokens(1);
              audio2.play();
            } }
            onTouchStart={() => handleTouchStart()}
            onTouchEnd={() => handleTouchEnd()} 
          >
            <StyledImg
              style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
              src={IsHovered ? "/config/images/left2.png" : "/config/images/left.png"}
              alt="image"
              draggable="false"/>

          </StyledRoundButton>

            <StyledRoundButton  // menos 1
            style={{ position: "absolute", top: 395, left: 33}}
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                decrementtokens();
                audio2.play();
              } }
              
              onTouchStart={() => handleTouchStart1()}
              onTouchEnd={() => handleTouchEnd1()} 
            >
              <StyledImg
              style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
              src={IsHovered1 ? "/config/images/down2.png" : "/config/images/down.png"}
              alt="image"
              draggable="false"/>
                
            </StyledRoundButton>

            <StyledRoundButton // mas 1
            style={{ position: "absolute", top: 395, left: 263}}
              disabled={claimingNft ? 1 : 0}

              onClick={(e) => {
                e.preventDefault();
                incrementtokens();
                audio2.play(); }}
              onTouchStart={() => handleTouchStart2()}
              onTouchEnd={() => handleTouchEnd2()}
            >
              <StyledImg
              style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
                src={IsHovered2 ? "/config/images/up2.png" : "/config/images/up.png"}
                alt="image"
                draggable="false"/>
                
            </StyledRoundButton>

            <StyledRoundButton //maximo
            style={{ position: "absolute", top: 395, left: 376}}
            onClick={(e) => {
              e.preventDefault();
              settokens(CONFIG.MAX_PER_TX);
              audio2.play();
            } }
            onTouchStart={() => handleTouchStart3()}
            onTouchEnd={() => handleTouchEnd3()}
          >
            <StyledImg
            style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
              src={IsHovered3 ? "/config/images/right2.png" : "/config/images/right.png"}
              alt="image"
              draggable="false"/>

          </StyledRoundButton>

          <CTNButton
              style={{ position: "relative", top: 526, left: -124, fontSize: 50 }}
              disabled={claimingNft ? 1 : 0}
              onClick={() => {audio.play(); setIsHovered12(false);  setShowMsgRoad1(true)}}
              onTouchStart={() => handleTouchStart12()}
              onTouchEnd={() => handleTouchEnd12()}
            >
              <StyledImg
                src={IsHovered12 ? "/config/images/ActionBlack.png" : "/config/images/action0.png"}
                alt="image"
                draggable="false"
                style={{ position: "absolute", top: -3, left: 0, width: "595px", height: "100px"  }} />

           <CTNButton
            style={{position: "absolute", top: -2, left: -0, fontSize: 48,  width: "595px", height: "100px"  }}>
           {claimingNft ? <Loader speed="fast"  content="MINTING..." /> : "MINT"} 
            </CTNButton></CTNButton>


          </s.AmountContainer>

        </>
      ) : ( 
            <>

{blockchain.account === "" || blockchain.smartContract === null ? (
                <>

{blockchain.errorMsg === "" ? (
    <>
        {
  <s.Container ai={"center"} jc={"center"}>


  <CTNButton
    style={{position: "absolute", top: 520, left: -77,}}
    onClick={(e) => {
      e.preventDefault();
      dispatch(connect());
      getData();
      audio2.play();
    } }
    onTouchStart={() => handleTouchStart5()}
    onTouchEnd={() => handleTouchEnd5()}
  >
<StyledImg
src={IsHovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
  </CTNButton>
  


  <CTNButton
style={{position: "absolute", top: 210, left: -100, fontSize: 36,  width: "576px", pointerEvents: 'none' }}
>
WELCOME, ADVENTURER! ARE YOU READY TO MINT?
</CTNButton>

</s.Container>

        }


    </>
) : ( 
    <>

        {  


    <CTNButton
    style={{position: "absolute", top: 210, left: -100, fontSize: 36,  width: "576px",}}
    >
    {blockchain.errorMsg}
    </CTNButton>
    }

<CTNButton
    style={{position: "absolute", top: 520, left: -77,}}
    onClick={(e) => {
      e.preventDefault();
      dispatch(connect());
      getData();
      audio2.play();
    } }
    onTouchStart={() => handleTouchStart5()}
    onTouchEnd={() => handleTouchEnd5()}
  >
<StyledImg
src={IsHovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
  </CTNButton>

    </>
)}
           
</>
) : (
  
<>


                </>
              )}

            </>
          )}
    </s.TextTitle>



<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsgRoad1 &&(
<>

<StyledImg src={"/config/images/More/Difuse04.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 27, left: -80, width: "1075px",height: "914px" }}/>

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 125, width: "155px",height: "50px",  }} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 616, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 290, left: 210, width: "400px",height: "214px",  }} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 200, left: 340}} 
>
NOTIFICATION
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()}
onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false); setTimeout(() => setShowMsgRoad1(false) ); audio2.play();}}>
OKAY
</CTNButton2>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => {setIsHovered23(false), setShowMsgRoad1(false), window.open(opensealink, "_blank"); audio2.play();}}>
OPENSEA</CTNButton2>





<CTNButton4
style={{  transform: 'scale(2.3)', position: "absolute", top: 360, left: 243, fontSize: 13, width: "337px", height: "140px"}}>
OOPS, THE MINTING HASN'T STARTED YET. BUT DON'T WORRY, THE PRIV-SALE {privsale}, THE PRE-SALE OPENS {presale},  AND THE PUBLIC-SALE {publicsale}. GET READY TO GET YOURS!.
</CTNButton4>


</s.Container>


</>
)}

</s.Container>

  </s.Container>


</ResponsiveWrapper></>              
</>}
</div>
)}

{activeButton === 2 && ( // 2 boton Roadmap 
 <div id="content2">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute", top: 450 }} test
>
  

  <s.Container flex={1} jc={"center"} ai={"center" }>


    <StyledImg
      src={"/config/images/More/Testw2.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 100, width: "199px",height: "192px" }} />
      


  </s.Container>

<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

  <StyledImg
      src={"/config/images/More/roadw1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 100, left: -80, width: "1060px",height: "750px" }} />



<StyledImg //imagen difusa 
      src= "/config/images/More/roadw3new.png"
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />


<StyledImg //imagen difusa 
      src={IsHovered1 || IsHovered2 || IsHovered3 || IsHovered4 || IsHovered21 || IsHovered5 || IsHovered12
        || IsHovered14 || IsHovered15 || IsHovered16 || IsHovered17 || IsHovered18 || IsHovered19 || IsHovered20
         ? "/config/images/More/roadw difusa.png" : "/config/images/roadicow00.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />


</s.Container>


  <p style={{  transform: 'scale(1.35)', position: "absolute", top: 85, left: -5,}} > 

  <s.Container style={{ position: "absolute", top: 0, left: -87,}} > 

<s.Container style={{ position: "absolute", top: 109, left: 65,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 125, width: "180px",height: "50px" }} 
src={IsHovered1 ? "/config/images/msgRoad.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 125, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered1? <p>VIEW START</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///incio
style={{ position: "absolute", top: 70, left: 72}}
onTouchStart={() => handleTouchStart1()} onTouchEnd={() => handleTouchEnd1()}
onClick={() => {audio2.play();setcurrentQuestion6('¿START?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "108px",height: "121px" }} 
src={IsHovered1? "/config/images/roadicow1.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 



</StyledRoundButton> </s.Container>



<s.Container style={{ position: "absolute", top: 109, left: 242,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 115, width: "180px",height: "50px" }} 
src={IsHovered18 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 115, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered18? <p>VIEW PRIV-SALE</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///2
style={{ position: "absolute", top: 70, left: 70}}
onTouchStart={() => handleTouchStart18()} onTouchEnd={() => handleTouchEnd18()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PRIV-SALE?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "95px",height: "85px" }} 
src={IsHovered18? "/config/images/roadicow3.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 109, left: 398,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 115, width: "180px",height: "50px" }} 
src={IsHovered3 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 115, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered3? <p>VIEW PRE-SALE</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///3
style={{ position: "absolute", top: 70, left: 70}}
onTouchStart={() => handleTouchStart3()} onTouchEnd={() => handleTouchEnd3()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PRE-SALE?'); setShowMsgRoad(true)}}> 
<StyledImg style={{position: "absolute", width: "95px",height: "85px" }} 
src={IsHovered3? "/config/images/roadicow3.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton></s.Container>

<s.Container style={{ position: "absolute", top: 109, left: 549,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 115, width: "180px",height: "50px" }} 
src={IsHovered4 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 115, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered4? <p>VIEW PUBLIC-SALE</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///4
style={{ position: "absolute", top: 70, left: 74}}
onTouchStart={() => handleTouchStart4()} onTouchEnd={() => handleTouchEnd4()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PUBLIC-SALE?'); setShowMsgRoad(true)}}> 
<StyledImg style={{position: "absolute", width: "95px",height: "85px" }} 
src={IsHovered4? "/config/images/roadicow3.png" : "/config/images/roadicow00.png"} 
alt="image" draggable="false"/> </StyledRoundButton></s.Container>

<s.Container style={{ position: "absolute", top: 280, left: 542,}} > 

<StyledImg
style={{position: "absolute", top: 49, left: -125, width: "180px",height: "50px" }} 
src={IsHovered21 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 49, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered21? <p>VIEW AIRDROP</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///5
style={{ position: "absolute", top: 60, left: 70}}
onTouchStart={() => handleTouchStart21()} onTouchEnd={() => handleTouchEnd21()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdrop1?'); setShowMsgRoad(true)}}> 
<StyledImg style={{position: "absolute", width: "88px",height: "80px" }} 
src={IsHovered21?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/></StyledRoundButton></s.Container>

<s.Container style={{ position: "absolute", top: 280, left: 382,}} > 

<StyledImg
style={{position: "absolute", top: 49, left: -125, width: "180px",height: "50px" }} 
src={IsHovered12 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 49, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered12 ? <p>VIEW AIRDROP</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///6
style={{ position: "absolute", top: 60, left: 70}}
onTouchStart={() => handleTouchStart12()} onTouchEnd={() => handleTouchEnd12()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdrop2?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "88px",height: "80px" }} 
src={IsHovered12?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 280, left: 226,}} > 

<StyledImg
style={{position: "absolute", top: 49, left: -125, width: "180px",height: "50px" }} 
src={IsHovered19? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 49, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered19 ? <p>VIEW AIRDROP</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///7
style={{ position: "absolute", top: 60, left: 67}}
onTouchStart={() => handleTouchStart19()} onTouchEnd={() => handleTouchEnd19()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdrop3?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "88px",height: "80px" }} 
src={IsHovered19?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 372, left: 202,}} > 

<StyledImg
style={{position: "absolute", top: 120, left: 112, width: "180px",height: "50px" }} 
src={IsHovered15 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: 112, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered15? <p>VIEW AIRDROP ETH</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///8
style={{ position: "absolute", top: 130, left: 66}}
onTouchStart={() => handleTouchStart15()} onTouchEnd={() => handleTouchEnd15()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdropeth?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "88px",height: "80px" }} 
src={IsHovered15?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>


<s.Container style={{ position: "absolute", top: 372, left: 370,}} > 

<StyledImg
style={{position: "absolute", top: 120, left: 112, width: "180px",height: "50px" }} 
src={IsHovered14 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: 112, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered14? <p>VIEW SERVER</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///9
style={{ position: "absolute", top: 130, left: 62}}
onTouchStart={() => handleTouchStart14()} onTouchEnd={() => handleTouchEnd14()}
onClick={() => {audio2.play();setcurrentQuestion6('¿SERVER?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "96px",height: "94px" }}
src={IsHovered14? "/config/images/roadicow4.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 372, left: 532,}} > 

<StyledImg
style={{position: "absolute", top: 120, left: 112, width: "180px",height: "50px" }} 
src={IsHovered16 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: 112, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered16? <p>VIEW PHYGITAL</p>: ""  }
</CTNButton4> 
</s.Container>


<s.Container style={{ position: "absolute", top: 372, left: 700,}} >

<StyledImg
style={{position: "absolute", top: 120, left: -130, width: "180px",height: "50px" }} 
src={IsHovered20? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{IsHovered20 ? <p>VIEW END</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///end
style={{ position: "absolute", top: 130, left: 50}}
onTouchStart={() => handleTouchStart20()} onTouchEnd={() => handleTouchEnd20()}
onClick={() => {audio2.play();setcurrentQuestion6('¿END?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position:"absolute", width: "68px",height: "64px" }} 
src={IsHovered20? "/config/images/roadend.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> 

</s.Container>

<StyledRoundButton  ///10
style={{ position: "absolute", top: 503, left: 600}}
onTouchStart={() => handleTouchStart16()} onTouchEnd={() => handleTouchEnd16()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PHYGITAL?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "96px",height: "94px" }}
src={IsHovered16? "/config/images/roadicow4.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> 


</s.Container>


      </p>

      <s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsgRoad &&  (
<>

<StyledImg //imagen difusa 
src= "/config/images/More/roadw difusa.png"
alt="image"
draggable="false"
style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />

<s.Container style={{position: "absolute", top: 0, left: 45}} >
<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 210, width: "400px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>
</s.Container>

</>
)}

{currentQuestion6 ===  '¿START?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
  
<p style={{position: "relative", top: -30, display: 'flex',  justifyContent: 'center', fontSize: 28}}>START</p>  
<p style={{ position: "relative", top: 20, marginBottom: "5px" }}>GET READY FOR AN UNFORGETTABLE ADVENTURE! THE DAY {start} WE BEGIN AN EXTRAORDINARY JOURNEY TO OUR PARADISE.</p>  
</CTNButton4>
  {" "}


  <s.Container style={{position: "absolute", top: 0, left: 45}} >
<StyledImg
style={{   transform: 'scale(2.3) ', position: "absolute", top: 617, left: 340, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>
<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()}
onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setMessageDismissed(true), ); audio2.play();}}
 >
OKAY
</CTNButton2>
</s.Container>

  
</>)}

{currentQuestion6 ===  '¿PRE-SALE?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PRE-SALE</p>  
<p style={{  position: "relative", top: 30, marginBottom: "5px" }}>REGISTER BEFORE {closeregister}, WINNERS OF A PLACE WILL BE ANNOUNCED ON {inforegister}. WINNERS WILL BE ELIGIBLE TO PARTICIPATE IN THE PRE-SALE FROM {presale}. </p>  
</CTNButton4>
  {" "}

<s.Container style={{position: "absolute", top: 0, left: 45}} >

  
<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿PRIV-SALE?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PRIV-SALE</p>  
<p style={{  position: "relative", top: 30, marginBottom: "5px" }}>REGISTER BEFORE {closeregister}, WINNERS OF A PLACE WILL BE ANNOUNCED ON {inforegister}. WINNERS WILL BE ELIGIBLE TO PARTICIPATE IN THE PRIV-SALE FROM {privsale}. </p>  </CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}



{currentQuestion6 ===  '¿PUBLIC-SALE?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "780px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PUBLIC-SALE</p>  
<p style={{  position: "relative", top: 30, marginBottom: "5px" }}>TODAY {publicsale} IS THE MOMENT WE'VE ALL BEEN WAITING FOR! THE PUBLIC SALE HAS STARTED, THE {endpublicsale}, DAY ENDS, AND YOU WILL BE THE PROTAGONIST MINT AS MANY NFT AS YOU WANT.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdrop1?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM DAY {openairdrop1} TO DAY {closeairdrop1} TO PARTICIPATE FOR ONE OR MORE FREE NFT ON DAY {airdrop1} IF YOU WIN YOU WILL RECEIVE THEM IN THE NEXT 24 HOURS.</p> 
 </CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdrop2?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM DAY {openairdrop2} TO DAY {closeairdrop2} TO PARTICIPATE FOR ONE OR MORE FREE NFT ON DAY {airdrop2} IF YOU WIN YOU WILL RECEIVE THEM IN THE NEXT 24 HOURS.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdrop3?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM DAY {openairdrop3} TO DAY {closeairdrop3} TO PARTICIPATE FOR ONE OR MORE FREE NFT ON DAY {airdrop3} IF YOU WIN YOU WILL RECEIVE THEM IN THE NEXT 24 HOURS.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdropeth?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP ETH</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM {openairdropeth} TO {closeairdropeth}, TO PARTICIPATE FOR A PRIZE IN ETHEREUM. THE DRAW WILL TAKE PLACE AT {airdropeth}, AND IF YOU WIN YOU WILL RECEIVE IT IN THE NEXT 24 HOURS.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿SERVER?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>SERVER MINECRAFT</p>  
<p style={{position: "relative", top: 25,  marginBottom: "5px" }}>SERVER UNDER CONSTRUCTION, IT IS ESTIMATED TO BE LAUNCHED IN 4 MONTHS AFTER THE COLLECTION SELLS OUT OR THE MINTING PROCESS ENDS ON {endpublicsale} DAY.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false),  setActiveButton(5), setcurrentQuestion2('¿Server?'), 100); audio2.play();}}>
ABOUT</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿PHYGITAL?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PHYGITAL</p>  
<p style={{position: "relative", top: 25,  marginBottom: "5px" }}>OUR PHYGITAL IS BEING MANUFACTURED, ITS DISTRIBUTION IS ESTIMATED IN 6 MONTHS AFTER THE COLLECTION SELLS OUT OR THE MINTING PROCESS ENDS ON {endpublicsale} DAY.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()} onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(5), setcurrentQuestion2('¿PHYGITAL NFT?'), 100); audio2.play();}}>
ABOUT</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿END?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -30, display: 'flex',  justifyContent: 'center', fontSize: 28}}>END?</p>  
<p style={{ position: "relative", top: 25, marginBottom: "5px" }}>IT'S NOT THE END, THIS IS JUST THE BEGINNING. CONQUISTA EL PARAÍSO HAS THOUSANDS OF IDEAS AND SURPRISES IN STORE, DON'T MISS IT.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >
<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 340, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>
<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()}
onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setMessageDismissed(true), ); audio2.play();}}
 >
OKAY
</CTNButton2>
</s.Container>
</>)}
</s.Container>





<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsg && !messageDismissed &&  (
<>

<StyledImg //imagen difusa 
src= "/config/images/More/roadw difusa.png"
alt="image"
draggable="false"
style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 125, width: "155px",height: "50px",  }} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 210, width: "400px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 335, left: 340}} 
>
NOTIFICATION
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 450, left: 235, fontSize: 13, width: "356px",height: "70px",}} 
>
CHECK OUR ROADMAP AND OUR SOCIAL NETWORKS FOR UPDATES AND SURPRISES
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 620, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()}
onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false);  setTimeout(() => setShowMsg(false), setMessageDismissed(true), ); audio2.play();}}
 >
  
OKAY
</CTNButton2>


<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 616, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 620, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered2(false); setTimeout(() => setMessageDismissed(true), setShowMsg(false), setActiveButton(6), setcurrentQuestion00('¿ROADMAP?')); audio2.play();}}>
ABOUT</CTNButton2>

</s.Container>

</>
)}

</s.Container>


</ResponsiveWrapper>

</>              
</>}
</div>
)}

{activeButton === 3 && ( // 3 boton Caracteristicas
 <div id="content3">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",  top: 450  }} test
>


  <s.Container flex={1} jc={"center"} ai={"center"}>
      
  <StyledImg
      src={"/config/images/More/Testw3.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top:  -122,  left: 299, width: "200px",height: "192px" }} />

      
<s.Container style={{  position: "absolute", top: 370, left: 78,}} >


<StyledImg id="backgroundGif" src={`./gifs/background/${selectedOption8}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>


<StyledImg src={"/config/images/More/Caracteristicas02.png"}
alt="image" draggable="false" style={{ position: "absolute", top: -350, left: -140, width: "1120px",height: "900px" }}/>



{currentQuestion ===  '¿Exploring?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Exploring/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
{" "}

</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Exploring/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Exploring/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{" "}

</>)}
{currentQuestion ===  '¿Archer?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Archer/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Archer/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Bones/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Archer/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
{" "}

</>)}

{" "}

</>)}
{currentQuestion ===  '¿Triton?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Triton/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
{" "}

</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Triton/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Triton/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{" "}

</>)}
{currentQuestion ===  '¿Attack?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Attack/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Attack/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Bones/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Attack/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{" "}

</>)}





{loadingImageShown && (
  <StyledImg src="./gifs/1M.png" 
alt="image" draggable="false" style={{  position: "absolute", top: -350, left: -140, width: "1120px",height: "900px" }}/>)}



</s.Container>


  <s.Container style={{  position: "absolute", top: 190, left: 0, }} >


<StyledRoundButton  //1
style={{ position: "absolute", top: -10, left: 49}} 
onTouchStart={() => handleTouchStart1()} onTouchEnd={() => handleTouchEnd1()}
onClick={handleNextOption2}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px" }} 
src={IsHovered1? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //2
style={{ position: "absolute", top: 195, left: 35}} 
onTouchStart={() => handleTouchStart2()} onTouchEnd={() => handleTouchEnd2()}
onClick={handleNextOption3}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px" }} 
src={IsHovered2? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //3
style={{ position: "absolute", top: 336 , left: 35}} 
onTouchStart={() => handleTouchStart3()} onTouchEnd={() => handleTouchEnd3()}
onClick={handleOptionChange6}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={IsHovered3? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //4
style={{ position: "absolute", top: 638, left: 139 }} 
onTouchStart={() => handleTouchStart4()} onTouchEnd={() => handleTouchEnd4()}
onClick={handleNextOption5}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px" }} 
src={IsHovered4? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //5
style={{ position: "absolute", top: -10, left: 916}} 
onTouchStart={() => handleTouchStart18()} onTouchEnd={() => handleTouchEnd18()}
onClick={handleCycleOptions1}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={IsHovered18? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //6
style={{ position: "absolute", top: 262, left: 937}} 
onTouchStart={() => handleTouchStart12()} onTouchEnd={() => handleTouchEnd12()}
onClick={handleOptionChange7}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={IsHovered12? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //7
style={{ position: "absolute", top: 404, left: 937}} 
onTouchStart={() => handleTouchStart14()} onTouchEnd={() => handleTouchEnd14()}
onClick={handleNextOption4}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={IsHovered14? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //8
style={{ position: "absolute", top: 638, left: 827 }}
onTouchStart={() => handleTouchStart15()} onTouchEnd={() => handleTouchEnd15()}
onClick={handleNextOption8}>
<StyledImg
style={{ position: "absolute",width: "81px",height: "79px"  }} 
src={IsHovered15? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //9
style={{ position: "absolute", top: 638, left: 483 }}
onTouchStart={() => handleTouchStart16()} onTouchEnd={() => handleTouchEnd16()}
onClick={handleCycleOptions}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={IsHovered16? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>


<CTNButton2
style={{position: "absolute", top: -45, left: 225, fontSize: 22, }} >
EXPLORE THOUSANDS OF COMBINATIONS! 
</CTNButton2>


  </s.Container>


<s.Container style={{ position: "absolute", top: 109, left: 50}} > 


<CTNButton2 
style={{ position: "absolute", top: 850, left: 358, fontSize: 20 }} 
onTouchStart={() => handleTouchStart21()} onTouchEnd={() => handleTouchEnd21()}
onClick={() => {audio2.play(); setIsHovered21(false); setcurrentQuestion6('¿RARITY?'); setShowMsgRoad2(true)}}> 
<StyledImg
src={"/config/images/More/view.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -27, left: -33, width: "238px", height: "70px "}} />

<StyledImg
src={IsHovered21 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "200px", height: "51px "}} />
<CTNButton2
 translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: -13, width: "200px", height: "52px"}}>
VIEW DETAILS</CTNButton2> </CTNButton2>



</s.Container>

<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{currentQuestion6 ===  '¿RARITY?' && (<>

  {showMsgRoad2 && (
<>

<StyledImg src={"/config/images/More/Caracteristicas03.png"}
alt="image" draggable="false" style={{ position: "absolute", top: -65, left: -115, width: "1121px",height: "998px" }}/>

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 340, width: "155px",height: "50px",  }} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>


<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 290, left: 210, width: "400px",height: "214px",  }} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 200, left: 365}} 
>
RARITIES
</CTNButton2>


<CTNButton4
style={{ position: "absolute", top: 282, left: 10, border: '0px solid #ddd',  maxHeight: 302, padding: 10, overflowY: 'auto', width: "800px", height: "740px"  }}>
<p style={{ fontSize: 26, marginBottom: "20px" }}>CUSTOMIZE TO YOUR LIKING! CLICK ON THE ELEMENT NAME OR CHANGE ICON TO EDIT IT.</p> 
<p style={{ fontSize: 20, marginBottom: "5px" }}>HEAD </p>  
<p onClick={handleNextOption2} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption2} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>BODY</p>  
<p onClick={handleNextOption3} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption3} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>RIGHT HAND </p>  
<p onClick={handleOptionChange6} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption6} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>FEET</p>  
<p onClick={handleNextOption5} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption5} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>CHARACTER</p> 
<p onClick={handleCycleOptions1} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption1} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>LEFT HAND</p> 
<p onClick={handleOptionChange7} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption7} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>LEGS</p> 
<p onClick={handleNextOption4} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption4} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>BACKGROUND</p> 
<p onClick={handleNextOption8} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption8} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>ANIMATION</p> 
<p onClick={handleCycleOptions} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption} </p>
</CTNButton4>


<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()}
onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false); setTimeout(() => setShowMsgRoad2(false) ); audio2.play();}}>
BACK
</CTNButton2>



</s.Container>

</>
)}
  
</>)}

</s.Container>


  <s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsg1 && !messageDismissed1 &&  (
<>

<StyledImg src={"/config/images/More/Caracteristicas03.png"}
alt="image" draggable="false" style={{ position: "absolute", top: -65, left: -115, width: "1121px",height: "998px" }}/>

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 125, width: "155px",height: "50px",  }} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 616, left: 540, width: "155px",height: "50px"}} 
src={IsHovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>


<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 290, left: 210, width: "400px",height: "214px",  }} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 200, left: 340}} 
>
NOTIFICATION
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 400, left: 235, fontSize: 13, width: "353px",height: "70px"}} 
>
USE THE DEMO TO CREATE AN NFT THAT REFLECTS YOUR STYLE. THE COLLECTION HAS OVER 21 THOUSAND UNIQUE NFT CREATED FROM 9 TRAITS WITH APPROXIMATELY 300 UNIQUE DESIGNS PER TRAIT
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 622, left: 127,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()}
onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false); setTimeout(() => setMessageDismissed1(true), setShowMsg1(false) ); audio2.play();}}>
 
  
OKAY
</CTNButton2>



<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 622, left: 542,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart23()} onTouchEnd={() => handleTouchEnd23()}
onClick={(e) => { setIsHovered23(false); setTimeout(() => setMessageDismissed1(true), setShowMsg1(false), setActiveButton(6), setcurrentQuestion00('¿RARITY?')); audio2.play();}}>
RARITY</CTNButton2>

</s.Container>

</>
)}

</s.Container>



</s.Container>


</ResponsiveWrapper>

</>              
</>}

</div>
)}

{activeButton === 4 && ( // 4 boton airdrop
 <div id="content4">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute", top: 450 }} test
>

  <s.Container flex={1} jc={"center"} ai={"center" }>




  <StyledImg
      src={"/config/images/More/Testw4.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 499, width: "199px",height: "192px" }} />

  </s.Container>

  {timeRemaining <= 0 ? ( 
    <>
<s.Container style={{ position: "absolute", top: 130, left: 52,}} >


<StyledImg
src="/config/images/More/slider.png" alt="image"
className={activeImage !== -1 ? 'slide-in' : ''} // Add class conditionally
style={{position: "absolute",  top: 340, left: 380, width: "260px", height: "85px"}}/>

<StyledImg
  src={"/config/images/More/Play.png"}
  alt="image"
  draggable="false"
  style={{  position: "absolute", top: 140, left: -120, width: "1100px", height: "480px",  }}
   />



<StyledImg src={IsHovered14 ? "/config/images/More/PlayThor2.png" : "/config/images/More/PlayThor.png"}
alt="image" draggable="false" style={{  position: "absolute", top: 115, left: 780, width: "150px", height: "150px", transformOrigin: 'bottom right', animation: 'rotate 1.3s infinite linear'   }} />

{activeImage >= 0 && (
  <StyledImg 
    src="/config/images/More/Book1.png" 
    alt="image" 
    draggable="false" 
    style={{ position: "absolute", top: 340, left: 81, width: "85px", height: "85px"}} 
  />
)}
{activeImage >= 1 && (
  <StyledImg 
    src="/config/images/More/Book1.png" 
    alt="image" 
    draggable="false" 
    style={{ position: "absolute", top: 340, left: 370, width: "85px", height: "85px"}} 
  />
)}
{activeImage === 2 && (
  <StyledImg 
    src="/config/images/More/Book2.png" 
    alt="image" 
    draggable="false" 
    style={{ position: "absolute", top: 340, left: 732, width: "85px", height: "85px"}} 
  />
)}



<CTNButton4
style={{ position: "absolute",  pointerEvents: 'none', top: 490, left: 80, fontSize: 24, padding: 10, border: '0px solid #ddd', borderRadius: 5, maxHeight: 352, overflowY: 'auto', width: "740px", height: "60px",   }}>
  <p style={{ marginBottom: "20px" }}>{frases[fraseActual]}</p>  
</CTNButton4>



<CTNButton
style={{position: "absolute", top: 750, left: 180,}}
onTouchStart={() => handleTouchStart12()} onTouchEnd={() => handleTouchEnd12()}
onClick={(e) => { setIsHovered12(false); setTimeout(() =>setActiveButton(6), setcurrentQuestion00('¿GIVEAWAY?')); audio2.play();}}>

<StyledImg src={IsHovered12 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} 
alt="image" draggable="false" />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
VIEW ALL RULES
</CTNButton>
</CTNButton>


<CTNButton
style={{position: "absolute", top: 0, left: 180,}}
onTouchStart={() => handleTouchStart13()} onTouchEnd={() => handleTouchEnd13()}
onClick={() => {audio2.play(); setcurrentQuestion7('¿SELECTION OF WINNERS??');  setIsHovered14(true); setShowMsgWiiner(true)}}> 

<StyledImg src={IsHovered13 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} 
alt="image" draggable="false" />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
SELECTION OF WINNERS?
</CTNButton>
</CTNButton>


{showMsgWinner &&  (
<>

<StyledImg src= "/config/images/More/Winner.png" alt="image" draggable="false"
style={{ position: "absolute", top: -10, left: 142, width: "611px", height: "125px" }} />

<StyledImg src= "/config/images/More/Winner.png" alt="image" draggable="false"
style={{ position: "absolute", top: 750, left: 142, width: "611px", height: "115px" }} />



<s.Container style={{position: "absolute", top: -40, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 288, left: 210, width: "400px",height: "214px"}} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>
</s.Container>

<s.Container style={{position: "absolute", top: -40, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3) ', position: "absolute", top: 617, left: 340, width: "155px",height: "50px"}} 
src={IsHovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>
<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart22()}
onTouchEnd={() => handleTouchEnd22()}
onClick={(e) => { setIsHovered22(false); setIsHovered14(false);setIsHovered(false);  setTimeout(() => setcurrentQuestion7(false), setShowMsgWiiner(false)); audio2.play();}}
 >
OKAY
</CTNButton2>
</s.Container>


</>
)}

{currentQuestion7 ===  '¿SELECTION OF WINNERS??' && (<>
    <CTNButton4
style={{ position: "absolute", top: 23, left: 60, fontSize: 24, maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 28}}>INFORMATION</p>  
</CTNButton4>


<CTNButton4
style={{ position: "absolute", top: 240, left: 70, fontSize: 24, maxHeight: 300, overflowY: 'auto', width: "770px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Here's how we'll pick the lucky winners!</p> 
<p style={{ marginBottom: "10px" }}>List of participants:</p> 
<p style={{ marginBottom: "20px" }}>We gather everyone who signed up for the pre-sale and Priv-sale on their corresponding list.</p> 
<p style={{ marginBottom: "10px" }}>Automatic verification:</p> 
<p style={{ marginBottom: "20px" }}>Our code carefully checks the list to make sure that everyone complies with the rules of the game. Those who do not comply will unfortunately be eliminated.</p> 
<p style={{ marginBottom: "10px" }}>Random draw:</p> 
<p style={{ marginBottom: "20px" }}>Here comes the excitement! We use a random selection system, similar to a traditional lottery. Imagine that all the names of eligible participants are in a hat and we draw the winners at random - everyone has an equal chance to win.</p> 
<p style={{ marginBottom: "10px" }}>Unique winners:</p> 
<p style={{ marginBottom: "20px" }}>Our system is very careful to ensure that each winner is unique. This means no repeat wallet addresses, so more people have a chance to celebrate.</p> 
<p style={{ marginBottom: "10px" }}>Digital safe:</p> 
<p style={{ marginBottom: "20px" }}>Winners' names are kept in a safe and secure place, like a digital safe. Once it is locked, no one can change the results! This ensures the transparency and integrity of the sweepstakes.</p> 
<p style={{ marginBottom: "20px" }}>Under this transparent and verifiable code, conquest of paradise will choose the winners of all our contests.</p> 
<p style={{marginBottom: "20px" }}><CopiarCodigo/></p> 

</CTNButton4>

  {" "}

</>)}





</s.Container>
  </>

) : (
<>
<s.Container style={{ position: "absolute", top: 130, left: 52,}} > 
{
  (blockchain.account === "" || blockchain.smartContract === null) || !isChecked ? (
  <>


<StyledImg
  src={"/config/images/participa1.png"}
  alt="image"
  draggable="false"
  style={{  position: "absolute", top: 140, left: -33, width: "980px", height: "350px" }}
   />

<StyledImg style={{ transform: 'scale(1.5)',  position: "absolute", top: 30, left: 88, width: "730px",height: "70px" }} 
src={"/config/images/More/sv1.png"} alt="image" draggable="false"/>

<CTNButton2
  style={{ position: "absolute", top: 0 , left: 0, fontSize: 16}}
>
  {currentQuestion5 ===  '¿PRESALE?' && (
  <>
<CTNButton4
style={{ position: "absolute", top: 162, left: -30, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 315, overflowY: 'auto', width: "960px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Also participate in our Pre-Sale.</p> 

<p style={{ marginBottom: "10px" }}>* As a member of our Pre-sale, you will be able to:</p> 
<p style={{ marginBottom: "10px" }}>* Mintage a maximum of 199 NFTs</p> 
<p style={{ marginBottom: "10px" }}>* Continue your mint at the Public-sale.</p> 
<p style={{ marginBottom: "10px" }}>* Get a discount on each NFT by paying {presaleprice}.</p> 
<p style={{ marginBottom: "20px" }}>* Be among the first to get your Phygital NFTs.</p> 

<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see our Roadmap for dates related to the Pre-sale</p>  
<p style={{ marginBottom: "20px" }}>Make sure you have read the sweepstakes rules.</p>  

</CTNButton4>

<StyledImg  style={{transform: 'scale(1.5)', position: "absolute",top: 41 ,left: 351, width: "200px",height: "51px",}}
  src= "/config/images/aboutw3.png"   alt="image" draggable="false"/> </>)}

{currentQuestion5 ===  '¿PRIVSALE?' && (
  <>
<CTNButton4
style={{ position: "absolute", top: 162, left: -30, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 315, overflowY: 'auto', width: "960px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Don't miss our Private Sale, you can win a place in our Priv-sale!</p>  
<p style={{ marginBottom: "10px" }}>As a member of our Priv-sale, you will be able to:</p>  
<p style={{ marginBottom: "10px" }}>* Mintage a maximum of 599 NFTs</p>  
<p style={{ marginBottom: "10px" }}>* Access to all the next stages of the sale.</p>  
<p style={{ marginBottom: "10px" }}>* Get a great discount on every NFT, paying only {privsaleprice}.</p>  
<p style={{ marginBottom: "10px" }}>* Receive an exclusive gift to go with your Phygital NFT!</p>  
<p style={{ marginBottom: "20px" }}>* Be among the first to get your Phygital NFTs.</p>  
<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see our Roadmap for dates related to the Priv-sale</p>  
<p style={{ marginBottom: "20px" }}>Make sure you have read the sweepstakes rules.</p>  

</CTNButton4>

<StyledImg  style={{transform: 'scale(1.5)', position: "absolute",top: 41 ,left: -15, width: "200px",height: "51px",}}
  src= "/config/images/aboutw3.png"   alt="image" draggable="false"/> </>)}

{currentQuestion5 ===  '¿AIRDROP?' && (
  <>
<CTNButton4
style={{ position: "absolute", top: 162, left: -30, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 315, overflowY: 'auto', width: "960px", height: "740px"  }}>
  

<p style={{ marginBottom: "20px" }}>Get free NFTs, and yes, you can also earn ETH!</p>

<p style={{ marginBottom: "10px" }}>Will i give NFTs as a gift?</p>
<p style={{ marginBottom: "10px" }}>Particiate in our 3 exclusive sweepstakes and win amazing NFT at no cost. 80 NFT per drawing, so you have 3 chances to win!</p>
<p style={{ marginBottom: "20px" }}>Winners of our Airdrops will also enjoy the special benefits we have prepared for our cardholders.</p>
<p style={{ marginBottom: "10px" }}>Give Ethereum as a gift?</p>
<p style={{ marginBottom: "10px" }}>In our roadmap we have included an Airdrop of 10% of Ethereum revenues on the minting of the project. The 10% will be randomly distributed among the 25 winning addresses.</p>
<p style={{ marginBottom: "10px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected.</p>
<p style={{ marginBottom: "20px" }}>My help will be available without conditions or expectations. I will not accept recognition, participation in your projects or any other form of retribution.</p>
<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see our Roadmap for dates related to the Airdrops</p>  
<p style={{ marginBottom: "20px" }}>Make sure you have read the sweepstakes rules.</p>  

</CTNButton4>

  <StyledImg  style={{ transform: 'scale(1.5)', position: "absolute",top: 41 ,left: 718,width: "200px",height: "51px",}}
  src= "/config/images/aboutw3.png"   alt="image" draggable="false"/> </>)}


</CTNButton2>

 
<CTNButton2 
style={{ position: "absolute", top: 56, left: -2, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion5('¿PRIVSALE?');}}
onTouchStart={() => handleTouchStart2()} onTouchEnd={() => handleTouchEnd2()}>
<StyledImg
src={IsHovered2 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "200px", height: "51px "  }} />

<CTNButton2
 translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: -13, width: "200px", height: "52px"}}>
PRIV-SALE </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: 365, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion5('¿PRESALE?');}}
onTouchStart={() => handleTouchStart4()} onTouchEnd={() => handleTouchEnd4()}>
<StyledImg
src={IsHovered4 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "200px", height: "51px "  }} />
<CTNButton2
 translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: -13, width: "200px", height: "52px"}}>
PRE-SALE </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: 675, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion5('¿AIRDROP?');}}
onTouchStart={() => handleTouchStart3()} onTouchEnd={() => handleTouchEnd3()}>
<StyledImg
src={IsHovered3 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: 43, width: "200px", height: "51px " }} />
<CTNButton2  translate="no" style={{  transform: 'scale(1.5)',  position: "absolute",  top: -15, left: 43, width: "200px", height: "52px"}}>
AIRDROPS</CTNButton2> </CTNButton2>

<CTNButton
style={{position: "absolute", top: 530, left: 180,}}
onTouchStart={() => handleTouchStart12()} onTouchEnd={() => handleTouchEnd12()}
onClick={(e) => { setIsHovered12(false); setTimeout(() =>setActiveButton(6), setcurrentQuestion00('¿GIVEAWAY?')); audio2.play();}}>

<StyledImg src={IsHovered12 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} 
alt="image" draggable="false" />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
VIEW ALL RULES
</CTNButton>
</CTNButton>

<StyledImg
    style={{  transform: 'scale(1.5)',   position: "absolute", top: 680, left: 252, width: "390px", height: "40px" }}
    src={"/config/images/acept.png"}
    alt="image"
    draggable="false"/>
      
  <CTNButton2
  style={{  transform: 'scale(1.5)',  position: "absolute", top: 689, left: 307, fontSize: 14}}
  >
    <input
    style={{position: "absolute", top: -4, left: -42,}}
      type="checkbox"
      className="checkbox"
      checked={isChecked}
      onChange={(e) => setIsChecked(e.target.checked)}
    />
  I ACCEPT THE TERMS AND CONDITIONS.
  </CTNButton2>


  {blockchain.errorMsg === ""  ?  (
<>
{
<s.Container ai={"center"} jc={"center"}>




<CTNButton
style={{position: "absolute", top: 760, left: 180,}}
onClick={(e) => {
e.preventDefault();
if (!isChecked) {
  setErrorMessage2('BEFORE CONTINUING, TAKE A LOOK AT THE TERMS AND CONDITIONS: WE KNOW THEY ARE A BIT BORING, BUT THEY ARE IMPORTANT.');
  audio3.play();
  return;}
dispatch(connect());
getData();
audio2.play();
} }
onTouchStart={() => handleTouchStart5()}
onTouchEnd={() => handleTouchEnd5()}
>
<StyledImg
src={IsHovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
</CTNButton>




</s.Container>

}

</>
) :     ( 
<>

{  
  <>
<StyledImg style={{ position: "absolute", top: 15, left: -89, width: "1079px", height: "864px"  }} src={"/config/images/More/participa2.png"} alt="image" draggable="false"/>


<s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{blockchain.errorMsg}</p>  
</CTNButton4>



</s.Container>
</>
}

<StyledImg
    style={{  transform: 'scale(1.5)',   position: "absolute", top: 680, left: 252, width: "390px", height: "40px" }}
    src={"/config/images/acept.png"}
    alt="image"
    draggable="false"/>
      
  <CTNButton2
  style={{  transform: 'scale(1.5)',  position: "absolute", top: 689, left: 307, fontSize: 14}}
  >
    <input
    style={{position: "absolute", top: -4, left: -42,}}
      type="checkbox"
      className="checkbox"
      checked={isChecked}
      onChange={(e) => setIsChecked(e.target.checked)}
    />
  I ACCEPT THE TERMS AND CONDITIONS.
  </CTNButton2>


<CTNButton
style={{position: "absolute", top: 760, left: 180,}}
onClick={(e) => {
e.preventDefault();
if (!isChecked) {
  setErrorMessage2('YOU MUST ACCEPT THE TERMS AND CONDITIONS');
  audio3.play();
  return;}
dispatch(connect());
getData();
audio2.play();
} }
onTouchStart={() => handleTouchStart5()}
onTouchEnd={() => handleTouchEnd5()}
>
<StyledImg
src={IsHovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
</CTNButton>

</>
)}

{errorMessage2 && (
    <> 
    <StyledImg style={{ position: "absolute", top: 15, left: -89, width: "1079px", height: "864px"  }} src={"/config/images/More/participa2.png"} alt="image" draggable="false"/>

   <s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{errorMessage2}</p>  
</CTNButton4>



</s.Container>

   </> 
) }

  </>

) : (
    <>
  <s.Container style={{ position: "absolute", top: 0, left: 0,}} > 
  
  <StyledImg
src={"/config/images/More/participa.png"}
style={{  position: "absolute", top: -15, left: -78, width: "1070px", height: "435px" }}
   />

  <StyledImg
src={"/config/images/More/participa3.png"}
alt="image"   draggable="false"
style={{ position: "absolute", top: 440, left: -33, width: "980px", height: "200px" }}
     />
  
  <StyledImg
src={"/config/images/More/AirWhi.png"}
style={{  transform: 'scale(1.5)',  position: "absolute", top: 780, left: 65, width: "780px", height: "70px" }}
alt="image"   draggable="false"

     />


<CTNButton
style={{position: "absolute", top: -23, left: 160, fontSize: 24, width: "585px", height: "98px"  }}
>
GENERAL RULES
</CTNButton>

<CTNButton4
style={{ position: "absolute", top: 55, left: -70, fontSize: 24, padding: 10, border: '0px solid #ddd', borderRadius: 5, maxHeight: 352, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready for an epic adventure, get free NFTs, secure a place in the Pre-sale and Public-sale and you can win ETH too!</p>  
<p style={{ marginBottom: "20px" }}>The system will check if you comply with the rules on the day of each draw. Users who do not comply with the rules will be disqualified.</p>

<p style={{ marginBottom: "10px" }}>How to participate for a place on our Pre-sale and Priv-sale whitelist? </p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>3- Fill in the following form.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have an ETH address with a balance.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Pre-sale or Priv-sale” option. </p>  
<p style={{ marginBottom: "20px" }}>You will not be charged for participating in the contest, but you must have a balance if you want to buy during the minting.</p>  
<p style={{ marginBottom: "20px" }}>The Priv-sale it is necessary to register before {closeregister}, the winners of a place will be announced on {inforegister}. The winners will be able to participate in the Priv-sale from {privsale}.</p>  
<p style={{ marginBottom: "20px" }}>Pre-sale requires registration by {closeregister}, winners of a seat will be announced on {inforegister}. The winners will be able to participate in the Pre-sale from {presale}. </p>  
<p style={{ marginBottom: "20px" }}>Participating in the Priv-sale not only gives you early access, but also secures you a place in the Pre-sale, allowing you to continue your miting in the Pre-sale phase. </p>  
<p style={{ marginBottom: "20px" }}>You will have 3 days to participate in the Pre-sale and Priv-sale once it goes live.</p>

<p style={{ marginBottom: "10px" }}>How to participate in our NFT Airdrop?</p>
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>3- Fill in the following form.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Participate in our 3 exclusive sweepstakes and win amazing NFT at no cost. 80 NFT per drawing, so you have 3 chances to win!</p>  
<p style={{ marginBottom: "20px" }}>Winners will receive their NFTs within 24 hours after each drawing, check our Roadmap for exact days.</p>  

<p style={{ marginBottom: "10px" }}>How to participate in our ETH Airdrop?</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>3- Fill in the following form.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “ETH Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Register from {openairdropeth} to {closeairdropeth} to participate for a reward in Ethereum to fund new projects. The draw will take place on {airdropeth}, and if you are a winner you will receive it within 24 hours.</p>  
<p style={{ marginBottom: "20px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected. </p>  
<p style={{ marginBottom: "20px" }}>The amount of Ethereum to be distributed 10% of the minting profits. 10% will be distributed to 25 winning addresses.</p>  

</CTNButton4>

  
  
  <StyledImg
    style={{ transform: 'scale(1.5)', position: "absolute", top: 680, left: 240, width: "390px", height: "40px" }}
    src={"/config/images/acept.png"}
    alt="image"
    draggable="false"/>
      
  <CTNButton2
  style={{transform: 'scale(1.5)', position: "absolute", top: 690, left: 294, fontSize: 14}}
  >
    <input
    style={{position: "absolute", top: -4, left: -42,}}
      type="checkbox"
      className="checkbox"
      checked={isChecked2}
      onChange={(e) => setIsChecked2(e.target.checked)}
    />
  I ACCEPT THE TERMS AND CONDITIONS.
  </CTNButton2>




  <CTNButton2
      style={{ transform: 'scale(1.3)', position: "absolute", top: 570, left: 133, fontSize: 20, }}
      >
    <input
   type="text"  id="text-input" 
   value={text} onChange={(event) => setText(event.target.value)}
   autoComplete="off" 
   placeholder="    0x.01230yOuR03210aDdReSs01230wAlLeT03210"
   maxLength="42"
   size={41}
  />
    </CTNButton2>
  
      <CTNButton2
        style={{  transform: 'scale(1.3)', position: "absolute", top: 470, left: 60, fontSize: 20, }}
        >
    <input
      type="text"
      id="name-input"
      value={UserTwitter}
      onChange={(event) => setUserTwitter(event.target.value)}
      autoComplete="off" 
      placeholder="USER TWITTER"
      maxLength="15"
      size={17}
    />
      </CTNButton2>
  
      <CTNButton2
        style={{ transform: 'scale(1.3)', position: "absolute", top: 470,  left: 620, fontSize: 20, }}
        >
    <input
      type="text"
      id="last-name-input"
      value={UserDisc}
      onChange={(event) => setUserDisc(event.target.value)}
      placeholder="USER DISCORD"
      autoComplete="off" 
      maxLength="32"
      size={14}
    />
      </CTNButton2>

  <CTNButton2 style={{ transform: 'scale(1.5)',  position: "absolute", top: 751, left: -185, fontSize: 16,}}
onClick={(e) => {handleSend();} } onTouchStart={() => handleTouchStart15  ()}onTouchEnd={() => handleTouchEnd15()}>
<StyledImg src={IsHovered15 ? "/config/images/ActionBlack.png" : "/config/images/action0.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 18, left: 80, width: "309px", height: "53px" }} />
<CTNButton
style={{position: "absolute", top: 18, left: 80, width: "309px", height: "53px" , fontSize: 28}}> <p translate="no">PRE-SALE</p> </CTNButton>
  
  
</CTNButton2> <CTNButton2 style={{ transform: 'scale(1.5)',  position: "absolute", top: 751, left: 392, fontSize: 16}}
onClick={(e) => {handleSend2(); }} onTouchStart={() => handleTouchStart16()} onTouchEnd={() => handleTouchEnd16()} >
<StyledImg src={IsHovered16 ? "/config/images/ActionBlack.png" : "/config/images/action0.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 18, left: 79, width: "309px", height: "53px"  }} />
<CTNButton
style={{position: "absolute", top: 18, left: 80, width: "309px", height: "53px" ,fontSize: 28}}><p translate="no">PRIV-SALE</p></CTNButton></CTNButton2>



  {errorMessage && (
    <>

<StyledImg style={{ position: "absolute", top: -55, left: -75, width: "1060px", height: "950px"  }} src={"/config/images/More/participa02.png"} alt="image" draggable="false"/>

<s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{errorMessage}</p>  
</CTNButton4>



</s.Container>

   </>
) }


{errorMessage1 && (
      <>
<StyledImg style={{ position: "absolute", top: -55, left: -75, width: "1060px", height: "950px"  }} src={"/config/images/More/participa02.png"} alt="image" draggable="false"/>

<s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{errorMessage1}</p>  
</CTNButton4>



</s.Container>
   </>
) }

  </s.Container>
    </>
)}

  </s.Container>
</>
        )}


</ResponsiveWrapper>

</>              
</>}
</div>
)}

{activeButton === 5 && (// 5 boton Utilidad
 <div id="content5">


{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",  top: 450}} test
>
  

  <s.Container flex={1} jc={"center"} ai={"center" }>



  <StyledImg
      src={"/config/images/More/Testw5.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 699, width: "199px",height: "192px" }} />
    

  </s.Container>

  <s.Container style={{ position: "absolute", top: 90, left: 52, }} >


<StyledImg style={{ transform: 'scale(1.5)',  position: "absolute", top: 30, left: 88, width: "730px",height: "70px" }} 
src={"/config/images/sv1.png"} alt="image" draggable="false"/>





<CTNButton2
  style={{ position: "absolute", top: 0, left: 0, fontSize: 16, }}
>
  {currentQuestion2 ===  '¿Server?'  &&  (<>

    <s.Container style={{ transform: 'scale(1.4)',  position: "absolute", top: 40, left: -75, pointerEvents: 'none'  }} > 

<StyledImg src={"/config/images/logosv0.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 75, left: -15, width: "780px",height: "150px" }}/>
<StyledImg src={"/config/images/logosv.png"} 
alt="Conquest Of Paradise" draggable="false" style={{ position: "absolute", top: 92, left: -8, width: "190px",height: "125px"}}/>
  
    <CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 60, left: 190 }} >
 <p  translate="no" style={{position: "absolute",fontSize: 14, margin: "0 -500px -122px -270px" }}>CONQUEST OF PARADISE</p>  
 <p translate="no" style={{position: "absolute",fontSize: 14, color: "#AAAAAA", margin: "0 -700px -110px 310px" }}>1 / 20000</p> 
 <p translate="no" className="animated-text-2 "  style={{position: "absolute", margin: "0 -500px -185px -108px" }}>CONQUEST OF PARADISE</p> 
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -500px -185px -108px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-0"  style={{position: "absolute",fontSize: 24,  margin: "0 -500px -178px 65px" }}> »»&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;||&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;««</p> 
 <p translate="no" className="animated-text-3" style={{position: "absolute", margin: "0 -510px -185px 380px" }}>1.8 - 1.21.X </p>
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -500px -185px 390px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-4" style={{position: "absolute", margin: "0 -500px -265px -265px" }}>SERVER IN CREATION</p>
 <p translate="no" className="animated-text"  style={{position: "absolute", margin: "0 -600px -265px 280px" }}>MULTIPLE MODALITIES</p>
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -500px -265px -265px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -600px -265px 280px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-0"  style={{position: "absolute",fontSize: 24,  margin: "0 -600px -260px -47px" }}> »||«</p> 
</CTNButton2>
</s.Container>


<StyledImg style={{ transform: 'scale(1.5)', position: "absolute", top: 41, left: -14, width: "256px", height: "51px "}}
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>

<s.Container style={{ position: "absolute", top: 190, left: 0, }} > 

<StyledImg src={"/config/images/More/roadwxd.png"} alt="image" draggable="false"
style={{ position: "absolute", top: 310, left: -85, width: "1068px",height: "425px" }}  />

<StyledImg src={"/config/images/sv2.png"} 
alt="image" draggable="false" style={{ transform: 'scale(1.3)', position: "absolute", top: 207, left: 33, width: "830px",height: "60px" }} />



{currentQuestion3 ===  '¿Server?'  &&  (<>
  <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready to explore a digital universe full of adventures! our Minecraft server is under construction and will open its doors 4 months after the collection is sold out or the minting process ends on {endpublicsale} day.</p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setcurrentQuestion3('¿BENEFITS?')); audio2.play();}}>As a holder of our NFTs you will have impressive benefits, which are detailed in the following category “Benefits” you can click here to see them now.</p> 
<p style={{ marginBottom: "20px" }}>Within the server you will enjoy a wide range of regular game modes and the implementation of new game modes never seen before.</p> 
<p style={{ marginBottom: "20px" }}>You will also find safe and private spaces where you can chat with all your friends.</p> 
<p style={{ marginBottom: "20px" }}>Our server will be 100% open to all the possibilities that users can think of, so we promise to listen to the community to achieve a pleasant environment, where users become a fundamental part of the development and implementation of the server.</p> 

</CTNButton4>
<StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216, left: -50, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
  </>)}

{currentQuestion3 ===  '¿BENEFITS?'  &&  (<>
  
    <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A world of advantages beyond exclusivity. As a holder of our NFT you will get the following benefits and advantages.</p> 
<p style={{ marginBottom: "10px" }}>Access to a vip rank server and advantages:</p> 
<p style={{ marginBottom: "10px" }}>1- Vip access to all areas of the server: enjoy exclusive areas and special privileges reserved only for veteran members.</p> 
<p style={{ marginBottom: "10px" }}>2- Game mode kits: equip yourself with everything you need to master every challenge and become the king of the server.</p> 
<p style={{ marginBottom: "10px" }}>3- “NFT_holder_your_nick” sticker: proudly display your NFT holder status and stand out from the crowd.</p> 
<p style={{ marginBottom: "10px" }}>4- Exclusive game modes and chat rooms: enjoy unique content and engage in conversations with other NFT and Minecraft enthusiasts.</p> 
<p style={{ marginBottom: "10px" }}>5- Command ./Fly: raise your gameplay level and explore the world from a whole new perspective.</p> 
<p style={{ marginBottom: "20px" }}>6- future Pay To Earn server: you will be able to play and earn rewards in ETH based on your progress and achievements.</p> 
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿BENEFITS?')); audio2.play();}}>Click to see all Benefits</p>



</CTNButton4>

<StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216  , left: 223, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion3 ===  '¿PLAY TO EARN?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  

 
<p style={{ marginBottom: "20px" }}>Immerse yourself in a world of opportunities with our play-to-earn system! Experience a global, self-sustaining economy within the server.</p>
<p style={{ marginBottom: "10px" }}>Explore the various ways to generate income:</p>  
<p style={{ marginBottom: "10px" }}>1- Worker: offer your skills to the rest of the community by completing tasks and quests in exchange for remuneration.</p>  
<p style={{ marginBottom: "10px" }}>2- Entrepreneurship: hire workers, manage resources and establish your own in-game economic empire.</p>  
<p style={{ marginBottom: "10px" }}>3- Trade: trade items with other players to obtain valuable goods or accumulate wealth.</p>  
<p style={{ marginBottom: "10px" }}>4- Mining: extract scarce resources from the server and sell them to the highest bidder for huge profits.</p>  
<p style={{ marginBottom: "20px" }}>5- Builder: unleash your creativity by building impressive decorative or functional structures, then sell them to other players for profit.</p>  
<p style={{ marginBottom: "20px" }}>And that's just the beginning! We are constantly developing new and exciting ways for players to generate revenue within the server.</p>  
<p style={{ marginBottom: "20px" }}>This feature is still in the planning stages, but we are working hard to implement it as soon as possible after the server launch. Get ready to dive into a world of possibilities where your effort and creativity are rewarded.</p>  


</CTNButton4>
  <StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216, left: 496, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion3 ===  '¿COMING SOON?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Your opinion is important to us! In this server, the voice of the community is fundamental. We are committed to listen carefully to the suggestions and comments of our users to make continuous improvements, add new modes and benefits that enrich the gaming experience.</p>
<p style={{ marginBottom: "20px" }}>A server in evolution with constant updates and development and innovation on the fly.</p>
<p style={{ marginBottom: "20px" }}>We hope to have a committed community that actively participates in the development and improvement of the server. More than just a game our goal is to go beyond simple entertainment, creating an environment where the community can thrive and achieve their goals.</p>
<p style={{ marginBottom: "20px" }}>Imagine the possibility of your creations not only being part of the game, but also becoming valuable assets within the metaverse. You will be able to build your own virtual land, share it with other players and monetize it in various ways.</p>
<p style={{ marginBottom: "20px" }}>It is a promising future.</p>


</CTNButton4>

 <StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216, left: 770, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
  </>)}

  </s.Container>

  <s.Container style={{ transform: 'scale(1.3)', position: "absolute", top: 80, left: -130, }} >




  <CTNButton2 
style={{position: "absolute", top: 260, left: 54, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿Server?');}}
onTouchStart={() => handleTouchStart14()} onTouchEnd={() => handleTouchEnd14()}>
<StyledImg
src={IsHovered14 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "177px", height: "44px"  }} />
<CTNButton2 style={{ position: "absolute",  top: -5, left: 28}}>
SERVER? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 260, left: 264, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿BENEFITS?');}}
onTouchStart={() => handleTouchStart15()} onTouchEnd={() => handleTouchEnd15()}>
<StyledImg
src={IsHovered15 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "177px", height: "44px"  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: 28  }}>
BENEFITS? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 260, left: 472, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿PLAY TO EARN?');}}
onTouchStart={() => handleTouchStart16()} onTouchEnd={() => handleTouchEnd16()}>
<StyledImg
src={IsHovered16 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -11, width: "177px", height: "44px "  }} />
<CTNButton2 translate="no" style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
PLAY TO EARN? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 260, left: 684, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿COMING SOON?');}}
onTouchStart={() => handleTouchStart17()} onTouchEnd={() => handleTouchEnd17()}>
<StyledImg
src={IsHovered17 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -12, width: "177px", height: "44px "  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
COMING SOON? </CTNButton2> </CTNButton2>

  {" "}

    </s.Container>

  </>)}



  {currentQuestion2 ===  '¿PHYGITAL NFT?'  &&  (<>

    <s.Container style={{  transform: 'scale(1.5)', position: "absolute", top: 220, left: -0, }} >



<s.Container style={{ position: "absolute", top: -100, left: -65, }} >

<StyledImg style={{ position: "absolute", top: 182, left: -6, width: "192px", height: "342px" }}
src={"/config/images/TestSteveWeb1.png"} alt="image" draggable="false"/>
<StyledImg style={{ position: "absolute", top: 180, left: -10, width: "205px",height: "367px" }}
src={"/config/images/canva.png"} alt="image" draggable="false"/>
<CTNButton2
style={{ position: "absolute", top: 522, left: 40, fontSize: 12, width: "105px",height: "10px"  }}>
3D MODEL 
</CTNButton2>

</s.Container>

<s.Container style={{  position: "absolute", top: 5, left: -200 , }} >


<canvas ref={canvasRef2} alt="phygital Conquest Of Paradise" style={{ transform: 'scale(1.1)', position: "absolute", top: 95, left: 147, display: visibleCanvas === 'PHYGITAL NFT' ? 'block' : 'none' }} />
<canvas ref={canvasRef3} alt="Nfc Card Conquest Of Paradise" style={{ transform: 'scale(1.2)', position: "absolute", top:  95, left: 147,display: visibleCanvas === 'NFC CARD' ? 'block' : 'none' }} />

</s.Container></s.Container>

    


<StyledImg style={{ transform: 'scale(1.5)', position: "absolute", top: 41, left: 662, width: "256px", height: "51px "}}
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>


<StyledImg src={"/config/images/More/sv001.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: 290, left: 210, width: "800px",height: "630px"  }}  />

<StyledImg src={"/config/images/More/sv2.png"}
alt="image" draggable="false" style={{  transform: 'scale(1.3)', position: "absolute", top: 170, left: 35, width: "830px",height: "60px" }} />



{currentQuestion4 ===  '¿PHYGITAL NFT?'  &&  (<>
  <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
  <p style={{ marginBottom: "20px" }}>Joining two worlds, the virtual and the physical, is now possible. With our Phygital NFT and its accessories you will share the experience of exchanging parts with other NFT owners.</p>
  <p style={{ marginBottom: "20px" }}>With your Phygital NFT you can exchange parts and assemble it in the style you like, who doesn't like to assemble a toy?</p>
  <p style={{ marginBottom: "10px" }}>As a holder you will get:</p>
  <p style={{ marginBottom: "10px" }}>1- Receive a Phygital NFT: enjoy a physical representation of your digital avatar, a unique souvenir you can proudly display.</p>
  <p style={{ marginBottom: "10px" }}>2- Interchangeable items kit: equip your Phygital NFT with a complete armor of your favorite material by exchanging parts with other NFT holders.</p>
  <p style={{ marginBottom: "10px" }}>3- Random items: discover what surprises random items have in store for you, from swords and blocks to clothing for your NFT.</p>
  <p style={{ marginBottom: "10px" }}>4- Unique pieces in the future: get ready for the possibility of acquiring unique pieces for your Phygital NFT, increasing its value and exclusivity even more.</p>
  <p style={{ marginBottom: "10px" }}>5- Super exclusive pieces: look for limited production pieces, exclusive pieces that will make your Phygital NFT even more exclusive.</p>
  <p style={{ marginBottom: "20px" }}>6- Personal interaction: take the opportunity to create new friendships while exchanging your items.</p>
  <p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿BENEFITS?')); audio2.play();}}>Click to see all Benefits</p>

</CTNButton4>
<StyledImg
style={{ position: "absolute",  top: 174, left: -76, width: "230px", height: "56px "  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
  </>)}

{currentQuestion4 ===  '¿NFC CARD?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
 <p style={{ marginBottom: "20px" }}>The possibilities for connection and authentication are endless. Our nfc card will open the doors to the physical and digital world.</p>  
 <p style={{ marginBottom: "20px" }}>1- Future events: join exclusive events and forums for NFT holders, where you can share ideas, collaborate and create opportunities. </p>  
 <p style={{ marginBottom: "20px" }}>2- Exciting surprises: be prepared to receive surprise gifts and participate in exclusive, totally free activities at community-selected locations. </p>  
 <p style={{ marginBottom: "20px" }}>3- Guaranteed authenticity: use your Nfc card to verify the authenticity of your Phygital NFTs, ensuring the legitimate possession of your interchangeable pieces. </p>  
 <p style={{ marginBottom: "20px" }}>4- Peace of mind and confidence: enjoy the peace of mind of knowing that your Phygital NFTs are protected and authenticated, giving you total confidence in their value. To use the Nfc card your smartphone or device must be compatible with the technology. </p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿BENEFITS?')); audio2.play();}}>Click to see all Benefits</p>


</CTNButton4>
<StyledImg
style={{ position: "absolute",  top: 174, left: 198, width: "230px", height: "56px "  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion4 ===  '¿TO CLAIM?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Claim your Phygital NFT and Nfc Card securely and easily in the future.</p>  
<p style={{ marginBottom: "20px" }}>We expect to enable the claims platform 6 months after the collection sells out or the minting process ends on {endpublicsale} day. Stay informed through our official channels to know the exact opening date.</p>   
<p style={{ marginBottom: "20px" }}>You will be able to claim your valuable Phygital NFT and Nfc Card through our official channels specifically set up for this transaction. We are committed to ensuring maximum security throughout the entire process, from before delivery until after you receive your Phygital NFT.</p>   
<p style={{ marginBottom: "20px" }}>Carefully follow the instructions provided to ensure a safe and satisfactory experience in the future.</p>   

</CTNButton4>
    <StyledImg
style={{ position: "absolute",  top: 174, left: 471, width: "231px", height: "56px "  }}  
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion4 ===  '¿EXPECTATION?'  &&  (<> 
    <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Our vision with Phygital NFTs extends beyond digital property. Our goal is to forge a tight-knit community built on friendship and camaraderie. We want our NFTs holders to become more than just a collection, we want them to become allies, collaborators and friends.</p>  
<p style={{ marginBottom: "20px" }}>Phygital NFTs with interchangeable parts unlock a whole new level of creative expression. Unleash your inner child and create something truly unique. Remember the joy of putting together physical toys? We're bringing back that same thrill, but with a digital twist.</p>  
<p style={{ marginBottom: "20px" }}>The Nfc Card could open doors to unforgettable moments with those who understand and share your interests. Imagine pleasant conversations over a cup of coffee or your favorite beverage sponsored by us as a gesture of gratitude.</p>  
<p style={{ marginBottom: "20px" }}>I have thousands of ideas that we could implement, but for me it is also important to know yours? What does the community want us to do? You will have to give us your proposals.</p>  

</CTNButton4>
 <StyledImg
style={{ position: "absolute",  top: 174, left: 745, width: "230px", height: "56px "  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>

  {" "}
</>)}


  <s.Container style={{ transform: 'scale(1.3)', position: "absolute", top: 40, left: -129, }} >


  <CTNButton2 
style={{position: "absolute", top: 118, left: 54, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿PHYGITAL NFT?'); handlePhygitalNFTClick();}}
onTouchStart={() => handleTouchStart14()} onTouchEnd={() => handleTouchEnd14()}>
<StyledImg
src={IsHovered14 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "177px", height: "43px"  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
PHYGITAL NFT? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 118, left: 264, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿NFC CARD?'); handleNFCCardClick();}}
onTouchStart={() => handleTouchStart15()} onTouchEnd={() => handleTouchEnd15()}>
<StyledImg
src={IsHovered15 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "178px", height: "43px"  }} />
<CTNButton2 style={{ position: "absolute",  top: -5, left: -8, width: "170px"}}>
NFC CARD? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 118, left: 472, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿TO CLAIM?');}}
onTouchStart={() => handleTouchStart16()} onTouchEnd={() => handleTouchEnd16()}>
<StyledImg
src={IsHovered16 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -11, width: "178px", height: "43px "  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
TO CLAIM? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 118, left: 684, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿EXPECTATION?');}}
onTouchStart={() => handleTouchStart18()} onTouchEnd={() => handleTouchEnd18()}>
<StyledImg
src={IsHovered18 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -12, width: "177px", height: "43px "  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
EXPECTATION? </CTNButton2> </CTNButton2>




{" "}
</s.Container>
</>)}



  
</CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: -2, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion2('¿Server?');}}
onTouchStart={() => handleTouchStart2()} onTouchEnd={() => handleTouchEnd2()}>
<StyledImg
src={IsHovered2 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -12, width: "256px", height: "51px "  }} />
<CTNButton2  translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: 15, width: "200px", height: "52px"}}>
SERVER </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: 675, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion2('¿PHYGITAL NFT?');}}
onTouchStart={() => handleTouchStart3()} onTouchEnd={() => handleTouchEnd3()}>
<StyledImg
src={IsHovered3 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "256px", height: "51px " }} />
<CTNButton2 style={{  transform: 'scale(1.5)',  position: "absolute",  top: -15, left: 10, width: "200px", height: "52px"}}>
PHYGITAL NFT </CTNButton2> </CTNButton2>










</s.Container>

</ResponsiveWrapper>

</>              
</>}
</div>
)}

{activeButton === 6 && ( // 6 boton Historia
 <div id="content6">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",  top: 450}} test
>

  <s.Container flex={1} jc={"center"} ai={"center" }>



  <StyledImg
      src={"/config/images/More/Testw6.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 901, width: "199px",height: "192px" }} />

  </s.Container>

<s.Container style={{ position: "absolute", top: 85, left: 52,}} > 



<StyledImg
      src={"/config/images/More/aboutw2.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 50, left: -30 , width: "960px",height: "400px" }} />

<s.Container style={{ position: "absolute", top: 0, left: 0,}} >  

<StyledImg src={"/config/images/More/roadwxd.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: 480, left: -85, width: "1068px",height: "440px" }}  />
  

  {currentQuestion00 ===  '¿WHAT IS THIS?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A collection that lives in the Ethereum blockchain with more than 16,350 hours of dedication have been captured in this digital work, the result of painstaking work in Blender, Vs code, Photoshop and other tools. have given life to this digital masterpiece, composed of 9 layers that house a large number of unique layered designs.</p>  
<p style={{ marginBottom: "20px" }}>This collection goes beyond a simple NFT. It is an invitation to reconnect friendship. We propose a journey to a tangible paradise, where screens are turned off and laughter is turned on. Why fight for paradise when we can live together, sharing unforgettable moments with friends? Discover that true paradise resides in authentic connections, in the joy of sharing and in the beauty of life itself.</p>  
<p style={{ marginBottom: "20px" }}>Conquest of Paradise is an experience. An opportunity to explore the confines of digital creativity, pay homage to the Minecraft universe and be part of a community that celebrates art and innovation.</p>  
<p style={{ marginBottom: "20px" }}>Each of the 21,000 NFT that have been created are unrepeatable. discover a piece of digital art as unique as you are, with its own personality and rarity, ready to become a treasure in your collection.</p>  
<p style={{ marginBottom: "20px" }}>Beyond its visual beauty, we invite you to join a dynamic and passionate community of digital art enthusiasts and Minecraft lovers. Share your passion, form meaningful connections and participate in a constantly evolving project.</p>  

</CTNButton4>
  {" "}
  <StyledImg src="/config/images/aboutw3.png"
alt="image" draggable="false" style={{ position: "absolute", top: 59, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿CREATION?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>This collection was born from a dream and the intention to unite two worlds through a bridge. A bridge that unites the passionate builders of Minecraft with the enthusiastic collectors of NFT. A place where digital creativity merges with the nostalgia of a game that marked all its players.</p>  
<p style={{ marginBottom: "20px" }}>I aspire to create a dynamic and tight-knit community, where Minecraft lovers, NFT collectors and everyone else can connect, share experiences and forge tangible friendships in an increasingly digitized world, where we spend hours in front of screens, I dream of a balance between the virtual and the real.</p>  
<p style={{ marginBottom: "20px" }}>Conques of Paradise aims to be that meeting point, where virtual friendships are transformed into real connections, full of laughter, conversations and unforgettable moments.</p>  
<p style={{ marginBottom: "20px" }}>Conquest of Paradise is a dream come true. A dream that invites you to explore new horizons, unite communities and build a future where passion, creativity and friendship are the foundations of a more prosperous and connected world.</p> 
<p style={{ marginBottom: "20px" }}>It's not just a NFT collection, it's the materialization of my dream and passion.</p> 



</CTNButton4>
  {" "}
  <StyledImg src="/config/images/aboutw3.png"
alt="image" draggable="false" style={{ position: "absolute", top: 59, left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿BENEFITS?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  

<p style={{ marginBottom: "20px" }}>A world of advantages beyond exclusivity. As a holder of our NFT you will get the following benefits and advantages.</p> 

<p style={{ marginBottom: "10px" }}>1- Access to a vip rank server and advantages:</p> 
<p style={{ marginBottom: "10px" }}>* Vip access to all areas of the server: enjoy exclusive areas and special privileges reserved only for veteran members.</p> 
<p style={{ marginBottom: "10px" }}>* Game mode kits: equip yourself with everything you need to master every challenge and become the king of the server.</p> 
<p style={{ marginBottom: "10px" }}>* “NFT_holder_your_nick” sticker: proudly display your NFT holder status and stand out from the crowd.</p> 
<p style={{ marginBottom: "10px" }}>* Exclusive game modes and chat rooms: enjoy unique content and engage in conversations with other NFT and Minecraft enthusiasts.</p> 
<p style={{ marginBottom: "10px" }}>* Command ./Fly: raise your gameplay level and explore the world from a whole new perspective.</p> 
<p style={{ marginBottom: "10px" }}>* future Pay To Earn server: you will be able to play and earn rewards in ETH based on your progress and achievements.</p> 
<p style={{ marginBottom: "20px" }}>We expect to bring the server online 4 months after the collection runs out or the minting process ends on {endpublicsale} day.</p> 

<p style={{ marginBottom: "10px" }}>2- NFT Phygital and its accessories:</p> 
<p style={{ marginBottom: "10px" }}>* Receive a Phygital NFT: enjoy a physical representation of your digital avatar, a unique souvenir you can proudly display.</p> 
<p style={{ marginBottom: "10px" }}>* Interchangeable items kit: equip your Phygital NFT with a complete armor of your favorite material by exchanging parts with other NFT holders.</p> 
<p style={{ marginBottom: "10px" }}>* Random items: discover what surprises random items have in store for you, from swords and blocks to clothing for your NFT.</p> 
<p style={{ marginBottom: "10px" }}>* Unique pieces in the future: get ready for the possibility of acquiring unique pieces for your Phygital NFT, increasing its value and exclusivity even more.</p> 
<p style={{ marginBottom: "10px" }}>* Super exclusive pieces: look for limited production pieces, exclusive pieces that will make your Phygital NFT even more exclusive.</p> 
<p style={{ marginBottom: "10px" }}>* Personal interaction: take the opportunity to create new friendships while exchanging your items.</p> 
<p style={{ marginBottom: "20px" }}>We expect to enable the claims platform 6 months after the collection is sold out or the minting process ends on {endpublicsale} day.</p> 

<p style={{ marginBottom: "10px" }}>3- Nfc card for exclusive connection and authentication:</p> 
<p style={{ marginBottom: "10px" }}>* Future events: join exclusive events and forums for NFT holders, where you can share ideas, collaborate and create opportunities. </p> 
<p style={{ marginBottom: "10px" }}>* Exciting surprises: be prepared to receive surprise gifts and participate in exclusive, totally free activities at community-selected locations. </p> 
<p style={{ marginBottom: "10px" }}>* Guaranteed authenticity: use your Nfc card to verify the authenticity of your Phygital NFTs, ensuring the legitimate possession of your interchangeable pieces. </p> 
<p style={{ marginBottom: "10px" }}>* Peace of mind and confidence: enjoy the peace of mind of knowing that your Phygital NFTs are protected and authenticated, giving you total confidence in their value. To use the Nfc card your smartphone or device must be compatible with the technology.</p> 
<p style={{ marginBottom: "10px" }}>We expect to enable the claims platform 6 months after the collection runs out or the minting process ends on {endpublicsale} day.</p> 

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 59, left: 642, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿WHO AM I?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>I have always been fascinated by the possibilities offered by the digital world to create, innovate and express creativity using the potential of NFTs. I knew it was the perfect opportunity to combine my passions and give life to a unique project.</p> 
<p style={{ marginBottom: "20px" }}>Creating this collection has not been an easy road. Everything you will see in this project has been made by me, so I have had to learn to do many things I did not know, economic difficulties have not allowed me to have a team or hire experts in each subject, plus I have also had many technical difficulties related to hardware and software.</p> 
<p style={{ marginBottom: "20px" }}>The biggest challenge i have faced has been the loss of Gordito, my siamese cat, my adventure companion and my biggest fan. We would spend hours together, him curled up on my lap while i immersed myself in work. His departure left a void in my heart and in my life that i still feel. I miss his constant companionship and his comforting purr. Although the loss has been painful, his memory inspires me to keep going, carrying his spirit with me in this project.</p> 
<p style={{ marginBottom: "20px" }}>The tight budget was another challenge, bordering on non-existent, leaving no room for unforeseen events such as the irreparable loss of my i3 laptop with 8gb ram. Although modest in specifications, its sentimental value was incalculable and it succumbed to the demanding daily workload. This unexpected breakdown became a major technical obstacle, testing my endurance and creativity to find alternative solutions.</p> 
<p style={{ marginBottom: "20px" }}>I have worked on every detail of the collection, the creation of the 3d models, the development of the Minecraft server, the 3d printing of the phygital models and even the website of the project. It has been a lonely process, but it has also allowed me to have total control over the materialization of my dream.</p> 
<p style={{ marginBottom: "20px" }}>I hope my story inspires others to pursue their dreams, no matter the difficulties along the way. Passion, creativity and perseverance are powerful tools that allow us to overcome any obstacle and achieve our goals.</p> 
<p style={{ marginBottom: "20px" }}>I am very proud of what I have achieved with the collection. it is a project that represents my passion, my creativity and my effort. I have given 1,000% on this project, and it will not be the only one you will see, however I wish to remain anonymous under the pseudonym “herobrine” for the time being.</p> 

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 162, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿PLAYERS?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>More than a game and a NFT, an experience for everyone. Conquest of Paradise is not limited to Minecraft players. It's an NFT collection that opens its doors to a universe of possibilities for any enthusiast of digital collectibles and unique experiences. </p> 
<p style={{ marginBottom: "20px" }}>Are you a collector of toys or characters? This collection brings you phygital, a unique experience that combines the digital and physical worlds. Each NFT comes with a collectible Phygital figure that you can display, trade or even use in board games.</p> 
<p style={{ marginBottom: "20px" }}>Do you love community and social interaction? Conquest of Paradise invites you to join a dynamics, tight-knit community where you can share your passion for NFTs, meet people with similar interests and participate in exciting events. </p> 
<p style={{ marginBottom: "20px" }}>Interested in supporting this new artist and his innovative projects? by purchasing a NFT, you will be contributing to the growth of a creative project that seeks to push the boundaries of the NFT and Minecraft world. </p> 
<p style={{ marginBottom: "20px" }}>Conquest of Paradise it's a platform for anyone to explore their passion for digital collectibles, participate in a dynamics community and support innovative projects.</p> 

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 162,  left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿SUPPORT?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>

<p style={{ marginBottom: "20px" }}>A beacon of support in the vast universe of NFTs. As an NFT enthusiast and 3d designer, i know firsthand the complexity and confusion surrounding this world. Information is abundant, but often contradictory, and finding reliable help can be a challenge, especially for those of us with limited resources. </p>  
<p style={{ marginBottom: "20px" }}>I have experienced the frustration of looking for help in this world of NFTs where most are only looking to make a profit, without any real interest in helping the community. That's why it gives me great satisfaction to announce that Conquest of Paradise will be offering totally free advice and assistance to all enthusiasts getting started in the world of NFTs.</p>  
<p style={{ marginBottom: "20px" }}>More than advice, we provide you with real support. In our roadmap we have included an airdrop of 10% of Ethereum revenue during project minting randomly distributed to 25 winning addresses. This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to discuss your project and get priority to be selected.</p>  
<p style={{ marginBottom: "20px" }}>My help will be available without conditions or expectations. I will not accept recognition, participation in your projects or any other form of retribution. My only goal is to help others navigate this unknown terrain and achieve their dreams.</p>  
<p style={{ marginBottom: "20px" }}>However, it is important to clarify that Conques of Paradise will not sponsor or promote new projects. My goal is to provide technical support and guidance to those who need it. I believe that with a solid foundation of knowledge and the right tools, every person has the potential to achieve success in this field. </p>  
<p style={{ marginBottom: "20px" }}>If you are an enthusiast looking to take your first steps into the world of NFT, i invite you to join the Conquest of Paradise community. Here you will find a safe and free space.</p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿GIVEAWAY?')); audio2.play();}}>Click to learn more about Airdrop ETH</p>





</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 162,  left: 642, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿COMMUNITY?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A dynamic community. My dream with this collection goes beyond a simple NFT collection. I aspire to create a space where the passion for NFTs and Minecraft come together to build a dynamic and united community.</p>  
<p style={{ marginBottom: "20px" }}>The benefits of Conquest of Paradise are not limited to the Phygital NFT and digital NFT. I want to foster a community where its members can interact face-to-face, leaving screens behind and rediscovering the joy of role-playing games, board games and social interaction.</p>  
<p style={{ marginBottom: "20px" }}>Imagine a space where they can come together to chat, play, have fun and share experiences. A place where healthy group activities are encouraged, and of course, we should also have this space in the online community. </p>  
<p style={{ marginBottom: "20px" }}>My goal is to create a strong and passionate community, where each member feels valued and actively participates. A community that adds, that supports and grows with us. Conquest of Paradise it is an invitation to join a community where passion, creativity and human connection are the protagonists.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 265, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿GIVEAWAY?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready for an epic adventure, get free NFTs, secure a place in the Pre-sale and Public-sale and you can win ETH too!</p>  
<p style={{ marginBottom: "20px" }}>The system will check if you comply with the rules on the day of each draw. Users who do not comply with the rules will be disqualified.</p>

<p style={{ marginBottom: "10px" }}>How to participate for a place on our Pre-sale and Priv-sale whitelist? </p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>3- Fill in the form: ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have an ETH address with a balance.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Pre-sale or Priv-sale” option. </p>  
<p style={{ marginBottom: "20px" }}>You will not be charged for participating in the contest, but you must have a balance if you want to buy during the minting.</p>  
<p style={{ marginBottom: "20px" }}>The Priv-sale it is necessary to register before {closeregister}, the winners of a place will be announced on {inforegister}. The winners will be able to participate in the Priv-sale from {privsale}.</p>  
<p style={{ marginBottom: "20px" }}>Pre-sale requires registration by {closeregister}, winners of a seat will be announced on {inforegister}. The winners will be able to participate in the Pre-sale from {presale}. </p>  
<p style={{ marginBottom: "20px" }}>Participating in the Priv-sale not only gives you early access, but also secures you a place in the Pre-sale, allowing you to continue your miting in the Pre-sale phase. </p>  
<p style={{ marginBottom: "20px" }}>You will have 3 days to participate in the Pre-sale and Priv-sale once it goes live.</p>

<p style={{ marginBottom: "10px" }}>How to participate in our NFT Airdrop?</p>
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>3- Fill in the form: ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Participate in our 3 exclusive sweepstakes and win amazing NFT at no cost. 80 NFT per drawing, so you have 3 chances to win!</p>  
<p style={{ marginBottom: "20px" }}>Winners will receive their NFTs within 24 hours after each drawing, check our Roadmap for exact days.</p>  

<p style={{ marginBottom: "10px" }}>How to participate in our ETH Airdrop?</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>3- Fill in the form: ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “ETH Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Register from {openairdropeth} to {closeairdropeth} to participate for a reward in Ethereum to fund new projects. The draw will take place on {airdropeth}, and if you are a winner you will receive it within 24 hours.</p>  
<p style={{ marginBottom: "20px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected. </p>  
<p style={{ marginBottom: "20px" }}>The amount of Ethereum to be distributed 10% of the minting profits. 10% will be distributed to 25 winning addresses.</p>  
<p style={{ marginBottom: "20px" }}onClick={(e) => { window.open(etherlink, "_blank"); audio2.play(); }}>Click here to view the exploit profits on our Smart-contract {contract} using Etherescan.</p>  
<p style={{ marginBottom: "20px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>Click here to participate in one of the following options.</p>  

</CTNButton4>

{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 265, left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿RARITY?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A universe of possibilities in each NFT. Enter a world of infinite possibilities in this collection. Each NFT is a unique and exclusive piece. Explore a universe of combinations and discover the rarity that awaits you.</p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => { setActiveButton(3), setShowMsg(false), setShowMsg1(true), audio2.play(); reloadGifs();}}>If you want to try something new visit our Rarities section by clicking here, you will find an interactive game that will allow you to mix and match a small percentage of the features available for our more than 21,000 NFTs. visualize an approximation of what our NFTs will look like.</p>  

<p style={{ marginBottom: "10px" }}>A glimpse of the diversity of conquest of paradise: </p>  
<p style={{ marginBottom: "10px" }}>* Animations: 4.</p>  
<p style={{ marginBottom: "10px" }}>* Backgrounds: 288.</p>  
<p style={{ marginBottom: "10px" }}>* Characters: 8. </p>  
<p style={{ marginBottom: "10px" }}>* Head: 330.</p>  
<p style={{ marginBottom: "10px" }}>* Body: 339.</p>  
<p style={{ marginBottom: "10px" }}>* Legs: 302. </p>  
<p style={{ marginBottom: "10px" }}>* Feet: 304.</p>  
<p style={{ marginBottom: "10px" }}>* Right hand: 338.</p>  
<p style={{ marginBottom: "20px" }}>* Left hand: 171.</p>  

<p style={{ marginBottom: "20px" }}>Our diversity gives us the possibility of having more than 124 billion possible combinations! a number so large that it surpasses our imagination. Especially considering that we have only created just over 21,000 unique NFT. </p>  
<p style={{ marginBottom: "20px" }}>Get ready for a fascinating journey where creativity and uniqueness go hand in hand in every NFT. </p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 265, left: 642, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿STAFF-SALE?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>

<p style={{ marginBottom: "20px" }}>Conquest of paradise is a dream come true, a work that i treasure with my soul. I have therefore decided to preserve a part of this precious collection to ensure that its legacy will live on and continue to amaze the community. </p>  
<p style={{ marginBottom: "20px" }}>This special reserve of NFTs, guarded by me, "herobrine", will allow us to create unique experiences in the future. Imagine surprise airdrops, exclusive rewards for the most loyal holders and events that will take your breath away. </p>  
<p style={{ marginBottom: "20px" }}>This reserve is not only a personal treasure, but a promise that Conquest of Paradise will continue to evolve and offer new adventures to those who join this epic journey. </p>  
<p style={{ marginBottom: "20px" }}>Prepare for a future full of surprises and thrills!.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 368, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿ROADMAP?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready for an unforgettable adventure.</p>  
<p style={{ marginBottom: "10px" }}>Start:</p>  
<p style={{ marginBottom: "20px" }}>On {start} we begin an extraordinary journey to our paradise. Join us in this unique experience and discover all that we have in store for you. Don't miss our Priv-sale, Pre-sale and Public-sale!</p>  

<p style={{ marginBottom: "10px" }}>Sales:</p>  
<p style={{ marginBottom: "10px" }}>* Priv-sale: register before {closeregister} for a chance to win a spot in the Priv-sale. Winners will be announced on {inforegister}. The Priv-sale will run from {privsale} at a special price of {privsaleprice} per NFT. </p>  
<p style={{ marginBottom: "10px" }}>* Pre-sale: register before {closeregister} for a chance to win a spot in the Pre-sale. Winners will be announced on {inforegister}. The Pre-sale will run from {presale} at a special price of {presaleprice} per NFT. </p>  
<p style={{ marginBottom: "20px" }}>* Public-sale: The moment we've all been waiting for has arrived! the Public-sale will start on {publicsale} and end on {endpublicsale}. You will be able to purchase your NFTs at a price of {publicsaleprice} per NFT. </p>  

<p style={{ marginBottom: "10px" }}>Airdrops: </p>  
<p style={{ marginBottom: "10px" }}>* Airdrop 1: register from {openairdrop1} to day {closeairdrop1} to participate. The drawing will be held on {airdrop1} and winners will receive their free NFTs within 24 hours.</p>  
<p style={{ marginBottom: "10px" }}>* Airdrop 2: register from {openairdrop2} to day {closeairdrop2} to participate. The drawing will be held on {airdrop2} and winners will receive their free NFTs within 24 hours. </p>  
<p style={{ marginBottom: "20px" }}>* Airdrop 3: register from {openairdrop3} to day {closeairdrop3} to participate. The drawing will be held on {airdrop3} and winners will receive their free NFTs within 24 hours. </p>  

<p style={{ marginBottom: "10px" }}>Benefits:</p>  
<p style={{ marginBottom: "10px" }}>* Win a prize in Ethereum: register from {openairdropeth} to day {closeairdropeth} to participate. The draw will take place the day {airdropeth} and winners will receive the prize in Ethereum within 24 hours. </p>  
<p style={{ marginBottom: "10px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected. </p>  
<p style={{ marginBottom: "10px" }}>* Minecraft server: get ready to explore a digital universe full of adventures! our Minecraft server is under construction and will open its doors 4 months after the collection is sold out or the minting process ends on {endpublicsale} day.</p>  
<p style={{ marginBottom: "20px" }}>* Phygital NFT: your experience transcends the digital! we are manufacturing an exclusive Phygital that merges the physical and digital to take your adventure to another level. Distribution of this exclusive item is scheduled for 6 months after the collection sells out or the minting process ends on {endpublicsale} day.</p>  

<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see the graphical Roadmap.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 368, left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿CONTACT US?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "10px" }}>Connect with us and be part of the community, You will find a place to:</p>  
<p style={{ marginBottom: "10px" }}>* Share your passion for NFTs and Minecraft. </p>  
<p style={{ marginBottom: "10px" }}>* Connect with other enthusiasts and build lasting relationships. </p>  
<p style={{ marginBottom: "10px" }}>* Receive the latest news and updates about the project. </p>  
<p style={{ marginBottom: "10px" }}>* Participate in exclusive events and exciting contests. </p>  
<p style={{ marginBottom: "20px" }}>* Get access to unique community member-only benefits. </p>  

<p style={{ marginBottom: "10px" }}>Click on the social network you wish to visit:</p>
<p style={{ marginBottom: "10px" }} onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>Discord.</p>  
<p style={{ marginBottom: "10px" }} onClick={(e) => { window.open(tiktoklink, "_blank"); audio2.play(); }}>Tiktok.</p>  
<p style={{ marginBottom: "10px" }} onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>TWITTER.</p> 
<p style={{ marginBottom: "20px" }} onClick={(e) => { window.open(instagramlink, "_blank"); audio2.play(); }}>Instagram.</p>  

<p style={{ marginBottom: "20px" }}>If you have any questions or wish to contact us for any specific reason, you can do it through our Discord / support.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 368, left: 642, width: "279px", height: "70px "}} /></>)}



</s.Container>


<CTNButton2 /////////////////////////////////////////////////////////////////////////////////// preguntas
  style={{ position: "absolute", top: 75, left: -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿WHAT IS THIS?');
  }}
  onTouchStart={() => handleTouchStart()}
  onTouchEnd={() => handleTouchEnd()}
>
<StyledImg
src={IsHovered ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />


<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
WHAT IS THIS?
</CTNButton2>
</CTNButton2>

<CTNButton2

style={{ position: "absolute", top: 75, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿CREATION?');
  }}
  onTouchStart={() => handleTouchStart2()}
  onTouchEnd={() => handleTouchEnd2()}
>
<StyledImg
src={IsHovered2 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
CREATION?
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 75, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿BENEFITS?');
  }}
  onTouchStart={() => handleTouchStart3()}
  onTouchEnd={() => handleTouchEnd3()}
>
<StyledImg
src={IsHovered3 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
BENEFITS?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 178, left:  -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿WHO AM I?');
  }}
  onTouchStart={() => handleTouchStart4()}
  onTouchEnd={() => handleTouchEnd4()}
>
<StyledImg
src={IsHovered4 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
WHO AM I?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 178, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿PLAYERS?');
  }}
  onTouchStart={() => handleTouchStart5()}
  onTouchEnd={() => handleTouchEnd5()}
>
<StyledImg
src={IsHovered5 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />        
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
PLAYERS?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 178, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿SUPPORT?');
  }}
  onTouchStart={() => handleTouchStart12()}
  onTouchEnd={() => handleTouchEnd12()}
>
<StyledImg
src={IsHovered12 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />   
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
SUPPORT?      
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 281, left: -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿COMMUNITY?');
  }}
  onTouchStart={() => handleTouchStart14()}
  onTouchEnd={() => handleTouchEnd14()}
>
<StyledImg
src={IsHovered14 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
COMMUNITY?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 281, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿GIVEAWAY?');
  }}
  onTouchStart={() => handleTouchStart15()}
  onTouchEnd={() => handleTouchEnd15()}
>
<StyledImg
src={IsHovered15 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
GIVEAWAY?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 281, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿RARITY?');
  }}
  onTouchStart={() => handleTouchStart16()}
  onTouchEnd={() => handleTouchEnd16()}
>
<StyledImg
src={IsHovered16 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
RARITY?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 385, left: -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿STAFF-SALE?');
  }}
  onTouchStart={() => handleTouchStart19()}
  onTouchEnd={() => handleTouchEnd19()}
>
<StyledImg
src={IsHovered19 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
STAFF-SALE?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 385, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿ROADMAP?');
  }}
  onTouchStart={() => handleTouchStart18()}
  onTouchEnd={() => handleTouchEnd18()}
>
<StyledImg
src={IsHovered18 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -16, left: -12, width: "279px", height: "69px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
ROADMAP?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 385, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿CONTACT US?');
  }}
  onTouchStart={() => handleTouchStart17()}
  onTouchEnd={() => handleTouchEnd17()}
>
<StyledImg
src={IsHovered17 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -16, left: -12, width: "279px", height: "69px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
CONTACT US?
</CTNButton2>
</CTNButton2>



</s.Container>

</ResponsiveWrapper>
</>              
</>}
</div>
)}

</s.Container>

<s.Container style={{ position: "absolute", top: -12, left: 0 ,}}>

<StyledRoundButton // 1 boton Minting   
style={{ position: "absolute  ", top: 370, left: -95, width: "190px", height: "125px"  }}
id="button1" // boton minting
onClick={(e) => {
  setActiveButton(1);
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
>
</StyledRoundButton> 


<StyledRoundButton // 2 boton Roadmap 
style={{ position: "absolute  ", top: 370, left: 103, width: "190px", height: "125px"  }}
id="button2" // boton airdrop

onClick={(e) => {
   setActiveButton(2)
   setShowMsg(true)
   setShowMsg1(false) 
  audio2.play();
  } }
>
</StyledRoundButton>

<StyledRoundButton // 3 boton airdrop 
style={{ position: "absolute  ", top: 370, left: 303, width: "190px", height: "125px"  }}
id="button3" // boton airdrop
onClick={(e) => {
  setActiveButton(3)
  setShowMsg(false) 
  setShowMsg1(true) 
 audio2.play();
 reloadGifs();
 } }
>
</StyledRoundButton>              
              
<StyledRoundButton // 4 boton Caracteristicas
style={{ position: "absolute  ", top: 370, left: 503, width: "190px", height: "125px"  }}
id="button4" // boton Caracteristicas
onClick={(e) => {
  setActiveButton(4)
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
>

</StyledRoundButton> 


<StyledRoundButton // 5 boton Utilidad
style={{ position: "absolute  ", top: 370, left: 703, width: "190px", height: "125px"  }}
id="button5" // boton Caracteristicas
onClick={(e) => {
  setActiveButton(5)
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
>
</StyledRoundButton> 

<StyledRoundButton // 6 boton Historia
style={{ position: "absolute  ", top: 370, left: 903, width: "190px", height: "125px"  }}
id="button6" // boton Caracteristicas
onClick={(e) => {
  setActiveButton(6)
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
>
</StyledRoundButton>
  
</s.Container>

<s.Container style={{ position: "absolute", top: -650, left: 0 ,}}>

{isImageVisible &&  (  
  
<>

<StyledImg
style={{   transform: 'scale(2.3) ', position: "absolute", top: 1773, left: 411, width: "155px",height: "50px"}} 
src={IsHovered13 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg
      src={"/config/images/More/Err.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 1465, left: 23, width: "930px",height: "370px" }} />

<CTNButton2
className="animated-text-2" translate="no" style={{  position: "absolute", top: 1580, left: 47,  width: "887px",height: "140px", fontSize: 32,}}
      >
Don't miss your chance to secure your spot in the Pre-Sale! Fill out the Whitelist form now.
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 1779, left: 411,  width: "150px",height: "45px", }} 
onTouchStart={() => handleTouchStart13()}
onTouchEnd={() => handleTouchEnd13()}
onClick={(e) => {setIsHovered13(false); setTimeout(() => setActiveButton(4), setImageVisible(false) ); audio2.play();}}
 >
WHITELIST
</CTNButton2>






</>

)}


</s.Container>

<s.Container translate="no" style={{ position: "absolute", top: -240, left: 75}}>



<StyledImg
src={"/config/images/timeout.png"}
      alt="image"
      draggable="false"
style={{ position: "absolute", top: 2270, left: -50, width: "950px",height: "130px" }} />




<StyledImg
      src={"/config/images/Redes.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 2200, left: -130, width: "1100px",height: "130px" }} />

<CTNButton4 
style={{ position: "absolute", top: 2314, left: -30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 14}}>CONQUEST OF PARADISE IS AN INDEPENDENT PROJECT INSPIRED BY MINECRAFT.</p>  
</CTNButton4>

<CTNButton2
  style={{ position: "absolute", top: 2242, left: -90, fontSize: 23 }}
  onTouchStart={() => handleTouchStart28()}   onTouchEnd={() => handleTouchEnd28()}
  onClick={(e) => { window.open(discordlink, "_blank"); setIsHovered28(false); audio2.play(); }}>
<StyledImg
src={IsHovered28 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }}
alt="image" draggable="false" />
<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
DISCORD
</CTNButton2>
</CTNButton2>

      
<CTNButton2
  style={{ position: "absolute", top: 2242, left: 123, fontSize: 23 }}
onTouchStart={() => handleTouchStart24()}   onTouchEnd={() => handleTouchEnd24()}
  onClick={(e) => { window.open(opensealink, "_blank"); setIsHovered24(false); audio2.play(); }}>
<StyledImg
src={IsHovered24 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>


<CTNButton2
style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}
onClick={(e) => { window.open(opensealink, "_blank"); audio2.play(); }}>
OPENSEA
</CTNButton2>
</CTNButton2>

<CTNButton2
  style={{ position: "absolute", top: 2242, left: 335, fontSize: 23 }}
  onTouchStart={() => handleTouchStart25()}   onTouchEnd={() => handleTouchEnd25()}
  onClick={(e) => { window.open(twitterlink, "_blank"); setIsHovered25(false); audio2.play(); }}>
<StyledImg
src={IsHovered25 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>



<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
TWITTER
</CTNButton2>
</CTNButton2>


<CTNButton2
  style={{ position: "absolute", top: 2242, left: 546, fontSize: 23 }}
  onTouchStart={() => handleTouchStart26()}   onTouchEnd={() => handleTouchEnd26()}
  onClick={(e) => { window.open(tiktoklink, "_blank"); setIsHovered26(false); audio2.play(); }}>
<StyledImg
src={IsHovered26 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>



<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
TIKTOK
</CTNButton2>
</CTNButton2>

<CTNButton2
  style={{ position: "absolute", top: 2242, left: 756, fontSize: 22 }}
  onTouchStart={() => handleTouchStart27()}   onTouchEnd={() => handleTouchEnd27()}
  onClick={(e) => { window.open(instagramlink, "_blank"); setIsHovered27(false); audio2.play(); }}>
<StyledImg
src={IsHovered27 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>


<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
INSTAGRAM
</CTNButton2>
</CTNButton2>


</s.Container>

</ResponsiveWrapper> 





      
  </s.Container>






    </div>
);
}


else {
  return (
<div ref={imageContainerRef}>



<s.Container flex={1} ai={"center"}
style= {{ padding: 24, position: "absolute", top: 100, left: 0, width: "100%", height: "120%" }}>
    
<ResponsiveWrapper flex={1} style={{ padding: 220, position: "absolute"}} test > 

<s.Container style={{  position: "absolute", top: -460, left: 75, pointerEvents: 'none' }}>

<StyledImg src={"/config/images/time.png"}
style={{ position: "absolute", top: 1745, left: -50, width: "950px",height: "350px" }} 
alt="image" draggable="false"/>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1997, fontSize: 24}} >
 <p  translate="no" style={{position: "relative", left: 28, display: 'flex', justifyContent: 'center', width: "160px"}}>DAY</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "169px"}}>HOUR</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>MIN</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "168px"}}>SEC</p>   
</CTNButton2>


{timeRemaining <= 0 ? ( 
<>
<CTNButton4 
style={{ position: "absolute", top: 1785, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 28}}>{fortime2}</p>  
</CTNButton4>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1848, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>UP</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>DA</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>TI</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>NG</p>  
</CTNButton2>

</>
) : (
<>
<CTNButton4 
style={{ position: "absolute", top: 1780, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 32}}>{fortime1}</p>  
</CTNButton4>


<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1848, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>{days}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>{hours}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>{minutes}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>{seconds}</p>  
</CTNButton2>
</>
        )}




</s.Container>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",}} test
>

<s.Container flex={1} jc={"center"} ai={"center" }>



    <StyledImg
      src={"/config/images/More/TestBaseMovil.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -70, left: -100, width: "1200px", height: "1200px" }} />


  </s.Container>



</ResponsiveWrapper>


<s.Container style={{ position: "absolute", top: -212, left: 0 ,}}>


<StyledImg
  src={hovered6 ? "/config/images/Testw01.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: -100, width: "199px", height: "131px" }} />

<StyledImg
  src={hovered7 ? "/config/images/Testw02.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 100, width: "199px", height: "131px" }} />

<StyledImg
  src={hovered8 ? "/config/images/Testw03.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 300, width: "199px", height: "131px" }} />

<StyledImg
  src={hovered9 ? "/config/images/Testw04.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 500, width: "199px", height: "131px" }} />


<StyledImg
  src={hovered10 ? "/config/images/Testw05.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 700, width: "199px", height: "131px" }} />

  
<StyledImg
  src={hovered11 ? "/config/images/Testw06.png" : "/config/images/Testw0.png"}
  alt="image"
  draggable="false"
  style={{ position: "absolute", top: 365, left: 900, width: "199px", height: "131px" }} />

  


  
</s.Container>

<s.Container style={{ position: "absolute", top: -200, left: 0 ,}} >

{activeButton === 1 && ( // boton Minting
   <div id="content1">
{ 
<>
<> 

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute", top: 450 }} test
>

<s.Container style={{ position: "relative", top: 88, left: 52,}} >


<StyledImg
      src={"/config/images/TestSteveWeb1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 159, left: -90,  width: "387px",height: "610px" }} />

<canvas ref={canvasRef} style={{position: "absolute", top: 260, left: -25, transform: 'scale(1.5)'}} />

  
</s.Container>


  <s.Container flex={1} jc={"center"} ai={"center"}>

    <StyledImg
      src={"/config/images/More/Testw1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: -100, width: "199px",height: "192px" }} 
      />

    <StyledImg
      src={"/config/images/More/TestSteveWeb1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 93, left: -30, width: "420px",height: "960px" }} />


    <StyledImg
      src={"/config/images/TestSteveWeb2.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 272, left: 430, width: "620px",height: "280px",  }} />


    <s.TextTitle
      style={{
        position: "absolute", top: 177, left: 550}}>

      {blockchain.account && blockchain.smartContract !== null ? ( 

      Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
        <>

<CTNButton
style={{position: "absolute", top: 210, left: -100, fontSize: 36,  width: "576px", pointerEvents: 'none'}}
>
MINTING HAS ENDED BUT YOU CAN STILL GET YOURS AT OPENSEA

</CTNButton>

  <CTNButton
    style={{position: "absolute", top: 520, left: -77,}}
    onClick={(e) =>{ window.open(opensealink, "_blank"); audio2.play();}}
    onMouseEnter={() => handleMouseEnter2()}
    onMouseLeave={() => handleMouseLeave2()}
  >
<StyledImg
src={hovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
OPENSEA
</CTNButton>
  </CTNButton>

        </>
      ) : 
        <>
          <s.AmountContainer style={{ position: "absolute", top: -0, left: 0, }}>



          <StyledImg
            src={"/config/images/More/button0.png"}
            alt="image"
            draggable="false"
            style={{ position: "absolute", top: 375, left: -130, width: "630px", height: "260px" }} />
            

            <CTNButton
            style={{ position: "absolute", top: 140, left: -45, fontSize: 42, pointerEvents: 'none' }}
          >
          <p translate="no">{data.totalSupply}&nbsp;/&nbsp;{CONFIG.MAX_SUPPLY}</p>
            </CTNButton>
            
          <s.SpacerSmall />
          <CTNButton
            style={{position: "absolute", top: 212, left: -45, fontSize: 56, pointerEvents: 'none'}}
          >
            PRICE&nbsp;<p translate="no">{(CONFIG.DISPLAY_COST * tokens).toString().substring(0,4)}&nbsp;{CONFIG.NETWORK.SYMBOL}</p>

            </CTNButton>

<CTNButton
  style={{position: "absolute", top: 280, left: -45, fontSize: 32, pointerEvents: 'none' }}>
   {walletAddress}
  </CTNButton>



  {blockchain.account !== "" ? (
  <>
  </>
  ) : null }

           <CTNButton
              style={{ position: "absolute", top: 396, left: 151, width: "77px", height: "93px", fontSize: 42, pointerEvents: 'none'}}
            >
  {tokens}
              </CTNButton>

            <StyledRoundButton   //minimo
            style={{ position: "absolute", top: 395, left: -80}}
            onClick={(e) => {
              e.preventDefault();
              settokens(1);
              audio2.play();
            } }
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()} 
          >
            <StyledImg
              style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
              src={hovered ? "/config/images/left2.png" : "/config/images/left.png"}
              alt="image"
              draggable="false"/>

          </StyledRoundButton>

            <StyledRoundButton  // menos 1
            style={{ position: "absolute", top: 395, left: 33}}
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                decrementtokens();
                audio2.play();
              } }
              
              onMouseEnter={() => handleMouseEnter1()}
              onMouseLeave={() => handleMouseLeave1()} 
            >
              <StyledImg
              style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
              src={hovered1 ? "/config/images/down2.png" : "/config/images/down.png"}
              alt="image"
              draggable="false"/>
                
            </StyledRoundButton>

            <StyledRoundButton // mas 1
            style={{ position: "absolute", top: 395, left: 263}}
              disabled={claimingNft ? 1 : 0}

              onClick={(e) => {
                e.preventDefault();
                incrementtokens();
                audio2.play(); }}
              onMouseEnter={() => handleMouseEnter2()}
              onMouseLeave={() => handleMouseLeave2()}
            >
              <StyledImg
              style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
                src={hovered2 ? "/config/images/up2.png" : "/config/images/up.png"}
                alt="image"
                draggable="false"/>
                
            </StyledRoundButton>

            <StyledRoundButton //maximo
            style={{ position: "absolute", top: 395, left: 376}}
            onClick={(e) => {
              e.preventDefault();
              settokens(CONFIG.MAX_PER_TX);
              audio2.play();
            } }
            onMouseEnter={() => handleMouseEnter3()}
            onMouseLeave={() => handleMouseLeave3()}
          >
            <StyledImg
            style={{position: "absolute", top: 0, left: 0, width: "83px", height: "95px"}}
              src={hovered3 ? "/config/images/right2.png" : "/config/images/right.png"}
              alt="image"
              draggable="false"/>

          </StyledRoundButton>

          <CTNButton
              style={{ position: "relative", top: 526, left: -124, fontSize: 50 }}
              disabled={claimingNft ? 1 : 0}
              onClick={() => {audio.play(); setHovered12(false);  setShowMsgRoad1(true)}}
              onMouseEnter={() => handleMouseEnter12()}
              onMouseLeave={() => handleMouseLeave12()}
            >
              <StyledImg
                src={hovered12 ? "/config/images/ActionBlack.png" : "/config/images/action0.png"}
                alt="image"
                draggable="false"
                style={{ position: "absolute", top: -3, left: 0, width: "595px", height: "100px"  }} />

           <CTNButton
            style={{position: "absolute", top: -2, left: -0, fontSize: 48,  width: "595px", height: "100px"  }}>
           {claimingNft ? <Loader speed="fast"  content="MINTING..." /> : "MINT"} 
            </CTNButton></CTNButton>


          </s.AmountContainer>

        </>
      ) : ( 
            <>

{blockchain.account === "" || blockchain.smartContract === null ? (
                <>

{blockchain.errorMsg === "" ? (
    <>
        {
  <s.Container ai={"center"} jc={"center"}>


  <CTNButton
    style={{position: "absolute", top: 520, left: -77,}}
    onClick={(e) => {
      e.preventDefault();
      dispatch(connect());
      getData();
      audio2.play();
    } }
    onMouseEnter={() => handleMouseEnter5()}
    onMouseLeave={() => handleMouseLeave5()}
  >
<StyledImg
src={hovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
  </CTNButton>
  


  <CTNButton
style={{position: "absolute", top: 210, left: -100, fontSize: 36,  width: "576px", pointerEvents: 'none'}}
>
WELCOME, ADVENTURER! ARE YOU READY TO MINT?
</CTNButton>

</s.Container>

        }


    </>
) : ( 
    <>

        {  


    <CTNButton
    style={{position: "absolute", top: 210, left: -100, fontSize: 36,  width: "576px",}}
    >
    {blockchain.errorMsg}
    </CTNButton>
    }

<CTNButton
    style={{position: "absolute", top: 520, left: -77,}}
    onClick={(e) => {
      e.preventDefault();
      dispatch(connect());
      getData();
      audio2.play();
    } }
    onMouseEnter={() => handleMouseEnter5()}
    onMouseLeave={() => handleMouseLeave5()}
  >
<StyledImg
src={hovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
  </CTNButton>

    </>
)}
           
</>
) : (
  
<>


                </>
              )}

            </>
          )}
    </s.TextTitle>



<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsgRoad1 &&(
<>

<StyledImg src={"/config/images/More/Difuse04.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 27, left: -80, width: "1075px",height: "914px" }}/>

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 125, width: "155px",height: "50px",  }} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 616, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 290, left: 210, width: "400px",height: "214px",  }} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 200, left: 340}} 
>
NOTIFICATION
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()}
onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false); setTimeout(() => setShowMsgRoad1(false) ); audio2.play();}}>
OKAY
</CTNButton2>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => {setHovered23(false), setShowMsgRoad1 (false), window.open(opensealink, "_blank"); audio2.play();}}>
OPENSEA</CTNButton2>





<CTNButton4
style={{  transform: 'scale(2.3)', position: "absolute", top: 360, left: 243, fontSize: 13, width: "337px", height: "140px"}}>
OOPS, THE MINTING HASN'T STARTED YET. BUT DON'T WORRY, THE PRIV-SALE {privsale}, THE PRE-SALE OPENS {presale},  AND THE PUBLIC-SALE {publicsale}. GET READY TO GET YOURS!.
</CTNButton4>


</s.Container>


</>
)}

</s.Container>

  </s.Container>


</ResponsiveWrapper></>              
</>}
</div>
)}

{activeButton === 2 && ( // 2 boton Roadmap 
  <div id="content2">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute", top: 450 }} test
>
  

  <s.Container flex={1} jc={"center"} ai={"center" }>


    <StyledImg
      src={"/config/images/More/Testw2.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 100, width: "199px",height: "192px" }} />
      


  </s.Container>

<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

  <StyledImg
      src={"/config/images/More/roadw1.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 100, left: -80, width: "1060px",height: "750px" }} />



<StyledImg //imagen difusa 
      src= "/config/images/More/roadw3new.png"
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />


<StyledImg //imagen difusa 
      src={hovered1 || hovered2 || hovered3 || hovered4 || hovered21 || hovered5 || hovered12
        || hovered14 || hovered15 || hovered16 || hovered17 || hovered18 || hovered19 || hovered20
         ? "/config/images/More/roadw difusa.png" : "/config/images/roadicow00.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />


</s.Container>


  <p style={{  transform: 'scale(1.35)', position: "absolute", top: 85, left: -5,}} > 

  <s.Container style={{ position: "absolute", top: 0, left: -87,}} > 

<s.Container style={{ position: "absolute", top: 109, left: 65,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 125, width: "180px",height: "50px" }} 
src={hovered1 ? "/config/images/msgRoad.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 125, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered1? <p>VIEW START</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///incio
style={{ position: "absolute", top: 70, left: 72}}
onMouseEnter={() => handleMouseEnter1()} onMouseLeave={() => handleMouseLeave1()}
onClick={() => {audio2.play();setcurrentQuestion6('¿START?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "108px",height: "121px" }} 
src={hovered1? "/config/images/roadicow1.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 



</StyledRoundButton> </s.Container>



<s.Container style={{ position: "absolute", top: 109, left: 242,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 115, width: "180px",height: "50px" }} 
src={hovered18 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 115, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered18? <p>VIEW PRIV-SALE</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///2
style={{ position: "absolute", top: 70, left: 70}}
onMouseEnter={() => handleMouseEnter18()} onMouseLeave={() => handleMouseLeave18()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PRIV-SALE?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "95px",height: "85px" }} 
src={hovered18? "/config/images/roadicow3.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 109, left: 398,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 115, width: "180px",height: "50px" }} 
src={hovered3 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 115, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered3? <p>VIEW PREV-SALE</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///3
style={{ position: "absolute", top: 70, left: 70}}
onMouseEnter={() => handleMouseEnter3()} onMouseLeave={() => handleMouseLeave3()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PRE-SALE?'); setShowMsgRoad(true)}}> 
<StyledImg style={{position: "absolute", width: "95px",height: "85px" }} 
src={hovered3? "/config/images/roadicow3.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton></s.Container>

<s.Container style={{ position: "absolute", top: 109, left: 549,}} > 

<StyledImg
style={{position: "absolute", top: 60, left: 115, width: "180px",height: "50px" }} 
src={hovered4 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 60, left: 115, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered4? <p>VIEW PUBLIC-SALE</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///4
style={{ position: "absolute", top: 70, left: 74}}
onMouseEnter={() => handleMouseEnter4()} onMouseLeave={() => handleMouseLeave4()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PUBLIC-SALE?'); setShowMsgRoad(true)}}> 
<StyledImg style={{position: "absolute", width: "95px",height: "85px" }} 
src={hovered4? "/config/images/roadicow3.png" : "/config/images/roadicow00.png"} 
alt="image" draggable="false"/> </StyledRoundButton></s.Container>

<s.Container style={{ position: "absolute", top: 280, left: 542,}} > 

<StyledImg
style={{position: "absolute", top: 49, left: -125, width: "180px",height: "50px" }} 
src={hovered21 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 49, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered21? <p>VIEW AIRDROP</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///5
style={{ position: "absolute", top: 60, left: 70}}
onMouseEnter={() => handleMouseEnter21()} onMouseLeave={() => handleMouseLeave21()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdrop1?'); setShowMsgRoad(true)}}> 
<StyledImg style={{position: "absolute", width: "88px",height: "80px" }} 
src={hovered21?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/></StyledRoundButton></s.Container>

<s.Container style={{ position: "absolute", top: 280, left: 382,}} > 

<StyledImg
style={{position: "absolute", top: 49, left: -125, width: "180px",height: "50px" }} 
src={hovered12 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 49, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered12 ? <p>VIEW AIRDROP</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///6
style={{ position: "absolute", top: 60, left: 70}}
onMouseEnter={() => handleMouseEnter12()} onMouseLeave={() => handleMouseLeave12()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdrop2?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "88px",height: "80px" }} 
src={hovered12?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 280, left: 226,}} > 

<StyledImg
style={{position: "absolute", top: 49, left: -125, width: "180px",height: "50px" }} 
src={hovered19? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 49, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered19 ? <p>VIEW AIRDROP</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///7
style={{ position: "absolute", top: 60, left: 67}}
onMouseEnter={() => handleMouseEnter19()} onMouseLeave={() => handleMouseLeave19()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdrop3?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "88px",height: "80px" }} 
src={hovered19?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 372, left: 202,}} > 

<StyledImg
style={{position: "absolute", top: 120, left: 112, width: "180px",height: "50px" }} 
src={hovered15 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: 112, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered15? <p>VIEW AIRDROP ETH</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///8
style={{ position: "absolute", top: 130, left: 66}}
onMouseEnter={() => handleMouseEnter15()} onMouseLeave={() => handleMouseLeave15()}
onClick={() => {audio2.play();setcurrentQuestion6('¿airdropeth?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "88px",height: "80px" }} 
src={hovered15?  "/config/images/roadicow2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>


<s.Container style={{ position: "absolute", top: 372, left: 370,}} > 

<StyledImg
style={{position: "absolute", top: 120, left: 112, width: "180px",height: "50px" }} 
src={hovered14 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: 112, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered14? <p>VIEW SERVER</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///9
style={{ position: "absolute", top: 130, left: 62}}
onMouseEnter={() => handleMouseEnter14()} onMouseLeave={() => handleMouseLeave14()}
onClick={() => {audio2.play();setcurrentQuestion6('¿SERVER?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "96px",height: "94px" }}
src={hovered14? "/config/images/roadicow4.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> </s.Container>

<s.Container style={{ position: "absolute", top: 372, left: 532,}} > 

<StyledImg
style={{position: "absolute", top: 120, left: 112, width: "180px",height: "50px" }} 
src={hovered16 ? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: 112, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered16? <p>VIEW PHYGITAL</p>: ""  }
</CTNButton4> 
</s.Container>


<s.Container style={{ position: "absolute", top: 372, left: 700,}} >

<StyledImg
style={{position: "absolute", top: 120, left: -130, width: "180px",height: "50px" }} 
src={hovered20? "/config/images/msgRoad2.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> 
<CTNButton4 translate="no"
style={{  position: "absolute", top: 120, left: -130, fontSize: 13, border: '0px solid #ddd', width: "180px", height: "50px"}}>
{hovered20 ? <p>VIEW END</p>: ""  }
</CTNButton4> 

<StyledRoundButton  ///end
style={{ position: "absolute", top: 130, left: 50}}
onMouseEnter={() => handleMouseEnter20()} onMouseLeave={() => handleMouseLeave20()}
onClick={() => {audio2.play();setcurrentQuestion6('¿END?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position:"absolute", width: "68px",height: "64px" }} 
src={hovered20? "/config/images/roadend.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> 

</s.Container>

<StyledRoundButton  ///10
style={{ position: "absolute", top: 503, left: 600}}
onMouseEnter={() => handleMouseEnter16()} onMouseLeave={() => handleMouseLeave16()}
onClick={() => {audio2.play();setcurrentQuestion6('¿PHYGITAL?'); setShowMsgRoad(true)}}> 
<StyledImg
style={{position: "absolute", width: "96px",height: "94px" }}
src={hovered16? "/config/images/roadicow4.png" : "/config/images/roadicow00.png"}
alt="image" draggable="false"/> </StyledRoundButton> 


</s.Container>


      </p>

      <s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsgRoad &&  (
<>

<StyledImg //imagen difusa 
src= "/config/images/More/roadw difusa.png"
alt="image"
draggable="false"
style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 210, width: "400px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>
</s.Container>

</>
)}

{currentQuestion6 ===  '¿START?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
  
<p style={{position: "relative", top: -30, display: 'flex',  justifyContent: 'center', fontSize: 28}}>START</p>  
<p style={{ position: "relative", top: 20, marginBottom: "5px" }}>GET READY FOR AN UNFORGETTABLE ADVENTURE! THE DAY {start} WE BEGIN AN EXTRAORDINARY JOURNEY TO OUR PARADISE.</p>  
</CTNButton4>
  {" "}


  <s.Container style={{position: "absolute", top: 0, left: 45}} >
<StyledImg
style={{   transform: 'scale(2.3) ', position: "absolute", top: 617, left: 340, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>
<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()}
onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setMessageDismissed(true), ); audio2.play();}}
 >
OKAY
</CTNButton2>
</s.Container>

  
</>)}

{currentQuestion6 ===  '¿PRIV-SALE?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PRIV-SALE</p>  
<p style={{  position: "relative", top: 30, marginBottom: "5px" }}>REGISTER BEFORE {closeregister}, WINNERS OF A PLACE WILL BE ANNOUNCED ON {inforegister}. WINNERS WILL BE ELIGIBLE TO PARTICIPATE IN THE PRIV-SALE FROM {privsale}. </p>  </CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿PRE-SALE?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PRE-SALE</p>  
<p style={{  position: "relative", top: 30, marginBottom: "5px" }}>REGISTER BEFORE {closeregister}, WINNERS OF A PLACE WILL BE ANNOUNCED ON {inforegister}. WINNERS WILL BE ELIGIBLE TO PARTICIPATE IN THE PRE-SALE FROM {presale}. </p>  
</CTNButton4>
  {" "}

<s.Container style={{position: "absolute", top: 0, left: 45}} >

  
<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}


{currentQuestion6 ===  '¿PUBLIC-SALE?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "780px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PUBLIC-SALE</p>  
<p style={{  position: "relative", top: 30, marginBottom: "5px" }}>TODAY {publicsale} IS THE MOMENT WE'VE ALL BEEN WAITING FOR! THE PUBLIC SALE HAS STARTED, THE {endpublicsale}, DAY ENDS, AND YOU WILL BE THE PROTAGONIST MINT AS MANY NFT AS YOU WANT.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdrop1?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM DAY {openairdrop1} TO DAY {closeairdrop1} TO PARTICIPATE FOR ONE OR MORE FREE NFT ON DAY {airdrop1} IF YOU WIN YOU WILL RECEIVE THEM IN THE NEXT 24 HOURS.</p> 
 </CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdrop2?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM DAY {openairdrop2} TO DAY {closeairdrop2} TO PARTICIPATE FOR ONE OR MORE FREE NFT ON DAY {airdrop2} IF YOU WIN YOU WILL RECEIVE THEM IN THE NEXT 24 HOURS.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdrop3?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM DAY {openairdrop3} TO DAY {closeairdrop3} TO PARTICIPATE FOR ONE OR MORE FREE NFT ON DAY {airdrop3} IF YOU WIN YOU WILL RECEIVE THEM IN THE NEXT 24 HOURS.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿airdropeth?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p translate="no" style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>AIRDROP ETH</p>  
<p style={{  position: "relative", top: 25, marginBottom: "5px" }}>REGISTER FROM {openairdropeth} TO {closeairdropeth}, TO PARTICIPATE FOR A PRIZE IN ETHEREUM. THE DRAW WILL TAKE PLACE AT {airdropeth}, AND IF YOU WIN YOU WILL RECEIVE IT IN THE NEXT 24 HOURS.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(4), 100); audio2.play();}}>
REGISTER</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿SERVER?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>SERVER MINECRAFT</p>  
<p style={{position: "relative", top: 25,  marginBottom: "5px" }}>SERVER UNDER CONSTRUCTION, IT IS ESTIMATED TO BE LAUNCHED IN 4 MONTHS AFTER THE COLLECTION SELLS OUT OR THE MINTING PROCESS ENDS ON {endpublicsale} DAY.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false),  setActiveButton(5), setcurrentQuestion2('¿Server?'), 100); audio2.play();}}>
ABOUT</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿PHYGITAL?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -10, display: 'flex',  justifyContent: 'center', fontSize: 28}}>PHYGITAL</p>  
<p style={{position: "relative", top: 25,  marginBottom: "5px" }}>OUR PHYGITAL IS BEING MANUFACTURED, ITS DISTRIBUTION IS ESTIMATED IN 6 MONTHS AFTER THE COLLECTION SELLS OUT OR THE MINTING PROCESS ENDS ON {endpublicsale} DAY.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 125, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()} onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false) ); audio2.play();}}>
OKAY</CTNButton2>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 617, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 625, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setActiveButton(5), setcurrentQuestion2('¿PHYGITAL NFT?'), 100); audio2.play();}}>
ABOUT</CTNButton2>

</s.Container>
</>)}

{currentQuestion6 ===  '¿END?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 292, left: 60, fontSize: 24, border: '0px solid #ddd', maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", top: -30, display: 'flex',  justifyContent: 'center', fontSize: 28}}>END?</p>  
<p style={{ position: "relative", top: 25, marginBottom: "5px" }}>IT'S NOT THE END, THIS IS JUST THE BEGINNING. CONQUISTA EL PARAÍSO HAS THOUSANDS OF IDEAS AND SURPRISES IN STORE, DON'T MISS IT.</p>  
</CTNButton4>
  {" "}
  <s.Container style={{position: "absolute", top: 0, left: 45}} >
<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 617, left: 340, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>
<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()}
onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setcurrentQuestion6(false), setShowMsgRoad(false), setMessageDismissed(true), ); audio2.play();}}
 >
OKAY
</CTNButton2>
</s.Container>
</>)}
</s.Container>





<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsg && !messageDismissed &&  (
<>

<StyledImg //imagen difusa 
src= "/config/images/More/roadw difusa.png"
alt="image"
draggable="false"
style={{ position: "absolute", top: 170, left: -50, width: "1000px",height: "613px" }} />

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 125, width: "155px",height: "50px",  }} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 210, width: "400px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 335, left: 340}} 
>
NOTIFICATION
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 450, left: 235, fontSize: 13, width: "356px",height: "70px",}} 
>
CHECK OUR ROADMAP AND OUR SOCIAL NETWORKS FOR UPDATES AND SURPRISES
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 620, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()}
onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false);  setTimeout(() => setShowMsg(false), setMessageDismissed(true), ); audio2.play();}}
 >
  
OKAY
</CTNButton2>


<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 616, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>

<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 620, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered2(false); setTimeout(() => setMessageDismissed(true), setShowMsg(false), setActiveButton(6), setcurrentQuestion00('¿ROADMAP?')); audio2.play();}}>
ABOUT</CTNButton2>

</s.Container>

</>
)}

</s.Container>


</ResponsiveWrapper>

</>              
</>}
</div>
)}

{activeButton === 3 && ( // 3 boton Caracteristicas
  <div id="content3">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",  top: 450  }} test
>


  <s.Container flex={1} jc={"center"} ai={"center"}>
      
  <StyledImg
      src={"/config/images/More/Testw3.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top:  -122,  left: 299, width: "200px",height: "192px" }} />

    

<s.Container style={{  position: "absolute", top: 370, left: 78,}} >


<StyledImg id="backgroundGif" src={`./gifs/background/${selectedOption8}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>


<StyledImg src={"/config/images/More/Caracteristicas02.png"}
alt="image" draggable="false" style={{ position: "absolute", top: -350, left: -140, width: "1120px",height: "900px" }}/>



{currentQuestion ===  '¿Exploring?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Exploring/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
{" "}

</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Exploring/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Exploring/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{" "}

</>)}
{currentQuestion ===  '¿Archer?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Archer/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Archer/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Bones/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Archer/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Archer/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
{" "}

</>)}

{" "}

</>)}
{currentQuestion ===  '¿Triton?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Triton/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
{" "}

</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Triton/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Triton/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Triton/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 135, width: "570px", height: "570px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{" "}

</>)}
{currentQuestion ===  '¿Attack?' && (<>

{currentQuestion1 ===  '¿General?' && (<>

<StyledImg
src={`./gifs/Attack/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Skeletons?' && (<>

<StyledImg
src={`./gifs/Attack/Bones/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Bones/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{currentQuestion1 ===  '¿Enderman?' && (<>

<StyledImg
src={`./gifs/Attack/Ender/Character/${selectedOption1}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Bones/Boots/${selectedOption5}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Ender/Pants/${selectedOption4}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Ender/body/${selectedOption3}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Head/${selectedOption2}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Attack/Hand1/${selectedOption6}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>
<StyledImg
src={`./gifs/Exploring/Hand2/${selectedOption7}.gif`}
alt="image" draggable="false" style={{ position: "absolute",top: -178, left: 25, width: "790px", height: "571px",}}/>

{" "}

</>)}

{" "}

</>)}





{loadingImageShown && (
  <StyledImg src="./gifs/1M.png" 
alt="image" draggable="false" style={{  position: "absolute", top: -350, left: -140, width: "1120px",height: "900px" }}/>)}



</s.Container>


  <s.Container style={{  position: "absolute", top: 190, left: 0, }} >


  <StyledRoundButton  //1
  style={{ position: "absolute", top: -10, left: 49}} 
  onMouseEnter={() => handleMouseEnter1()} onMouseLeave={() => handleMouseLeave1()}
  onClick={handleNextOption2}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px" }} 
src={hovered1? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //2
style={{ position: "absolute", top: 195, left: 35}} 
onMouseEnter={() => handleMouseEnter2()} onMouseLeave={() => handleMouseLeave2()}
onClick={handleNextOption3}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px" }} 
src={hovered2? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //3
style={{ position: "absolute", top: 336 , left: 35}} 
onMouseEnter={() => handleMouseEnter3()} onMouseLeave={() => handleMouseLeave3()}
onClick={handleOptionChange6}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={hovered3? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //4
style={{ position: "absolute", top: 638, left: 139 }} 
onMouseEnter={() => handleMouseEnter4()} onMouseLeave={() => handleMouseLeave4()}
onClick={handleNextOption5}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px" }} 
src={hovered4? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //5
style={{ position: "absolute", top: -10, left: 916}} 
onMouseEnter={() => handleMouseEnter18()} onMouseLeave={() => handleMouseLeave18()}
onClick={handleCycleOptions1}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={hovered18? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //6
style={{ position: "absolute", top: 262, left: 937}} 
onMouseEnter={() => handleMouseEnter12()} onMouseLeave={() => handleMouseLeave12()}
onClick={handleOptionChange7}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={hovered12? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //7
style={{ position: "absolute", top: 404, left: 937}} 
onMouseEnter={() => handleMouseEnter14()} onMouseLeave={() => handleMouseLeave14()}
onClick={handleNextOption4}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={hovered14? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //8
style={{ position: "absolute", top: 638, left: 827 }}
onMouseEnter={() => handleMouseEnter15()} onMouseLeave={() => handleMouseLeave15()}
onClick={handleNextOption8}>
<StyledImg
style={{ position: "absolute",width: "81px",height: "79px"  }} 
src={hovered15? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>

<StyledRoundButton  //9
style={{ position: "absolute", top: 638, left: 483 }}
onMouseEnter={() => handleMouseEnter16()} onMouseLeave={() => handleMouseLeave16()}
onClick={handleCycleOptions}>
<StyledImg
style={{ position: "absolute", width: "81px",height: "79px"  }} 
src={hovered16? "/config/images/changer0.png" : "/config/images/changer.png"}
alt="image"
draggable="false"
/>
</StyledRoundButton>


<CTNButton2
style={{position: "absolute", top: -45, left: 225, fontSize: 22, }} >
EXPLORE THOUSANDS OF COMBINATIONS! 
</CTNButton2>


  </s.Container>


<s.Container style={{ position: "absolute", top: 109, left: 50}} > 


<CTNButton2 
style={{ position: "absolute", top: 850, left: 358, fontSize: 20 }} 
onMouseEnter={() => handleMouseEnter21()} onMouseLeave={() => handleMouseLeave21()}
onClick={() => {audio2.play(); setHovered21(false); setcurrentQuestion6('¿RARITY?'); setShowMsgRoad2(true)}}> 
<StyledImg
src={"/config/images/More/view.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -27, left: -33, width: "238px", height: "70px "}} />

<StyledImg
src={hovered21 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "200px", height: "51px "}} />
<CTNButton2
 translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: -13, width: "200px", height: "52px"}}>
VIEW DETAILS</CTNButton2> </CTNButton2>



</s.Container>

<s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{currentQuestion6 ===  '¿RARITY?' && (<>

  {showMsgRoad2 && (
<>

<StyledImg src={"/config/images/More/Caracteristicas03.png"}
alt="image" draggable="false" style={{ position: "absolute", top: -65, left: -115, width: "1121px",height: "998px" }}/>

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 340, width: "155px",height: "50px",  }} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>


<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 290, left: 210, width: "400px",height: "214px",  }} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 200, left: 365}} 
>
RARITIES
</CTNButton2>


<CTNButton4
style={{ position: "absolute", top: 282, left: 10, border: '0px solid #ddd',  maxHeight: 302, padding: 10, overflowY: 'auto', width: "800px", height: "740px"  }}>
<p style={{ fontSize: 26, marginBottom: "20px" }}>CUSTOMIZE TO YOUR LIKING! CLICK ON THE ELEMENT NAME OR CHANGE ICON TO EDIT IT.</p> 
<p style={{ fontSize: 20, marginBottom: "5px" }}>HEAD </p>  
<p onClick={handleNextOption2} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption2} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>BODY</p>  
<p onClick={handleNextOption3} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption3} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>RIGHT HAND </p>  
<p onClick={handleOptionChange6} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption6} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>FEET</p>  
<p onClick={handleNextOption5} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption5} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>CHARACTER</p> 
<p onClick={handleCycleOptions1} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption1} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>LEFT HAND</p> 
<p onClick={handleOptionChange7} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption7} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>LEGS</p> 
<p onClick={handleNextOption4} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption4} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>BACKGROUND</p> 
<p onClick={handleNextOption8} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption8} </p>
<p style={{ fontSize: 20, marginBottom: "5px" }}>ANIMATION</p> 
<p onClick={handleCycleOptions} style={{ fontSize: 30, marginBottom: "10px", textTransform: "uppercase"}} > {selectedOption} </p>
</CTNButton4>


<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()}
onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false); setTimeout(() => setShowMsgRoad2(false) ); audio2.play();}}>
BACK
</CTNButton2>



</s.Container>

</>
)}
  
</>)}

</s.Container>


  <s.Container style={{ position: "absolute", top: 88, left: 52,}} >

{showMsg1 && !messageDismissed1 &&  (
<>

<StyledImg src={"/config/images/More/Caracteristicas03.png"}
alt="image" draggable="false" style={{ position: "absolute", top: -65, left: -115, width: "1121px",height: "998px" }}/>

<s.Container style={{position: "absolute", top: 0, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3)', position: "absolute", top: 616, left: 125, width: "155px",height: "50px",  }} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg style={{transform: 'scale(2.3)', position: "absolute", top: 616, left: 540, width: "155px",height: "50px"}} 
src={hovered23 ? "/config/images/msg02.png" : "/config/images/msg01.png"}alt="image" draggable="false"/>


<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 290, left: 210, width: "400px",height: "214px",  }} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>

<CTNButton2
style={{  transform: 'scale(2.3)', position: "absolute", top: 200, left: 340}} 
>
NOTIFICATION
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 400, left: 235, fontSize: 13, width: "353px",height: "70px"}} 
>
USE THE DEMO TO CREATE AN NFT THAT REFLECTS YOUR STYLE. THE COLLECTION HAS OVER 21 THOUSAND UNIQUE NFT CREATED FROM 9 TRAITS WITH APPROXIMATELY 300 UNIQUE DESIGNS PER TRAIT
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 622, left: 127,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()}
onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false); setTimeout(() => setMessageDismissed1(true), setShowMsg1(false) ); audio2.play();}}>
 
  
OKAY
</CTNButton2>



<CTNButton2 style={{transform: 'scale(2.3)', position: "absolute", top: 622, left: 542,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter23()} onMouseLeave={() => handleMouseLeave23()}
onClick={(e) => { setHovered23(false); setTimeout(() => setMessageDismissed1(true), setShowMsg1(false), setActiveButton(6), setcurrentQuestion00('¿RARITY?')); audio2.play();}}>
RARITY</CTNButton2>

</s.Container>

</>
)}

</s.Container>



</s.Container>


</ResponsiveWrapper>

</>              
</>}

</div>
)}

{activeButton === 4 && ( // 4 boton airdrop
 <div id="content4">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute", top: 450 }} test
>

  <s.Container flex={1} jc={"center"} ai={"center" }>




  <StyledImg
      src={"/config/images/More/Testw4.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 499, width: "199px",height: "192px" }} />

  </s.Container>

  {timeRemaining <= 0 ? ( 
    <>
<s.Container style={{ position: "absolute", top: 130, left: 52,}} >


<StyledImg
src="/config/images/More/slider.png" alt="image"
className={activeImage !== -1 ? 'slide-in' : ''} // Add class conditionally
style={{position: "absolute",  top: 340, left: 380, width: "260px", height: "85px"}}/>

<StyledImg
  src={"/config/images/More/Play.png"}
  alt="image"
  draggable="false"
  style={{  position: "absolute", top: 140, left: -120, width: "1100px", height: "480px",  }}
   />



<StyledImg src={hovered14 ? "/config/images/More/PlayThor2.png" : "/config/images/More/PlayThor.png"}
alt="image" draggable="false" style={{  position: "absolute", top: 115, left: 780, width: "150px", height: "150px", transformOrigin: 'bottom right', animation: 'rotate 1.3s infinite linear'   }} />

{activeImage >= 0 && (
  <StyledImg 
    src="/config/images/More/Book1.png" 
    alt="image" 
    draggable="false" 
    style={{ position: "absolute", top: 340, left: 81, width: "85px", height: "85px"}} 
  />
)}
{activeImage >= 1 && (
  <StyledImg 
    src="/config/images/More/Book1.png" 
    alt="image" 
    draggable="false" 
    style={{ position: "absolute", top: 340, left: 370, width: "85px", height: "85px"}} 
  />
)}
{activeImage === 2 && (
  <StyledImg 
    src="/config/images/More/Book2.png" 
    alt="image" 
    draggable="false" 
    style={{ position: "absolute", top: 340, left: 732, width: "85px", height: "85px"}} 
  />
)}



<CTNButton4
style={{ position: "absolute",  pointerEvents: 'none', top: 490, left: 80, fontSize: 24, padding: 10, border: '0px solid #ddd', borderRadius: 5, maxHeight: 352, overflowY: 'auto', width: "740px", height: "60px",   }}>
  <p style={{ marginBottom: "20px" }}>{frases[fraseActual]}</p>  
</CTNButton4>



<CTNButton
style={{position: "absolute", top: 750, left: 180,}}
onMouseEnter={() => handleMouseEnter12()} onMouseLeave={() => handleMouseLeave12()}
onClick={(e) => { setHovered12(false); setTimeout(() =>setActiveButton(6), setcurrentQuestion00('¿GIVEAWAY?')); audio2.play();}}>

<StyledImg src={hovered12 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} 
alt="image" draggable="false" />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
VIEW ALL RULES
</CTNButton>
</CTNButton>


<CTNButton
style={{position: "absolute", top: 0, left: 180,}}
onMouseEnter={() => handleMouseEnter13()} onMouseLeave={() => handleMouseLeave13()}
onClick={() => {audio2.play(); setcurrentQuestion7('¿SELECTION OF WINNERS??');  setHovered14(true); setShowMsgWiiner(true)}}> 

<StyledImg src={hovered13 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} 
alt="image" draggable="false" />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
SELECTION OF WINNERS?
</CTNButton>
</CTNButton>


{showMsgWinner &&  (
<>

<StyledImg src= "/config/images/More/Winner.png" alt="image" draggable="false"
style={{ position: "absolute", top: -10, left: 142, width: "611px", height: "125px" }} />

<StyledImg src= "/config/images/More/Winner.png" alt="image" draggable="false"
style={{ position: "absolute", top: 750, left: 142, width: "611px", height: "115px" }} />



<s.Container style={{position: "absolute", top: -40, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 288, left: 210, width: "400px",height: "214px"}} 
src={"/config/images/msg003.png"}
alt="image"
draggable="false"/>
</s.Container>

<s.Container style={{position: "absolute", top: -40, left: 45}} >

<StyledImg
style={{   transform: 'scale(2.3) ', position: "absolute", top: 617, left: 340, width: "155px",height: "50px"}} 
src={hovered22 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>
<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 625, left: 342,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter22()}
onMouseLeave={() => handleMouseLeave22()}
onClick={(e) => { setHovered22(false); setHovered14(false);setHovered(false);  setTimeout(() => setcurrentQuestion7(false), setShowMsgWiiner(false)); audio2.play();}}
 >
OKAY
</CTNButton2>
</s.Container>


</>
)}

{currentQuestion7 ===  '¿SELECTION OF WINNERS??' && (<>
    <CTNButton4
style={{ position: "absolute", top: 23, left: 60, fontSize: 24, maxHeight: 302, width: "790px", height: "740px"  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 28}}>INFORMATION</p>  
</CTNButton4>


<CTNButton4
style={{ position: "absolute", top: 240, left: 70, fontSize: 24, maxHeight: 300, overflowY: 'auto', width: "770px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Here's how we'll pick the lucky winners!</p> 
<p style={{ marginBottom: "10px" }}>List of participants:</p> 
<p style={{ marginBottom: "20px" }}>We gather everyone who signed up for the pre-sale and Priv-sale on their corresponding list.</p> 
<p style={{ marginBottom: "10px" }}>Automatic verification:</p> 
<p style={{ marginBottom: "20px" }}>Our code carefully checks the list to make sure that everyone complies with the rules of the game. Those who do not comply will unfortunately be eliminated.</p> 
<p style={{ marginBottom: "10px" }}>Random draw:</p> 
<p style={{ marginBottom: "20px" }}>Here comes the excitement! We use a random selection system, similar to a traditional lottery. Imagine that all the names of eligible participants are in a hat and we draw the winners at random - everyone has an equal chance to win.</p> 
<p style={{ marginBottom: "10px" }}>Unique winners:</p> 
<p style={{ marginBottom: "20px" }}>Our system is very careful to ensure that each winner is unique. This means no repeat wallet addresses, so more people have a chance to celebrate.</p> 
<p style={{ marginBottom: "10px" }}>Digital safe:</p> 
<p style={{ marginBottom: "20px" }}>Winners' names are kept in a safe and secure place, like a digital safe. Once it is locked, no one can change the results! This ensures the transparency and integrity of the sweepstakes.</p> 
<p style={{ marginBottom: "20px" }}>Under this transparent and verifiable code, conquest of paradise will choose the winners of all our contests.</p> 
<p style={{marginBottom: "20px" }}><CopiarCodigo/></p> 

</CTNButton4>

  {" "}

</>)}





</s.Container>
  </>

) : (
<>
<s.Container style={{ position: "absolute", top: 130, left: 52,}} > 
{
  (blockchain.account === "" || blockchain.smartContract === null) || !isChecked ? (
  <>


<StyledImg
  src={"/config/images/participa1.png"}
  alt="image"
  draggable="false"
  style={{  position: "absolute", top: 140, left: -33, width: "980px", height: "350px" }}
   />

<StyledImg style={{ transform: 'scale(1.5)',  position: "absolute", top: 30, left: 88, width: "730px",height: "70px" }} 
src={"/config/images/More/sv1.png"} alt="image" draggable="false"/>

<CTNButton2
  style={{ position: "absolute", top: 0 , left: 0, fontSize: 16}}
>
  {currentQuestion5 ===  '¿PRESALE?' && (
  <>
<CTNButton4
style={{ position: "absolute", top: 162, left: -30, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 315, overflowY: 'auto', width: "960px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Also participate in our Pre-Sale.</p> 

<p style={{ marginBottom: "10px" }}>* As a member of our Pre-sale, you will be able to:</p> 
<p style={{ marginBottom: "10px" }}>* Mintage a maximum of 199 NFTs</p> 
<p style={{ marginBottom: "10px" }}>* Continue your mint at the Public-sale.</p> 
<p style={{ marginBottom: "10px" }}>* Get a discount on each NFT by paying {presaleprice}.</p> 
<p style={{ marginBottom: "20px" }}>* Be among the first to get your Phygital NFTs.</p> 

<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see our Roadmap for dates related to the Pre-sale</p>  
<p style={{ marginBottom: "20px" }}>Make sure you have read the sweepstakes rules.</p>  

</CTNButton4>

<StyledImg  style={{transform: 'scale(1.5)', position: "absolute",top: 41 ,left: 351, width: "200px",height: "51px",}}
  src= "/config/images/aboutw3.png"   alt="image" draggable="false"/> </>)}

{currentQuestion5 ===  '¿PRIVSALE?' && (
  <>
<CTNButton4
style={{ position: "absolute", top: 162, left: -30, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 315, overflowY: 'auto', width: "960px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Don't miss our Private Sale, you can win a place in our Priv-sale!</p>  
<p style={{ marginBottom: "10px" }}>As a member of our Priv-sale, you will be able to:</p>  
<p style={{ marginBottom: "10px" }}>* Mintage a maximum of 599 NFTs</p>  
<p style={{ marginBottom: "10px" }}>* Access to all the next stages of the sale.</p>  
<p style={{ marginBottom: "10px" }}>* Get a great discount on every NFT, paying only {privsaleprice}.</p>  
<p style={{ marginBottom: "10px" }}>* Receive an exclusive gift to go with your Phygital NFT!</p>  
<p style={{ marginBottom: "20px" }}>* Be among the first to get your Phygital NFTs.</p>  
<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see our Roadmap for dates related to the Priv-sale</p>  
<p style={{ marginBottom: "20px" }}>Make sure you have read the sweepstakes rules.</p>  

</CTNButton4>

<StyledImg  style={{transform: 'scale(1.5)', position: "absolute",top: 41 ,left: -15, width: "200px",height: "51px",}}
  src= "/config/images/aboutw3.png"   alt="image" draggable="false"/> </>)}

{currentQuestion5 ===  '¿AIRDROP?' && (
  <>
<CTNButton4
style={{ position: "absolute", top: 162, left: -30, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 315, overflowY: 'auto', width: "960px", height: "740px"  }}>
  

<p style={{ marginBottom: "20px" }}>Get free NFTs, and yes, you can also earn ETH!</p>

<p style={{ marginBottom: "10px" }}>Will i give NFTs as a gift?</p>
<p style={{ marginBottom: "10px" }}>Particiate in our 3 exclusive sweepstakes and win amazing NFT at no cost. 80 NFT per drawing, so you have 3 chances to win!</p>
<p style={{ marginBottom: "20px" }}>Winners of our Airdrops will also enjoy the special benefits we have prepared for our cardholders.</p>
<p style={{ marginBottom: "10px" }}>Give Ethereum as a gift?</p>
<p style={{ marginBottom: "10px" }}>In our roadmap we have included an Airdrop of 10% of Ethereum revenues on the minting of the project. The 10% will be randomly distributed among the 25 winning addresses.</p>
<p style={{ marginBottom: "10px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected.</p>
<p style={{ marginBottom: "20px" }}>My help will be available without conditions or expectations. I will not accept recognition, participation in your projects or any other form of retribution.</p>
<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see our Roadmap for dates related to the Airdrops</p>  
<p style={{ marginBottom: "20px" }}>Make sure you have read the sweepstakes rules.</p>  

</CTNButton4>

  <StyledImg  style={{ transform: 'scale(1.5)', position: "absolute",top: 41 ,left: 718,width: "200px",height: "51px",}}
  src= "/config/images/aboutw3.png"   alt="image" draggable="false"/> </>)}


</CTNButton2>

 
<CTNButton2 
style={{ position: "absolute", top: 56, left: -2, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion5('¿PRIVSALE?');}}
onMouseEnter={() => handleMouseEnter2()} onMouseLeave={() => handleMouseLeave2()}>
<StyledImg
src={hovered2 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "200px", height: "51px "  }} />

<CTNButton2
 translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: -13, width: "200px", height: "52px"}}>
PRIV-SALE </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: 365, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion5('¿PRESALE?');}}
onMouseEnter={() => handleMouseEnter4()} onMouseLeave={() => handleMouseLeave4()}>
<StyledImg
src={hovered4 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "200px", height: "51px "  }} />
<CTNButton2
 translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: -13, width: "200px", height: "52px"}}>
PRE-SALE </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: 675, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion5('¿AIRDROP?');}}
onMouseEnter={() => handleMouseEnter3()} onMouseLeave={() => handleMouseLeave3()}>
<StyledImg
src={hovered3 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: 43, width: "200px", height: "51px " }} />
<CTNButton2  translate="no" style={{  transform: 'scale(1.5)',  position: "absolute",  top: -15, left: 43, width: "200px", height: "52px"}}>
AIRDROPS</CTNButton2> </CTNButton2>

<CTNButton
style={{position: "absolute", top: 530, left: 180,}}
onMouseEnter={() => handleMouseEnter12()} onMouseLeave={() => handleMouseLeave12()}
onClick={(e) => { setHovered12(false); setTimeout(() =>setActiveButton(6), setcurrentQuestion00('¿GIVEAWAY?')); audio2.play();}}>

<StyledImg src={hovered12 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} 
alt="image" draggable="false" />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
VIEW ALL RULES
</CTNButton>
</CTNButton>

<StyledImg
    style={{  transform: 'scale(1.5)',   position: "absolute", top: 680, left: 252, width: "390px", height: "40px" }}
    src={"/config/images/acept.png"}
    alt="image"
    draggable="false"/>
      
  <CTNButton2
  style={{  transform: 'scale(1.5)',  position: "absolute", top: 689, left: 307, fontSize: 14}}
  >
    <input
    style={{position: "absolute", top: -4, left: -42,}}
      type="checkbox"
      className="checkbox"
      checked={isChecked}
      onChange={(e) => setIsChecked(e.target.checked)}
    />
  I ACCEPT THE TERMS AND CONDITIONS.
  </CTNButton2>


  {blockchain.errorMsg === ""  ?  (
<>
{
<s.Container ai={"center"} jc={"center"}>




<CTNButton
style={{position: "absolute", top: 760, left: 180,}}
onClick={(e) => {
e.preventDefault();
if (!isChecked) {
  setErrorMessage2('BEFORE CONTINUING, TAKE A LOOK AT THE TERMS AND CONDITIONS: WE KNOW THEY ARE A BIT BORING, BUT THEY ARE IMPORTANT.');
  audio3.play();
  return;}
dispatch(connect());
getData();
audio2.play();
} }
onMouseEnter={() => handleMouseEnter5()}
onMouseLeave={() => handleMouseLeave5()}
>
<StyledImg
src={hovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
</CTNButton>




</s.Container>

}

</>
) :     ( 
<>

{  
  <>
<StyledImg style={{ position: "absolute", top: 15, left: -89, width: "1079px", height: "864px"  }} src={"/config/images/More/participa2.png"} alt="image" draggable="false"/>


<s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{blockchain.errorMsg}</p>  
</CTNButton4>



</s.Container>
</>
}

<StyledImg
    style={{  transform: 'scale(1.5)',   position: "absolute", top: 680, left: 252, width: "390px", height: "40px" }}
    src={"/config/images/acept.png"}
    alt="image"
    draggable="false"/>
      
  <CTNButton2
  style={{  transform: 'scale(1.5)',  position: "absolute", top: 689, left: 307, fontSize: 14}}
  >
    <input
    style={{position: "absolute", top: -4, left: -42,}}
      type="checkbox"
      className="checkbox"
      checked={isChecked}
      onChange={(e) => setIsChecked(e.target.checked)}
    />
  I ACCEPT THE TERMS AND CONDITIONS.
  </CTNButton2>


<CTNButton
style={{position: "absolute", top: 760, left: 180,}}
onClick={(e) => {
e.preventDefault();
if (!isChecked) {
  setErrorMessage2('YOU MUST ACCEPT THE TERMS AND CONDITIONS');
  audio3.play();
  return;}
dispatch(connect());
getData();
audio2.play();
} }
onMouseEnter={() => handleMouseEnter5()}
onMouseLeave={() => handleMouseLeave5()}
>
<StyledImg
src={hovered5 ? "/config/images/cmBoton1black.png" : "/config/images/cmBoton1.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -10, left: -38, width: "611px", height: "125px"  }} />

<CTNButton
style={{position: "absolute", top: 5, left: -24, fontSize: 36, width: "585px", height: "98px"  }}
>
CONNECT WALLET
</CTNButton>
</CTNButton>

</>
)}

{errorMessage2 && (
    <> 
    <StyledImg style={{ position: "absolute", top: 15, left: -89, width: "1079px", height: "864px"  }} src={"/config/images/More/participa2.png"} alt="image" draggable="false"/>

   <s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{errorMessage2}</p>  
</CTNButton4>



</s.Container>

   </> 
) }

  </>

) : (
    <>
  <s.Container style={{ position: "absolute", top: 0, left: 0,}} > 
  
  <StyledImg
src={"/config/images/More/participa.png"}
style={{  position: "absolute", top: -15, left: -78, width: "1070px", height: "435px" }}
   />

  <StyledImg
src={"/config/images/More/participa3.png"}
alt="image"   draggable="false"
style={{ position: "absolute", top: 440, left: -33, width: "980px", height: "200px" }}
     />
  
  <StyledImg
src={"/config/images/More/AirWhi.png"}
style={{  transform: 'scale(1.5)',  position: "absolute", top: 780, left: 65, width: "780px", height: "70px" }}
alt="image"   draggable="false"

     />


<CTNButton
style={{position: "absolute", top: -23, left: 160, fontSize: 24, width: "585px", height: "98px"  }}
>
GENERAL RULES
</CTNButton>

<CTNButton4
style={{ position: "absolute", top: 55, left: -70, fontSize: 24, padding: 10, border: '0px solid #ddd', borderRadius: 5, maxHeight: 352, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready for an epic adventure, get free NFTs, secure a place in the Pre-sale and Public-sale and you can win ETH too!</p>  
<p style={{ marginBottom: "20px" }}>The system will check if you comply with the rules on the day of each draw. Users who do not comply with the rules will be disqualified.</p>

<p style={{ marginBottom: "10px" }}>How to participate for a place on our Pre-sale and Priv-sale whitelist? </p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>3- Fill in the following form.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have an ETH address with a balance.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Pre-sale or Priv-sale” option. </p>  
<p style={{ marginBottom: "20px" }}>You will not be charged for participating in the contest, but you must have a balance if you want to buy during the minting.</p>  
<p style={{ marginBottom: "20px" }}>The Priv-sale it is necessary to register before {closeregister}, the winners of a place will be announced on {inforegister}. The winners will be able to participate in the Priv-sale from {privsale}.</p>  
<p style={{ marginBottom: "20px" }}>Pre-sale requires registration by {closeregister}, winners of a seat will be announced on {inforegister}. The winners will be able to participate in the Pre-sale from {presale}. </p>  
<p style={{ marginBottom: "20px" }}>Participating in the Priv-sale not only gives you early access, but also secures you a place in the Pre-sale, allowing you to continue your miting in the Pre-sale phase. </p>  
<p style={{ marginBottom: "20px" }}>You will have 3 days to participate in the Pre-sale and Priv-sale once it goes live.</p>

<p style={{ marginBottom: "10px" }}>How to participate in our NFT Airdrop?</p>
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>3- Fill in the following form.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Participate in our 3 exclusive sweepstakes and win amazing NFT at no cost. 80 NFT per drawing, so you have 3 chances to win!</p>  
<p style={{ marginBottom: "20px" }}>Winners will receive their NFTs within 24 hours after each drawing, check our Roadmap for exact days.</p>  

<p style={{ marginBottom: "10px" }}>How to participate in our ETH Airdrop?</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>3- Fill in the following form.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “ETH Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Register from {openairdropeth} to {closeairdropeth} to participate for a reward in Ethereum to fund new projects. The draw will take place on {airdropeth}, and if you are a winner you will receive it within 24 hours.</p>  
<p style={{ marginBottom: "20px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected. </p>  
<p style={{ marginBottom: "20px" }}>The amount of Ethereum to be distributed 10% of the minting profits. 10% will be distributed to 25 winning addresses.</p>  

</CTNButton4>

  
  
  <StyledImg
    style={{ transform: 'scale(1.5)', position: "absolute", top: 680, left: 240, width: "390px", height: "40px" }}
    src={"/config/images/acept.png"}
    alt="image"
    draggable="false"/>
      
  <CTNButton2
  style={{transform: 'scale(1.5)', position: "absolute", top: 690, left: 294, fontSize: 14}}
  >
    <input
    style={{position: "absolute", top: -4, left: -42,}}
      type="checkbox"
      className="checkbox"
      checked={isChecked2}
      onChange={(e) => setIsChecked2(e.target.checked)}
    />
  I ACCEPT THE TERMS AND CONDITIONS.
  </CTNButton2>




  <CTNButton2
      style={{ transform: 'scale(1.3)', position: "absolute", top: 570, left: 133, fontSize: 20, }}
      >
    <input
   type="text"  id="text-input" 
   value={text} onChange={(event) => setText(event.target.value)}
   autoComplete="off" 
   placeholder="    0x.01230yOuR03210aDdReSs01230wAlLeT03210"
   maxLength="42"
   size={41}
  />
    </CTNButton2>
  
      <CTNButton2
        style={{  transform: 'scale(1.3)', position: "absolute", top: 470, left: 60, fontSize: 20, }}
        >
    <input
      type="text"
      id="name-input"
      value={UserTwitter}
      onChange={(event) => setUserTwitter(event.target.value)}
      autoComplete="off" 
      placeholder="USER TWITTER"
      maxLength="15"
      size={17}
    />
      </CTNButton2>
  
      <CTNButton2
        style={{ transform: 'scale(1.3)', position: "absolute", top: 470,  left: 620, fontSize: 20, }}
        >
    <input
      type="text"
      id="last-name-input"
      value={UserDisc}
      onChange={(event) => setUserDisc(event.target.value)}
      placeholder="USER DISCORD"
      autoComplete="off" 
      maxLength="32"
      size={14}
    />
      </CTNButton2>

  <CTNButton2 style={{ transform: 'scale(1.5)',  position: "absolute", top: 751, left: -185, fontSize: 16,}}
onClick={(e) => {handleSend();} } onMouseEnter={() => handleMouseEnter15  ()}onMouseLeave={() => handleMouseLeave15()}>
<StyledImg src={hovered15 ? "/config/images/ActionBlack.png" : "/config/images/action0.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 18, left: 80, width: "309px", height: "53px" }} />
<CTNButton
style={{position: "absolute", top: 18, left: 80, width: "309px", height: "53px" , fontSize: 28}}> <p translate="no">PRE-SALE</p> </CTNButton>
  
  
</CTNButton2> <CTNButton2 style={{ transform: 'scale(1.5)',  position: "absolute", top: 751, left: 392, fontSize: 16}}
onClick={(e) => {handleSend2(); }} onMouseEnter={() => handleMouseEnter16()} onMouseLeave={() => handleMouseLeave16()} >
<StyledImg src={hovered16 ? "/config/images/ActionBlack.png" : "/config/images/action0.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 18, left: 79, width: "309px", height: "53px"  }} />
<CTNButton
style={{position: "absolute", top: 18, left: 80, width: "309px", height: "53px" ,fontSize: 28}}><p translate="no">PRIV-SALE</p></CTNButton></CTNButton2>



  {errorMessage && (
    <>

<StyledImg style={{ position: "absolute", top: -55, left: -75, width: "1060px", height: "950px"  }} src={"/config/images/More/participa02.png"} alt="image" draggable="false"/>

<s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{errorMessage}</p>  
</CTNButton4>



</s.Container>

   </>
) }


{errorMessage1 && (
      <>
<StyledImg style={{ position: "absolute", top: -55, left: -75, width: "1060px", height: "950px"  }} src={"/config/images/More/participa02.png"} alt="image" draggable="false"/>

<s.Container style={{position: "absolute", top: -80, left: 45}} >

<StyledImg
style={{transform: 'scale(2.3)', position: "absolute", top: 380, left: 200, width: "420px",height: "138px",  }} 
src={"/config/images/msg.png"}
alt="image"
draggable="false"/>


<CTNButton4
style={{ position: "absolute", top: 325, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 50, width: "790px", height: "740px"  }}> 
<p style={{position: "relative", top: -0, display: 'flex',  justifyContent: 'center', fontSize: 28}}>NOTIFICATION</p>  
</CTNButton4>

<CTNButton4
style={{ position: "absolute", top: 410, left: 15, fontSize: 24, border: '0px solid #ddd', maxHeight: 170, width: "790px", height: "740px"  }}> 
<p style={{ position: "relative", top: 0, marginBottom: "5px" }}>{errorMessage1}</p>  
</CTNButton4>



</s.Container>
   </>
) }

  </s.Container>
    </>
)}

  </s.Container>
</>
        )}


</ResponsiveWrapper>

</>              
</>}
</div>
)}

{activeButton === 5 && (// 5 boton Utilidad
<div id="content5">


{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",  top: 450}} test
>
  

  <s.Container flex={1} jc={"center"} ai={"center" }>



  <StyledImg
      src={"/config/images/More/Testw5.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 699, width: "199px",height: "192px" }} />
    

  </s.Container>

  <s.Container style={{ position: "absolute", top: 90, left: 52, }} >


<StyledImg style={{ transform: 'scale(1.5)',  position: "absolute", top: 30, left: 88, width: "730px",height: "70px" }} 
src={"/config/images/sv1.png"} alt="image" draggable="false"/>





<CTNButton2
  style={{ position: "absolute", top: 0, left: 0, fontSize: 16}}
>
{currentQuestion2 ===  '¿Server?'  &&  (<>

    <s.Container style={{ transform: 'scale(1.4)',  position: "absolute", top: 40, left: -75, pointerEvents: 'none'  }} > 

<StyledImg src={"/config/images/logosv0.png"}
alt="image" draggable="false" style={{ position: "absolute", top: 75, left: -15, width: "780px",height: "150px" }}/>
<StyledImg src={"/config/images/logosv.png"} 
alt="Conquest Of Paradise" draggable="false" style={{ position: "absolute", top: 92, left: -8, width: "190px",height: "125px"}}/>
  
    <CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 60, left: 190 }} >
 <p  translate="no" style={{position: "absolute",fontSize: 14, margin: "0 -500px -122px -270px" }}>CONQUEST OF PARADISE</p>  
 <p translate="no" style={{position: "absolute",fontSize: 14, color: "#AAAAAA", margin: "0 -700px -110px 310px" }}>1 / 20000</p> 
 <p translate="no" className="animated-text-2 "  style={{position: "absolute", margin: "0 -500px -185px -108px" }}>CONQUEST OF PARADISE</p> 
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -500px -185px -108px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-0"  style={{position: "absolute",fontSize: 24,  margin: "0 -500px -178px 65px" }}> »»&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;||&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;««</p> 
 <p translate="no" className="animated-text-3" style={{position: "absolute", margin: "0 -510px -185px 380px" }}>1.8 - 1.21.X </p>
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -500px -185px 390px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-4" style={{position: "absolute", margin: "0 -500px -265px -265px" }}>SERVER IN CREATION</p>
 <p translate="no" className="animated-text"  style={{position: "absolute", margin: "0 -600px -265px 280px" }}>MULTIPLE MODALITIES</p>
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -500px -265px -265px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-1"  style={{position: "absolute",fontSize: 22,  margin: "0 -600px -265px 280px" }}>[&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;]</p> 
 <p translate="no" className="animated-text-0"  style={{position: "absolute",fontSize: 24,  margin: "0 -600px -260px -47px" }}> »||«</p> 
</CTNButton2>
</s.Container>


<StyledImg style={{ transform: 'scale(1.5)', position: "absolute", top: 41, left: -14, width: "256px", height: "51px "}}
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>

<s.Container style={{ position: "absolute", top: 190, left: 0, }} > 



<StyledImg src={"/config/images/More/roadwxd.png"} alt="image" draggable="false"
style={{ position: "absolute", top: 310, left: -85, width: "1068px",height: "425px" }}  />

<StyledImg src={"/config/images/sv2.png"} 
alt="image" draggable="false" style={{ transform: 'scale(1.3)', position: "absolute", top: 207, left: 33, width: "830px",height: "60px" }} />

{currentQuestion3 ===  '¿Server?'  &&  (<>
  <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready to explore a digital universe full of adventures! our Minecraft server is under construction and will open its doors 4 months after the collection is sold out or the minting process ends on {endpublicsale} day.</p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setcurrentQuestion3('¿BENEFITS?')); audio2.play();}}>As a holder of our NFTs you will have impressive benefits, which are detailed in the following category “Benefits” you can click here to see them now.</p> 
<p style={{ marginBottom: "20px" }}>Within the server you will enjoy a wide range of regular game modes and the implementation of new game modes never seen before.</p> 
<p style={{ marginBottom: "20px" }}>You will also find safe and private spaces where you can chat with all your friends.</p> 
<p style={{ marginBottom: "20px" }}>Our server will be 100% open to all the possibilities that users can think of, so we promise to listen to the community to achieve a pleasant environment, where users become a fundamental part of the development and implementation of the server.</p> 

</CTNButton4>
<StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216, left: -50, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
  </>)}

{currentQuestion3 ===  '¿BENEFITS?'  &&  (<>
  
    <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A world of advantages beyond exclusivity. As a holder of our NFT you will get the following benefits and advantages.</p> 
<p style={{ marginBottom: "10px" }}>Access to a vip rank server and advantages:</p> 
<p style={{ marginBottom: "10px" }}>1- Vip access to all areas of the server: enjoy exclusive areas and special privileges reserved only for veteran members.</p> 
<p style={{ marginBottom: "10px" }}>2- Game mode kits: equip yourself with everything you need to master every challenge and become the king of the server.</p> 
<p style={{ marginBottom: "10px" }}>3- “NFT_holder_your_nick” sticker: proudly display your NFT holder status and stand out from the crowd.</p> 
<p style={{ marginBottom: "10px" }}>4- Exclusive game modes and chat rooms: enjoy unique content and engage in conversations with other NFT and Minecraft enthusiasts.</p> 
<p style={{ marginBottom: "10px" }}>5- Command ./Fly: raise your gameplay level and explore the world from a whole new perspective.</p> 
<p style={{ marginBottom: "20px" }}>6- future Pay To Earn server: you will be able to play and earn rewards in ETH based on your progress and achievements.</p> 
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿BENEFITS?')); audio2.play();}}>Click to see all Benefits</p>



</CTNButton4>

<StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216  , left: 223, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion3 ===  '¿PLAY TO EARN?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  

 
<p style={{ marginBottom: "20px" }}>Immerse yourself in a world of opportunities with our play-to-earn system! Experience a global, self-sustaining economy within the server.</p>
<p style={{ marginBottom: "10px" }}>Explore the various ways to generate income:</p>  
<p style={{ marginBottom: "10px" }}>1- Worker: offer your skills to the rest of the community by completing tasks and quests in exchange for remuneration.</p>  
<p style={{ marginBottom: "10px" }}>2- Entrepreneurship: hire workers, manage resources and establish your own in-game economic empire.</p>  
<p style={{ marginBottom: "10px" }}>3- Trade: trade items with other players to obtain valuable goods or accumulate wealth.</p>  
<p style={{ marginBottom: "10px" }}>4- Mining: extract scarce resources from the server and sell them to the highest bidder for huge profits.</p>  
<p style={{ marginBottom: "20px" }}>5- Builder: unleash your creativity by building impressive decorative or functional structures, then sell them to other players for profit.</p>  
<p style={{ marginBottom: "20px" }}>And that's just the beginning! We are constantly developing new and exciting ways for players to generate revenue within the server.</p>  
<p style={{ marginBottom: "20px" }}>This feature is still in the planning stages, but we are working hard to implement it as soon as possible after the server launch. Get ready to dive into a world of possibilities where your effort and creativity are rewarded.</p>  


</CTNButton4>
  <StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216, left: 496, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion3 ===  '¿COMING SOON?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 320, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 400, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Your opinion is important to us! In this server, the voice of the community is fundamental. We are committed to listen carefully to the suggestions and comments of our users to make continuous improvements, add new modes and benefits that enrich the gaming experience.</p>
<p style={{ marginBottom: "20px" }}>A server in evolution with constant updates and development and innovation on the fly.</p>
<p style={{ marginBottom: "20px" }}>We hope to have a committed community that actively participates in the development and improvement of the server. More than just a game our goal is to go beyond simple entertainment, creating an environment where the community can thrive and achieve their goals.</p>
<p style={{ marginBottom: "20px" }}>Imagine the possibility of your creations not only being part of the game, but also becoming valuable assets within the metaverse. You will be able to build your own virtual land, share it with other players and monetize it in various ways.</p>
<p style={{ marginBottom: "20px" }}>It is a promising future.</p>


</CTNButton4>

 <StyledImg
style={{ transform: 'scale(1.3)', position: "absolute", top: 216, left: 770, width: "177px", height: "44px"  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
  </>)}

  </s.Container>

  <s.Container style={{ transform: 'scale(1.3)', position: "absolute", top: 80, left: -130, }} >




  <CTNButton2 
style={{position: "absolute", top: 260, left: 54, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿Server?');}}
onMouseEnter={() => handleMouseEnter14()} onMouseLeave={() => handleMouseLeave14()}>
<StyledImg
src={hovered14 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "177px", height: "44px"  }} />
<CTNButton2 style={{ position: "absolute",  top: -5, left: 28}}>
SERVER? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 260, left: 264, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿BENEFITS?');}}
onMouseEnter={() => handleMouseEnter15()} onMouseLeave={() => handleMouseLeave15()}>
<StyledImg
src={hovered15 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "177px", height: "44px"  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: 28  }}>
BENEFITS? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 260, left: 472, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿PLAY TO EARN?');}}
onMouseEnter={() => handleMouseEnter16()} onMouseLeave={() => handleMouseLeave16()}>
<StyledImg
src={hovered16 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -11, width: "177px", height: "44px "  }} />
<CTNButton2 translate="no" style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
PLAY TO EARN? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 260, left: 684, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion3('¿COMING SOON?');}}
onMouseEnter={() => handleMouseEnter17()} onMouseLeave={() => handleMouseLeave17()}>
<StyledImg
src={hovered17 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -12, width: "177px", height: "44px "  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
COMING SOON? </CTNButton2> </CTNButton2>

  {" "}

    </s.Container>

  </>)}


{currentQuestion2 ===  '¿PHYGITAL NFT?'  &&  (<>

<s.Container style={{  transform: 'scale(1.5)', position: "absolute", top: 220, left: -0, }} >



<s.Container style={{ position: "absolute", top: -100, left: -65, }} >

<StyledImg style={{ position: "absolute", top: 182, left: -6, width: "192px", height: "342px" }}
src={"/config/images/TestSteveWeb1.png"} alt="image" draggable="false"/>
<StyledImg style={{ position: "absolute", top: 180, left: -10, width: "205px",height: "367px" }}
src={"/config/images/canva.png"} alt="image" draggable="false"/>
<CTNButton2
style={{ position: "absolute", top: 522, left: 40, fontSize: 12, width: "105px",height: "10px"  }}>
3D MODEL 
</CTNButton2>

</s.Container>

<s.Container style={{  position: "absolute", top: 5, left: -200 , }} >


<canvas ref={canvasRef2} alt="phygital Conquest Of Paradise" style={{ transform: 'scale(1.1)', position: "absolute", top: 95, left: 147, display: visibleCanvas === 'PHYGITAL NFT' ? 'block' : 'none' }} />
<canvas ref={canvasRef3} alt="Nfc Card Conquest Of Paradise" style={{ transform: 'scale(1.2)', position: "absolute", top:  95, left: 147,display: visibleCanvas === 'NFC CARD' ? 'block' : 'none' }} />

</s.Container></s.Container>

    


<StyledImg style={{ transform: 'scale(1.5)', position: "absolute", top: 41, left: 662, width: "256px", height: "51px "}}
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>


<StyledImg src={"/config/images/More/sv001.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: 290, left: 210, width: "800px",height: "630px"  }}  />

<StyledImg src={"/config/images/More/sv2.png"}
alt="image" draggable="false" style={{  transform: 'scale(1.3)', position: "absolute", top: 170, left: 35, width: "830px",height: "60px" }} />



{currentQuestion4 ===  '¿PHYGITAL NFT?'  &&  (<>
  <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
  <p style={{ marginBottom: "20px" }}>Joining two worlds, the virtual and the physical, is now possible. With our Phygital NFT and its accessories you will share the experience of exchanging parts with other NFT owners.</p>
  <p style={{ marginBottom: "20px" }}>With your Phygital NFT you can exchange parts and assemble it in the style you like, who doesn't like to assemble a toy?</p>
  <p style={{ marginBottom: "10px" }}>As a holder you will get:</p>
  <p style={{ marginBottom: "10px" }}>1- Receive a Phygital NFT: enjoy a physical representation of your digital avatar, a unique souvenir you can proudly display.</p>
  <p style={{ marginBottom: "10px" }}>2- Interchangeable items kit: equip your Phygital NFT with a complete armor of your favorite material by exchanging parts with other NFT holders.</p>
  <p style={{ marginBottom: "10px" }}>3- Random items: discover what surprises random items have in store for you, from swords and blocks to clothing for your NFT.</p>
  <p style={{ marginBottom: "10px" }}>4- Unique pieces in the future: get ready for the possibility of acquiring unique pieces for your Phygital NFT, increasing its value and exclusivity even more.</p>
  <p style={{ marginBottom: "10px" }}>5- Super exclusive pieces: look for limited production pieces, exclusive pieces that will make your Phygital NFT even more exclusive.</p>
  <p style={{ marginBottom: "20px" }}>6- Personal interaction: take the opportunity to create new friendships while exchanging your items.</p>
  <p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿BENEFITS?')); audio2.play();}}>Click to see all Benefits</p>

</CTNButton4>
<StyledImg
style={{ position: "absolute",  top: 174, left: -76, width: "230px", height: "56px "  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
  </>)}

{currentQuestion4 ===  '¿NFC CARD?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
 <p style={{ marginBottom: "20px" }}>The possibilities for connection and authentication are endless. Our nfc card will open the doors to the physical and digital world.</p>  
 <p style={{ marginBottom: "20px" }}>1- Future events: join exclusive events and forums for NFT holders, where you can share ideas, collaborate and create opportunities. </p>  
 <p style={{ marginBottom: "20px" }}>2- Exciting surprises: be prepared to receive surprise gifts and participate in exclusive, totally free activities at community-selected locations. </p>  
 <p style={{ marginBottom: "20px" }}>3- Guaranteed authenticity: use your Nfc card to verify the authenticity of your Phygital NFTs, ensuring the legitimate possession of your interchangeable pieces. </p>  
 <p style={{ marginBottom: "20px" }}>4- Peace of mind and confidence: enjoy the peace of mind of knowing that your Phygital NFTs are protected and authenticated, giving you total confidence in their value. To use the Nfc card your smartphone or device must be compatible with the technology. </p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿BENEFITS?')); audio2.play();}}>Click to see all Benefits</p>


</CTNButton4>
<StyledImg
style={{ position: "absolute",  top: 174, left: 198, width: "230px", height: "56px "  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion4 ===  '¿TO CLAIM?'  &&  (<>
    <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Claim your Phygital NFT and Nfc Card securely and easily in the future.</p>  
<p style={{ marginBottom: "20px" }}>We expect to enable the claims platform 6 months after the collection sells out or the minting process ends on {endpublicsale} day. Stay informed through our official channels to know the exact opening date.</p>   
<p style={{ marginBottom: "20px" }}>You will be able to claim your valuable Phygital NFT and Nfc Card through our official channels specifically set up for this transaction. We are committed to ensuring maximum security throughout the entire process, from before delivery until after you receive your Phygital NFT.</p>   
<p style={{ marginBottom: "20px" }}>Carefully follow the instructions provided to ensure a safe and satisfactory experience in the future.</p>   

</CTNButton4>
    <StyledImg
style={{ position: "absolute",  top: 174, left: 471, width: "231px", height: "56px "  }}  
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>
  {" "}
</>)}

{currentQuestion4 ===  '¿EXPECTATION?'  &&  (<> 
    <CTNButton4
style={{ position: "absolute", top: 307, left: 217, fontSize: 24, padding: 20, border: '0px solid #ddd', borderRadius: 5, maxHeight: 605, overflowY: 'auto', width: "780px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Our vision with Phygital NFTs extends beyond digital property. Our goal is to forge a tight-knit community built on friendship and camaraderie. We want our NFTs holders to become more than just a collection, we want them to become allies, collaborators and friends.</p>  
<p style={{ marginBottom: "20px" }}>Phygital NFTs with interchangeable parts unlock a whole new level of creative expression. Unleash your inner child and create something truly unique. Remember the joy of putting together physical toys? We're bringing back that same thrill, but with a digital twist.</p>  
<p style={{ marginBottom: "20px" }}>The Nfc Card could open doors to unforgettable moments with those who understand and share your interests. Imagine pleasant conversations over a cup of coffee or your favorite beverage sponsored by us as a gesture of gratitude.</p>  
<p style={{ marginBottom: "20px" }}>I have thousands of ideas that we could implement, but for me it is also important to know yours? What does the community want us to do? You will have to give us your proposals.</p>  

</CTNButton4>
 <StyledImg
style={{ position: "absolute",  top: 174, left: 745, width: "230px", height: "56px "  }} 
src= "/config/images/aboutw3.png" alt="image" draggable="false"/>

  {" "}
</>)}



  <s.Container style={{ transform: 'scale(1.3)', position: "absolute", top: 40, left: -129, }} >


  <CTNButton2 
style={{position: "absolute", top: 118, left: 54, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿PHYGITAL NFT?'); handlePhygitalNFTClick();}}
onMouseEnter={() => handleMouseEnter14()} onMouseLeave={() => handleMouseLeave14()}>
<StyledImg
src={hovered14 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "177px", height: "43px"  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
PHYGITAL NFT? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 118, left: 264, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿NFC CARD?'); handleNFCCardClick();}}
onMouseEnter={() => handleMouseEnter15()} onMouseLeave={() => handleMouseLeave15()}>
<StyledImg
src={hovered15 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "178px", height: "43px"  }} />
<CTNButton2 style={{ position: "absolute",  top: -5, left: -8, width: "170px"}}>
NFC CARD? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 118, left: 472, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿TO CLAIM?');}}
onMouseEnter={() => handleMouseEnter16()} onMouseLeave={() => handleMouseLeave16()}>
<StyledImg
src={hovered16 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -11, width: "178px", height: "43px "  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
TO CLAIM? </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 118, left: 684, fontSize: 16 }} onClick={() => {audio2.play(); setcurrentQuestion4('¿EXPECTATION?');}}
onMouseEnter={() => handleMouseEnter18()} onMouseLeave={() => handleMouseLeave18()}>
<StyledImg
src={hovered18 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{ position: "absolute", top: -15, left: -12, width: "177px", height: "43px "  }} />
<CTNButton2 style={{ position: "absolute", top: -5, left: -8, width: "170px"}}>
EXPECTATION? </CTNButton2> </CTNButton2>




{" "}
</s.Container>
</>)}



  
</CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: -2, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion2('¿Server?');}}
onMouseEnter={() => handleMouseEnter2()} onMouseLeave={() => handleMouseLeave2()}>
<StyledImg
src={hovered2 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -12, width: "256px", height: "51px "  }} />
<CTNButton2  translate="no" style={{ transform: 'scale(1.5)',  position: "absolute",  top: -15, left: 15, width: "200px", height: "52px"}}>
SERVER </CTNButton2> </CTNButton2>

<CTNButton2 
style={{ position: "absolute", top: 56, left: 675, fontSize: 20 }} onClick={() => {audio2.play(); setcurrentQuestion2('¿PHYGITAL NFT?');}}
onMouseEnter={() => handleMouseEnter3()} onMouseLeave={() => handleMouseLeave3()}>
<StyledImg
src={hovered3 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"} alt="image" draggable="false"
style={{  transform: 'scale(1.5)',  position: "absolute", top: -15, left: -13, width: "256px", height: "51px " }} />
<CTNButton2 style={{  transform: 'scale(1.5)',  position: "absolute",  top: -15, left: 10, width: "200px", height: "52px"}}>
PHYGITAL NFT </CTNButton2> </CTNButton2>










</s.Container>

</ResponsiveWrapper>

</>              
</>}
</div>
)}

{activeButton === 6 && ( // 6 boton Historia
<div id="content6">
{ 
<>
<>

<ResponsiveWrapper flex={1} style={{padding: 24, position: "absolute",  top: 450}} test
>

  <s.Container flex={1} jc={"center"} ai={"center" }>



  <StyledImg
      src={"/config/images/More/Testw6.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -122, left: 901, width: "199px",height: "192px" }} />

  </s.Container>

<s.Container style={{ position: "absolute", top: 85, left: 52, }} > 



<StyledImg
      src={"/config/images/More/aboutw2.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 50, left: -30 , width: "960px",height: "400px" }} />

<s.Container style={{ position: "absolute", top: 0, left: 0, }} >  

<StyledImg src={"/config/images/More/roadwxd.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: 480, left: -85, width: "1068px",height: "440px" }}  />
  
{currentQuestion00 ===  '¿WHAT IS THIS?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A collection that lives in the Ethereum blockchain with more than 16,350 hours of dedication have been captured in this digital work, the result of painstaking work in Blender, Vs code, Photoshop and other tools. have given life to this digital masterpiece, composed of 9 layers that house a large number of unique layered designs.</p>  
<p style={{ marginBottom: "20px" }}>This collection goes beyond a simple NFT. It is an invitation to reconnect friendship. We propose a journey to a tangible paradise, where screens are turned off and laughter is turned on. Why fight for paradise when we can live together, sharing unforgettable moments with friends? Discover that true paradise resides in authentic connections, in the joy of sharing and in the beauty of life itself.</p>  
<p style={{ marginBottom: "20px" }}>Conquest of Paradise is an experience. An opportunity to explore the confines of digital creativity, pay homage to the Minecraft universe and be part of a community that celebrates art and innovation.</p>  
<p style={{ marginBottom: "20px" }}>Each of the 21,000 NFT that have been created are unrepeatable. discover a piece of digital art as unique as you are, with its own personality and rarity, ready to become a treasure in your collection.</p>  
<p style={{ marginBottom: "20px" }}>Beyond its visual beauty, we invite you to join a dynamic and passionate community of digital art enthusiasts and Minecraft lovers. Share your passion, form meaningful connections and participate in a constantly evolving project.</p>  

</CTNButton4>
  {" "}
  <StyledImg src="/config/images/aboutw3.png"
alt="image" draggable="false" style={{ position: "absolute", top: 59, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿CREATION?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>This collection was born from a dream and the intention to unite two worlds through a bridge. A bridge that unites the passionate builders of Minecraft with the enthusiastic collectors of NFT. A place where digital creativity merges with the nostalgia of a game that marked all its players.</p>  
<p style={{ marginBottom: "20px" }}>I aspire to create a dynamic and tight-knit community, where Minecraft lovers, NFT collectors and everyone else can connect, share experiences and forge tangible friendships in an increasingly digitized world, where we spend hours in front of screens, I dream of a balance between the virtual and the real.</p>  
<p style={{ marginBottom: "20px" }}>Conques of Paradise aims to be that meeting point, where virtual friendships are transformed into real connections, full of laughter, conversations and unforgettable moments.</p>  
<p style={{ marginBottom: "20px" }}>Conquest of Paradise is a dream come true. A dream that invites you to explore new horizons, unite communities and build a future where passion, creativity and friendship are the foundations of a more prosperous and connected world.</p> 
<p style={{ marginBottom: "20px" }}>It's not just a NFT collection, it's the materialization of my dream and passion.</p> 



</CTNButton4>
  {" "}
  <StyledImg src="/config/images/aboutw3.png"
alt="image" draggable="false" style={{ position: "absolute", top: 59, left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿BENEFITS?' && (<>
    <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  

<p style={{ marginBottom: "20px" }}>A world of advantages beyond exclusivity. As a holder of our NFT you will get the following benefits and advantages.</p> 

<p style={{ marginBottom: "10px" }}>1- Access to a vip rank server and advantages:</p> 
<p style={{ marginBottom: "10px" }}>* Vip access to all areas of the server: enjoy exclusive areas and special privileges reserved only for veteran members.</p> 
<p style={{ marginBottom: "10px" }}>* Game mode kits: equip yourself with everything you need to master every challenge and become the king of the server.</p> 
<p style={{ marginBottom: "10px" }}>* “NFT_holder_your_nick” sticker: proudly display your NFT holder status and stand out from the crowd.</p> 
<p style={{ marginBottom: "10px" }}>* Exclusive game modes and chat rooms: enjoy unique content and engage in conversations with other NFT and Minecraft enthusiasts.</p> 
<p style={{ marginBottom: "10px" }}>* Command ./Fly: raise your gameplay level and explore the world from a whole new perspective.</p> 
<p style={{ marginBottom: "10px" }}>* future Pay To Earn server: you will be able to play and earn rewards in ETH based on your progress and achievements.</p> 
<p style={{ marginBottom: "20px" }}>We expect to bring the server online 4 months after the collection runs out or the minting process ends on {endpublicsale} day.</p> 

<p style={{ marginBottom: "10px" }}>2- NFT Phygital and its accessories:</p> 
<p style={{ marginBottom: "10px" }}>* Receive a Phygital NFT: enjoy a physical representation of your digital avatar, a unique souvenir you can proudly display.</p> 
<p style={{ marginBottom: "10px" }}>* Interchangeable items kit: equip your Phygital NFT with a complete armor of your favorite material by exchanging parts with other NFT holders.</p> 
<p style={{ marginBottom: "10px" }}>* Random items: discover what surprises random items have in store for you, from swords and blocks to clothing for your NFT.</p> 
<p style={{ marginBottom: "10px" }}>* Unique pieces in the future: get ready for the possibility of acquiring unique pieces for your Phygital NFT, increasing its value and exclusivity even more.</p> 
<p style={{ marginBottom: "10px" }}>* Super exclusive pieces: look for limited production pieces, exclusive pieces that will make your Phygital NFT even more exclusive.</p> 
<p style={{ marginBottom: "10px" }}>* Personal interaction: take the opportunity to create new friendships while exchanging your items.</p> 
<p style={{ marginBottom: "20px" }}>We expect to enable the claims platform 6 months after the collection is sold out or the minting process ends on {endpublicsale} day.</p> 

<p style={{ marginBottom: "10px" }}>3- Nfc card for exclusive connection and authentication:</p> 
<p style={{ marginBottom: "10px" }}>* Future events: join exclusive events and forums for NFT holders, where you can share ideas, collaborate and create opportunities. </p> 
<p style={{ marginBottom: "10px" }}>* Exciting surprises: be prepared to receive surprise gifts and participate in exclusive, totally free activities at community-selected locations. </p> 
<p style={{ marginBottom: "10px" }}>* Guaranteed authenticity: use your Nfc card to verify the authenticity of your Phygital NFTs, ensuring the legitimate possession of your interchangeable pieces. </p> 
<p style={{ marginBottom: "10px" }}>* Peace of mind and confidence: enjoy the peace of mind of knowing that your Phygital NFTs are protected and authenticated, giving you total confidence in their value. To use the Nfc card your smartphone or device must be compatible with the technology.</p> 
<p style={{ marginBottom: "10px" }}>We expect to enable the claims platform 6 months after the collection runs out or the minting process ends on {endpublicsale} day.</p> 

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 59, left: 642, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿WHO AM I?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>I have always been fascinated by the possibilities offered by the digital world to create, innovate and express creativity using the potential of NFTs. I knew it was the perfect opportunity to combine my passions and give life to a unique project.</p> 
<p style={{ marginBottom: "20px" }}>Creating this collection has not been an easy road. Everything you will see in this project has been made by me, so I have had to learn to do many things I did not know, economic difficulties have not allowed me to have a team or hire experts in each subject, plus I have also had many technical difficulties related to hardware and software.</p> 
<p style={{ marginBottom: "20px" }}>The biggest challenge i have faced has been the loss of Gordito, my siamese cat, my adventure companion and my biggest fan. We would spend hours together, him curled up on my lap while i immersed myself in work. His departure left a void in my heart and in my life that i still feel. I miss his constant companionship and his comforting purr. Although the loss has been painful, his memory inspires me to keep going, carrying his spirit with me in this project.</p> 
<p style={{ marginBottom: "20px" }}>The tight budget was another challenge, bordering on non-existent, leaving no room for unforeseen events such as the irreparable loss of my i3 laptop with 8gb ram. Although modest in specifications, its sentimental value was incalculable and it succumbed to the demanding daily workload. This unexpected breakdown became a major technical obstacle, testing my endurance and creativity to find alternative solutions.</p> 
<p style={{ marginBottom: "20px" }}>I have worked on every detail of the collection, the creation of the 3d models, the development of the Minecraft server, the 3d printing of the phygital models and even the website of the project. It has been a lonely process, but it has also allowed me to have total control over the materialization of my dream.</p> 
<p style={{ marginBottom: "20px" }}>I hope my story inspires others to pursue their dreams, no matter the difficulties along the way. Passion, creativity and perseverance are powerful tools that allow us to overcome any obstacle and achieve our goals.</p> 
<p style={{ marginBottom: "20px" }}>I am very proud of what I have achieved with the collection. it is a project that represents my passion, my creativity and my effort. I have given 1,000% on this project, and it will not be the only one you will see, however I wish to remain anonymous under the pseudonym “herobrine” for the time being.</p> 

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 162, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿PLAYERS?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>More than a game and a NFT, an experience for everyone. Conquest of Paradise is not limited to Minecraft players. It's an NFT collection that opens its doors to a universe of possibilities for any enthusiast of digital collectibles and unique experiences. </p> 
<p style={{ marginBottom: "20px" }}>Are you a collector of toys or characters? This collection brings you phygital, a unique experience that combines the digital and physical worlds. Each NFT comes with a collectible Phygital figure that you can display, trade or even use in board games.</p> 
<p style={{ marginBottom: "20px" }}>Do you love community and social interaction? Conquest of Paradise invites you to join a dynamics, tight-knit community where you can share your passion for NFTs, meet people with similar interests and participate in exciting events. </p> 
<p style={{ marginBottom: "20px" }}>Interested in supporting this new artist and his innovative projects? by purchasing a NFT, you will be contributing to the growth of a creative project that seeks to push the boundaries of the NFT and Minecraft world. </p> 
<p style={{ marginBottom: "20px" }}>Conquest of Paradise it's a platform for anyone to explore their passion for digital collectibles, participate in a dynamics community and support innovative projects.</p> 

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 162,  left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿SUPPORT?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>

<p style={{ marginBottom: "20px" }}>A beacon of support in the vast universe of NFTs. As an NFT enthusiast and 3d designer, i know firsthand the complexity and confusion surrounding this world. Information is abundant, but often contradictory, and finding reliable help can be a challenge, especially for those of us with limited resources. </p>  
<p style={{ marginBottom: "20px" }}>I have experienced the frustration of looking for help in this world of NFTs where most are only looking to make a profit, without any real interest in helping the community. That's why it gives me great satisfaction to announce that Conquest of Paradise will be offering totally free advice and assistance to all enthusiasts getting started in the world of NFTs.</p>  
<p style={{ marginBottom: "20px" }}>More than advice, we provide you with real support. In our roadmap we have included an airdrop of 10% of Ethereum revenue during project minting randomly distributed to 25 winning addresses. This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to discuss your project and get priority to be selected.</p>  
<p style={{ marginBottom: "20px" }}>My help will be available without conditions or expectations. I will not accept recognition, participation in your projects or any other form of retribution. My only goal is to help others navigate this unknown terrain and achieve their dreams.</p>  
<p style={{ marginBottom: "20px" }}>However, it is important to clarify that Conques of Paradise will not sponsor or promote new projects. My goal is to provide technical support and guidance to those who need it. I believe that with a solid foundation of knowledge and the right tools, every person has the potential to achieve success in this field. </p>  
<p style={{ marginBottom: "20px" }}>If you are an enthusiast looking to take your first steps into the world of NFT, i invite you to join the Conquest of Paradise community. Here you will find a safe and free space.</p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => {setTimeout(() => setActiveButton(6), setcurrentQuestion00('¿GIVEAWAY?')); audio2.play();}}>Click to learn more about Airdrop ETH</p>





</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 162,  left: 642, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿COMMUNITY?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A dynamic community. My dream with this collection goes beyond a simple NFT collection. I aspire to create a space where the passion for NFTs and Minecraft come together to build a dynamic and united community.</p>  
<p style={{ marginBottom: "20px" }}>The benefits of Conquest of Paradise are not limited to the Phygital NFT and digital NFT. I want to foster a community where its members can interact face-to-face, leaving screens behind and rediscovering the joy of role-playing games, board games and social interaction.</p>  
<p style={{ marginBottom: "20px" }}>Imagine a space where they can come together to chat, play, have fun and share experiences. A place where healthy group activities are encouraged, and of course, we should also have this space in the online community. </p>  
<p style={{ marginBottom: "20px" }}>My goal is to create a strong and passionate community, where each member feels valued and actively participates. A community that adds, that supports and grows with us. Conquest of Paradise it is an invitation to join a community where passion, creativity and human connection are the protagonists.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 265, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿GIVEAWAY?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready for an epic adventure, get free NFTs, secure a place in the Pre-sale and Public-sale and you can win ETH too!</p>  
<p style={{ marginBottom: "20px" }}>The system will check if you comply with the rules on the day of each draw. Users who do not comply with the rules will be disqualified.</p>

<p style={{ marginBottom: "10px" }}>How to participate for a place on our Pre-sale and Priv-sale whitelist? </p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>3- Fill in the form: ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have an ETH address with a balance.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Pre-sale or Priv-sale” option. </p>  
<p style={{ marginBottom: "20px" }}>You will not be charged for participating in the contest, but you must have a balance if you want to buy during the minting.</p>  
<p style={{ marginBottom: "20px" }}>The Priv-sale it is necessary to register before {closeregister}, the winners of a place will be announced on {inforegister}. The winners will be able to participate in the Priv-sale from {privsale}.</p>  
<p style={{ marginBottom: "20px" }}>Pre-sale requires registration by {closeregister}, winners of a seat will be announced on {inforegister}. The winners will be able to participate in the Pre-sale from {presale}. </p>  
<p style={{ marginBottom: "20px" }}>Participating in the Priv-sale not only gives you early access, but also secures you a place in the Pre-sale, allowing you to continue your miting in the Pre-sale phase. </p>  
<p style={{ marginBottom: "20px" }}>You will have 3 days to participate in the Pre-sale and Priv-sale once it goes live.</p>

<p style={{ marginBottom: "10px" }}>How to participate in our NFT Airdrop?</p>
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>3- Fill in the form: ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Participate in our 3 exclusive sweepstakes and win amazing NFT at no cost. 80 NFT per drawing, so you have 3 chances to win!</p>  
<p style={{ marginBottom: "20px" }}>Winners will receive their NFTs within 24 hours after each drawing, check our Roadmap for exact days.</p>  

<p style={{ marginBottom: "10px" }}>How to participate in our ETH Airdrop?</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>1- Follow the Twitter account ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>2- Join the channel on Discord ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>3- Fill in the form: ◄ Click to here.</p>  
<p style={{ marginBottom: "10px" }}>4- Make sure you have a valid ETH address.</p>  
<p style={{ marginBottom: "20px" }}>5- Choose the “ETH Airdrop” option.</p>  
<p style={{ marginBottom: "20px" }}>Register from {openairdropeth} to {closeairdropeth} to participate for a reward in Ethereum to fund new projects. The draw will take place on {airdropeth}, and if you are a winner you will receive it within 24 hours.</p>  
<p style={{ marginBottom: "20px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected. </p>  
<p style={{ marginBottom: "20px" }}>The amount of Ethereum to be distributed 10% of the minting profits. 10% will be distributed to 25 winning addresses.</p>  
<p style={{ marginBottom: "20px" }}onClick={(e) => { window.open(etherlink, "_blank"); audio2.play(); }}>Click here to view the exploit profits on our Smart-contract {contract} using Etherescan.</p>  
<p style={{ marginBottom: "20px" }}onClick={(e) => {setTimeout(() => setActiveButton(4)); audio2.play();}}>Click here to participate in one of the following options.</p>  

</CTNButton4>

{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 265, left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿RARITY?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>A universe of possibilities in each NFT. Enter a world of infinite possibilities in this collection. Each NFT is a unique and exclusive piece. Explore a universe of combinations and discover the rarity that awaits you.</p>  
<p style={{ marginBottom: "20px" }} onClick={(e) => { setActiveButton(3), setShowMsg(false), setShowMsg1(true), audio2.play(); reloadGifs();}}>If you want to try something new visit our Rarities section by clicking here, you will find an interactive game that will allow you to mix and match a small percentage of the features available for our more than 21,000 NFTs. visualize an approximation of what our NFTs will look like.</p>  

<p style={{ marginBottom: "10px" }}>A glimpse of the diversity of conquest of paradise: </p>  
<p style={{ marginBottom: "10px" }}>* Animations: 4.</p>  
<p style={{ marginBottom: "10px" }}>* Backgrounds: 288.</p>  
<p style={{ marginBottom: "10px" }}>* Characters: 8. </p>  
<p style={{ marginBottom: "10px" }}>* Head: 330.</p>  
<p style={{ marginBottom: "10px" }}>* Body: 339.</p>  
<p style={{ marginBottom: "10px" }}>* Legs: 302. </p>  
<p style={{ marginBottom: "10px" }}>* Feet: 304.</p>  
<p style={{ marginBottom: "10px" }}>* Right hand: 338.</p>  
<p style={{ marginBottom: "20px" }}>* Left hand: 171.</p>  

<p style={{ marginBottom: "20px" }}>Our diversity gives us the possibility of having more than 124 billion possible combinations! a number so large that it surpasses our imagination. Especially considering that we have only created just over 21,000 unique NFT. </p>  
<p style={{ marginBottom: "20px" }}>Get ready for a fascinating journey where creativity and uniqueness go hand in hand in every NFT. </p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 265, left: 642, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿STAFF-SALE?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>

<p style={{ marginBottom: "20px" }}>Conquest of paradise is a dream come true, a work that i treasure with my soul. I have therefore decided to preserve a part of this precious collection to ensure that its legacy will live on and continue to amaze the community. </p>  
<p style={{ marginBottom: "20px" }}>This special reserve of NFTs, guarded by me, "herobrine", will allow us to create unique experiences in the future. Imagine surprise airdrops, exclusive rewards for the most loyal holders and events that will take your breath away. </p>  
<p style={{ marginBottom: "20px" }}>This reserve is not only a personal treasure, but a promise that Conquest of Paradise will continue to evolve and offer new adventures to those who join this epic journey. </p>  
<p style={{ marginBottom: "20px" }}>Prepare for a future full of surprises and thrills!.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 368, left: -21, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿ROADMAP?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "20px" }}>Get ready for an unforgettable adventure.</p>  
<p style={{ marginBottom: "10px" }}>Start:</p>  
<p style={{ marginBottom: "20px" }}>On {start} we begin an extraordinary journey to our paradise. Join us in this unique experience and discover all that we have in store for you. Don't miss our Priv-sale, Pre-sale and Public-sale!</p>  

<p style={{ marginBottom: "10px" }}>Sales:</p>  
<p style={{ marginBottom: "10px" }}>* Priv-sale: register before {closeregister} for a chance to win a spot in the Priv-sale. Winners will be announced on {inforegister}. The Priv-sale will run from {privsale} at a special price of {privsaleprice} per NFT. </p>  
<p style={{ marginBottom: "10px" }}>* Pre-sale: register before {closeregister} for a chance to win a spot in the Pre-sale. Winners will be announced on {inforegister}. The Pre-sale will run from {presale} at a special price of {presaleprice} per NFT. </p>  
<p style={{ marginBottom: "20px" }}>* Public-sale: The moment we've all been waiting for has arrived! the Public-sale will start on {publicsale} and end on {endpublicsale}. You will be able to purchase your NFTs at a price of {publicsaleprice} per NFT. </p>  

<p style={{ marginBottom: "10px" }}>Airdrops: </p>  
<p style={{ marginBottom: "10px" }}>* Airdrop 1: register from {openairdrop1} to day {closeairdrop1} to participate. The drawing will be held on {airdrop1} and winners will receive their free NFTs within 24 hours.</p>  
<p style={{ marginBottom: "10px" }}>* Airdrop 2: register from {openairdrop2} to day {closeairdrop2} to participate. The drawing will be held on {airdrop2} and winners will receive their free NFTs within 24 hours. </p>  
<p style={{ marginBottom: "20px" }}>* Airdrop 3: register from {openairdrop3} to day {closeairdrop3} to participate. The drawing will be held on {airdrop3} and winners will receive their free NFTs within 24 hours. </p>  

<p style={{ marginBottom: "10px" }}>Benefits:</p>  
<p style={{ marginBottom: "10px" }}>* Win a prize in Ethereum: register from {openairdropeth} to day {closeairdropeth} to participate. The draw will take place the day {airdropeth} and winners will receive the prize in Ethereum within 24 hours. </p>  
<p style={{ marginBottom: "10px" }}>This airdrop aims to boost new and promising projects like yours. Contact us via Discord / Support to tell us about your project and get priority to be selected. </p>  
<p style={{ marginBottom: "10px" }}>* Minecraft server: get ready to explore a digital universe full of adventures! our Minecraft server is under construction and will open its doors 4 months after the collection is sold out or the minting process ends on {endpublicsale} day.</p>  
<p style={{ marginBottom: "20px" }}>* Phygital NFT: your experience transcends the digital! we are manufacturing an exclusive Phygital that merges the physical and digital to take your adventure to another level. Distribution of this exclusive item is scheduled for 6 months after the collection sells out or the minting process ends on {endpublicsale} day.</p>  

<p style={{ marginBottom: "20px" }}onClick={(e) => {setActiveButton(2), setShowMsg(true), setShowMsg1(false), audio2.play(); }}>Click here to see the graphical Roadmap.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 368, left: 311, width: "279px", height: "70px "}} /></>)}

{currentQuestion00 === '¿CONTACT US?' && (<>
  <CTNButton4
style={{ position: "absolute", top: 495, left: -72, fontSize: 24, padding: 20, border: '0px solid #ddd', maxHeight: 410, overflowY: 'auto', width: "1040px", height: "740px"  }}>
  
<p style={{ marginBottom: "10px" }}>Connect with us and be part of the community, You will find a place to:</p>  
<p style={{ marginBottom: "10px" }}>* Share your passion for NFTs and Minecraft. </p>  
<p style={{ marginBottom: "10px" }}>* Connect with other enthusiasts and build lasting relationships. </p>  
<p style={{ marginBottom: "10px" }}>* Receive the latest news and updates about the project. </p>  
<p style={{ marginBottom: "10px" }}>* Participate in exclusive events and exciting contests. </p>  
<p style={{ marginBottom: "20px" }}>* Get access to unique community member-only benefits. </p>  

<p style={{ marginBottom: "10px" }}>Click on the social network you wish to visit:</p>
<p style={{ marginBottom: "10px" }} onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>Discord.</p>  
<p style={{ marginBottom: "10px" }} onClick={(e) => { window.open(tiktoklink, "_blank"); audio2.play(); }}>Tiktok.</p>  
<p style={{ marginBottom: "10px" }} onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>TWITTER.</p> 
<p style={{ marginBottom: "20px" }} onClick={(e) => { window.open(instagramlink, "_blank"); audio2.play(); }}>Instagram.</p>  

<p style={{ marginBottom: "20px" }}>If you have any questions or wish to contact us for any specific reason, you can do it through our Discord / support.</p>  

</CTNButton4>
{" "}
<StyledImg   src="/config/images/aboutw3.png"
alt="image"   draggable="false"   style={{ position: "absolute", top: 368, left: 642, width: "279px", height: "70px "}} /></>)}


</s.Container>

<CTNButton2 /////////////////////////////////////////////////////////////////////////////////// preguntas
  style={{ position: "absolute", top: 75, left: -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿WHAT IS THIS?');
  }}
  onMouseEnter={() => handleMouseEnter()}
  onMouseLeave={() => handleMouseLeave()}
>
<StyledImg
src={hovered ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />


<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
WHAT IS THIS?
</CTNButton2>
</CTNButton2>

<CTNButton2

style={{ position: "absolute", top: 75, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿CREATION?');
  }}
  onMouseEnter={() => handleMouseEnter2()}
  onMouseLeave={() => handleMouseLeave2()}
>
<StyledImg
src={hovered2 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
CREATION?
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 75, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿BENEFITS?');
  }}
  onMouseEnter={() => handleMouseEnter3()}
  onMouseLeave={() => handleMouseLeave3()}
>
<StyledImg
src={hovered3 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
BENEFITS?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 178, left:  -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿WHO AM I?');
  }}
  onMouseEnter={() => handleMouseEnter4()}
  onMouseLeave={() => handleMouseLeave4()}
>
<StyledImg
src={hovered4 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
WHO AM I?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 178, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿PLAYERS?');
  }}
  onMouseEnter={() => handleMouseEnter5()}
  onMouseLeave={() => handleMouseLeave5()}
>
<StyledImg
src={hovered5 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />        
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
PLAYERS?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 178, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿SUPPORT?');
  }}
  onMouseEnter={() => handleMouseEnter12()}
  onMouseLeave={() => handleMouseLeave12()}
>
<StyledImg
src={hovered12 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} />   
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
SUPPORT?      
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 281, left: -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿COMMUNITY?');
  }}
  onMouseEnter={() => handleMouseEnter14()}
  onMouseLeave={() => handleMouseLeave14()}
>
<StyledImg
src={hovered14 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
COMMUNITY?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 281, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿GIVEAWAY?');
  }}
  onMouseEnter={() => handleMouseEnter15()}
  onMouseLeave={() => handleMouseLeave15()}
>
<StyledImg
src={hovered15 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
GIVEAWAY?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 281, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿RARITY?');
  }}
  onMouseEnter={() => handleMouseEnter16()}
  onMouseLeave={() => handleMouseLeave16()}
>
<StyledImg
src={hovered16 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
RARITY?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 385, left: -8, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿STAFF-SALE?');
  }}
  onMouseEnter={() => handleMouseEnter19()}
  onMouseLeave={() => handleMouseLeave19()}
>
<StyledImg
src={hovered19 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -15, left: -13, width: "279px", height: "70px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
STAFF-SALE?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 385, left: 324, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿ROADMAP?');
  }}
  onMouseEnter={() => handleMouseEnter18()}
  onMouseLeave={() => handleMouseLeave18()}
>
<StyledImg
src={hovered18 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -16, left: -12, width: "279px", height: "69px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
ROADMAP?
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 385, left: 655, fontSize: 26 }}
  onClick={() => {
    audio2.play();
    setcurrentQuestion00('¿CONTACT US?');
  }}
  onMouseEnter={() => handleMouseEnter17()}
  onMouseLeave={() => handleMouseLeave17()}
>
<StyledImg
src={hovered17 ? "/config/images/aboutw3.png" : "/config/images/roadicow00.png"}
alt="image"
draggable="false"
style={{ position: "absolute", top: -16, left: -12, width: "279px", height: "69px "  }} /> 
<CTNButton2
  style={{ position: "absolute",  top: -15, left: -10, width: "274px", height: "68px"}}
>
CONTACT US?
</CTNButton2>
</CTNButton2>


</s.Container>

</ResponsiveWrapper>
</>              
</>}
</div>
)}

</s.Container>


<s.Container style={{ position: "absolute", top: -212, left: 0 ,}}>

<StyledRoundButton // 1 boton Minting   
style={{ position: "absolute  ", top: 370, left: -95, width: "190px", height: "125px"  }}
id="button1" // boton minting
onClick={(e) => {
  setActiveButton(1);
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
 onMouseEnter={() => handleMouseEnter6()}
onMouseLeave={() => handleMouseLeave6()}
>
</StyledRoundButton> 


<StyledRoundButton // 2 boton Roadmap 
style={{ position: "absolute  ", top: 370, left: 103, width: "190px", height: "125px"  }}
id="button2" // boton airdrop

onClick={(e) => {
   setActiveButton(2)
   setShowMsg(true)
   setShowMsg1(false) 
  audio2.play();
  } }
  onMouseEnter={() => handleMouseEnter7()}
onMouseLeave={() => handleMouseLeave7()}
>
</StyledRoundButton>

<StyledRoundButton // 3 boton airdrop 
style={{ position: "absolute  ", top: 370, left: 303, width: "190px", height: "125px"  }}
id="button3" // boton airdrop
onClick={(e) => {
  setActiveButton(3)
  setShowMsg(false) 
  setShowMsg1(true) 
 audio2.play();
 reloadGifs();
 } }
 onMouseEnter={() => handleMouseEnter8()}
onMouseLeave={() => handleMouseLeave8()}
>
</StyledRoundButton>              
              
<StyledRoundButton // 4 boton Caracteristicas
style={{ position: "absolute  ", top: 370, left: 503, width: "190px", height: "125px"  }}
id="button4" // boton Caracteristicas
onClick={(e) => {
  setActiveButton(4)
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
 onMouseEnter={() => handleMouseEnter9()}
onMouseLeave={() => handleMouseLeave9()}
>

</StyledRoundButton> 


<StyledRoundButton // 5 boton Utilidad
style={{ position: "absolute  ", top: 370, left: 703, width: "190px", height: "125px"  }}
id="button5" // boton Caracteristicas
onClick={(e) => {
  setActiveButton(5)
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
 onMouseEnter={() => handleMouseEnter10()}
onMouseLeave={() => handleMouseLeave10()}
>
</StyledRoundButton> 

<StyledRoundButton // 6 boton Historia
style={{ position: "absolute  ", top: 370, left: 903, width: "190px", height: "125px"  }}
id="button6" // boton Caracteristicas
onClick={(e) => {
  setActiveButton(6)
  setShowMsg(false) 
  setShowMsg1(false) 
 audio2.play();
 } }
 onMouseEnter={() => handleMouseEnter11()}
onMouseLeave={() => handleMouseLeave11()}
>
</StyledRoundButton>
  
</s.Container>


<ResponsiveWrapper flex={1} style={{ position: "absolute"}} test > 


<canvas ref={canvasReflogo} alt="Conquest Of Paradise" style={{ position: "absolute", top: -360, left: -95, pointerEvents: 'none' }} />

</ResponsiveWrapper>

<s.Container translate="no" style={{ position: "absolute", top: -560, left: 75}}>


<StyledImg
src={"/config/images/timeout.png"}
      alt="image"
      draggable="false"
style={{ position: "absolute", top: 2270, left: -50, width: "950px",height: "130px" }} />





<StyledImg
src={"/config/images/Redes.png"}
      alt="image"
      draggable="false"
style={{ position: "absolute", top: 2200, left: -130, width: "1100px",height: "130px" }} />


<CTNButton4 
style={{ position: "absolute", top: 2314, left: -30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 14}}>CONQUEST OF PARADISE IS AN INDEPENDENT PROJECT INSPIRED BY MINECRAFT.</p>  
</CTNButton4>




<CTNButton2
  style={{ position: "absolute", top: 2242, left: -90, fontSize: 23 }}
  onMouseEnter={() => handleMouseEnter28()}   onMouseLeave={() => handleMouseLeave28()}
  onClick={(e) => { window.open(discordlink, "_blank"); audio2.play(); }}>
<StyledImg
src={hovered28 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }}
alt="image" draggable="false" />
<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
DISCORD
</CTNButton2>
</CTNButton2>

      
<CTNButton2
  style={{ position: "absolute", top: 2242, left: 123, fontSize: 23 }}
onMouseEnter={() => handleMouseEnter24()}   onMouseLeave={() => handleMouseLeave24()}
  onClick={(e) => { window.open(opensealink, "_blank"); audio2.play(); }}>
<StyledImg
src={hovered24 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>


<CTNButton2
style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}
onClick={(e) => { window.open(opensealink, "_blank"); audio2.play(); }}>
OPENSEA
</CTNButton2>
</CTNButton2>

<CTNButton2
  style={{ position: "absolute", top: 2242, left: 335, fontSize: 23 }}
  onMouseEnter={() => handleMouseEnter25()}   onMouseLeave={() => handleMouseLeave25()}
  onClick={(e) => { window.open(twitterlink, "_blank"); audio2.play(); }}>
<StyledImg
src={hovered25 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>



<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
TWITTER
</CTNButton2>
</CTNButton2>


<CTNButton2
  style={{ position: "absolute", top: 2242, left: 546, fontSize: 23 }}
  onMouseEnter={() => handleMouseEnter26()}   onMouseLeave={() => handleMouseLeave26()}
  onClick={(e) => { window.open(tiktoklink, "_blank"); audio2.play(); }}>
<StyledImg
src={hovered26 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>



<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
TIKTOK
</CTNButton2>
</CTNButton2>

<CTNButton2
  style={{ position: "absolute", top: 2242, left: 756, fontSize: 22 }}
  onMouseEnter={() => handleMouseEnter27()}   onMouseLeave={() => handleMouseLeave27()}
  onClick={(e) => { window.open(instagramlink, "_blank"); audio2.play(); }}>
<StyledImg
src={hovered27 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "200px", height: "78px"  }} 
alt="image" draggable="false"/>


<CTNButton2
  style={{ position: "absolute",  top: -11, left: -13, width: "200px", height: "68px"}}>
INSTAGRAM
</CTNButton2>
</CTNButton2>






</s.Container>

<s.Container style={{ position: "absolute", top: -850, left: 0 ,}}>

{isImageVisible &&  (  
  
<>

<StyledImg
style={{   transform: 'scale(2.3) ', position: "absolute", top: 1773, left: 411, width: "155px",height: "50px"}} 
src={IsHovered13 ? "/config/images/msg02.png" : "/config/images/msg01.png"}
alt="image" draggable="false"
/>

<StyledImg
      src={"/config/images/More/Err.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 1465, left: 23, width: "930px",height: "370px" }} />

<CTNButton2
className="animated-text-2" translate="no" style={{  position: "absolute", top: 1580, left: 47,  width: "887px",height: "140px", fontSize: 32,}}
      >
Don't miss your chance to secure your spot in the Pre-Sale! Fill out the Whitelist form now.
</CTNButton2>

<CTNButton2
style={{   transform: 'scale(2.3)', position: "absolute", top: 1779, left: 411,  width: "150px",height: "45px", }} 
onMouseEnter={() => handleMouseEnter13()}
onMouseLeave={() => handleMouseLeave13()}
onClick={(e) => {setIsHovered13(false); setTimeout(() => setActiveButton(4), setImageVisible(false) ); audio2.play();}}
 >
WHITELIST
</CTNButton2>






</>

)}


</s.Container>




</ResponsiveWrapper> 





      
  </s.Container>




    </div>
 
 

  );
}
}





export default App;
